//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { IMenu } from "./models/menu";
import { CommonService } from "src/app/services/common/common.service";
import { HelperService } from "src/app/services/helper/helper.service";
declare var AOS: any;
declare var $: any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: "app-header-partial",
  templateUrl: "./header-partial.component.html",
  styleUrls: ["./header-partial.component.scss"],
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class HeaderPartialComponent implements OnInit {
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(private COMMON: CommonService, private HS: HelperService) {}

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public live_stream_url: string;
  public menu_sport: IMenu;
  public menu_tvseries: IMenu;
  public menu_tvshows: IMenu;
  public menu_about: IMenu = {
    type: "about",
    label: "Haqqımızda",
    submenus: {
      items: [
        {
          id: "",
          title: "Haqqımızda",
          image: "string",
          childrens: [],
        },
        {
          id: "structure",
          title: "Struktur",
          image: "string",
          childrens: [],
        },
        {
          id: "yayim",
          title: `Yayım şurası`,
          image: "string",
          childrens: [],
        },
        // {
        //   id: "adami",
        //   title: `"Adami" Media Mükafatı`,
        //   image: "string",
        //   childrens: [],
        // },
        // {
        //   id: "charter",
        //   title: "Nizamnamə",
        //   image: "string",
        //   childrens :[]
        // }
      ],
    },
  };

  public responsive = {
    sm: false,
    md: false,
    lg: true,
  };
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMenuApi() {
    this.COMMON.GetMenu().subscribe(
      (RESPONSE) => {
        this.SeparateMenu(RESPONSE.body);
      },
      (ERROR) => {}
    );
  }

  GetLiveStream() {
    this.COMMON.GetLiveStream().subscribe(
      (RESPONSE) => {
        this.live_stream_url = RESPONSE.body.live_video_link;
      },
      (ERROR) => {}
    );
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SeparateMenu(data) {
    data.forEach((element) => {
      if (element.type == "sports") {
        this.menu_sport = element;
      } else if (element.type == "tvseries") {
        this.menu_tvseries = element;
      } else if (element.type == "programs") {
        this.menu_tvshows = element;
      }
    });
  }

  OpenMdMenu() {
    $("nav[MD-MENU]").css({ display: "block" });
    setTimeout(() => {
      $("nav[MD-MENU]").css({ opacity: "1" });
    }, 10);
    $("html").css({
      position: "relative",
      overflow: "hidden",
      height: "100%",
    });
    $("body").css({
      position: "relative",
      overflow: "hidden",
      height: "100%",
    });
  }
  CloseMdMenu() {
    $("nav[MD-MENU]").css({ opacity: "0" });
    setTimeout(() => {
      $("nav[MD-MENU]").css({ display: "none" });
    }, 500);
    $("html").css({
      position: "relative",
      overflow: "scroll",
      height: "auto",
    });
    $("body").css({
      position: "relative",
      overflow: "scroll",
      height: "auto",
    });
    this.CloseAllMdMenuSubmenus();
  }

  ToggleMdMenuSubmenu(e) {
    $(e.target)
      .closest("li")
      .find("ul")
      .slideToggle("fast", () => {});
    $(e.target).closest("li").find("[ARROW-WHITE-ICON]").toggleClass("active");
  }

  CloseAllMdMenuSubmenus() {
    $("[SUBMENU]")
      .find("ul")
      .slideUp("fast", () => {});
    $("[SUBMENU]").find("[ARROW-WHITE-ICON]").removeClass("active");
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  OpenSearchModal() {
    this.HS.ShowModal("search");
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    if (this.HS.GetDocumentWidth() < 1150) {
      this.responsive.md = true;
      this.responsive.lg = false;
    }
    this.GetMenuApi();
    this.GetLiveStream();
    AOS.init();
  }
} //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
