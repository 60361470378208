import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { MakeYoutubeUrlsEmbed } from 'src/app/components/partials/embed-player/embed-player.component';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-news-partial', templateUrl: './news-partial.component.html', styleUrls: ['./news-partial.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


export class NewsPartialComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private COMMON: CommonService
  ) { 
    this.GetMainNewsApi();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public news: IMainNews;
  
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  ngOnInit() {
  }

  GetMainNewsApi(){
    this.COMMON.GetMainNews().subscribe(
      RESPONSE => {
        this.news = RESPONSE.body;
        this.news.video.src.embed = MakeYoutubeUrlsEmbed(this.news.video.src.embed);
      },
      ERROR => {}
    )
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


export interface IMainNews{
  id: number;
  title: string;
  presenter: string;
  date: string;
  subtitles: 
      {
        id: number;
        title: string;
      }[],
  view_count: number;
  duration: number;
  image: string;
  detail: string;
  video: any;
}