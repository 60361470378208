import { environment } from "./../../../../environments/environment.prod";
import {
  HttpClient,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { CommonService } from "./../../../services/common/common.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { listLocales } from "ngx-bootstrap/chronos";
import { FileUploadService } from "src/app/services/file-upload/file-upload.service";
import { HelperService } from "src/app/services/helper/helper.service";
import { AppealPageService } from "./services/appeal-page.service";
import { IAppealPage } from "./models/IAppealPage";
import { Title } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-appeal-page",
  templateUrl: "./appeal-page.component.html",
  styleUrls: ["./appeal-page.component.scss"],
})
export class AppealPageComponent implements OnInit {
  constructor(
    private localeService: BsLocaleService,
    private FileUploadService: FileUploadService,
    private AppealPageService: AppealPageService,
    private SPINNER: NgxUiLoaderService,
    private HS: HelperService,
    private COMMON_SERVICE: CommonService,
    private TS: Title
  ) {
    this.TS.setTitle("İTV - Səs azərbaycan");
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 36500);
    this.maxDate.setDate(this.maxDate.getDate() - 5840);
  }

  locales = listLocales();
  public maxDate;
  public minDate;
  public progress_percent;
  files: any = [];

  public all_volume;
  public volume_now;

  public name_input = {
    name: null,
    required: true,
    validate: false,
    value: false,
    error_message: "",
    touched: false,
  };

  public surname_input = {
    surname: null,
    required: true,
    validate: false,
    value: false,
    error_message: "",
    touched: false,
  };
  public patronymic_input = {
    patronymic: null,
    required: true,
    validate: false,
    value: false,
    error_message: "",
    touched: false,
  };

  public phone_input = {
    phone: "994",
    required: true,
    validate: false,
    error_message: "",
    touched: false,
  };

  public email_input = {
    mail: null,
    required: false,
    validate: false,
    error_message: "",
    touched: false,
  };

  public date_input = {
    date: null,
    formatted_date: null,
    required: true,
    validate: false,
    error_message: "",
    touched: false,
  };

  public instrument_input = {
    instrument: null,
    required: false,
    validate: false,
    value: false,
    error_message: "",
    touched: false,
  };

  public project_experience_input = {
    project: null,
    required: false,
    validate: false,
    value: false,
    error_message: "",
    touched: false,
  };

  public city_input = {
    city: null,
    required: true,
    validate: false,
    value: false,
    error_message: "",
    touched: false,
  };

  public external_url_input = {
    external_url: null,
    required: true,
    validate: false,
    value: false,
    error_message: "",
    touched: false,
  };

  public country_input;
  public country_input_valid = false;

  public show_region_input = false;
  public show_city_input = false;

  public region_input;
  public region_input_valid = false;

  public show_experience = false;

  public file_input = {
    value: null,
    required: true,
    validate: false,
    error_message: "",
    touched: false,
    loading: "0",
  };

  public country_array = [
    "Azərbaycan",
    "ABŞ",
    "Albaniya",
    "Almaniya",
    "Andorra",
    "Anqola",
    "Antiqua və Barbuda",
    "Argentina",
    "Avstraliya",
    "Avstriya",
    "Baham",
    "Bəhreyn",
    "BƏƏ",
    "Banqladeş",
    "Barbados",
    "Belarusiya",
    "Belçika",
    "Beliz",
    "Benin",
    "Butan",
    "Boliviya",
    "Bosniya və Herseqovina",
    "Botsvana",
    "Braziliya",
    "Brüney",
    "Bolqariya",
    "Burkina Faso",
    "Burundi",
    "Kabo Verde",
    "Kambodiya",
    "Kameron",
    "Kanada",
    "Kolumbiya",
    "Komor adaları ",
    "Kosta Rika",
    "Kot d’Ivuar",
    "Kuba",
    "Kipr",
    "Konqo Demokratik Respublikası",
    "Çad",
    "Çili",
    "Çin",
    "Çex Respublikası",
    "Əfqanistan",
    "Əlcəzair",
    "Xorvatiya",
    "Cibuti",
    "Cənubi Afrika",
    "Danimarka",
    "Dominikan",
    "Dominikan Respublikası",
    "Misir",
    "Mərkəzi Afrika Respublikası",
    "Ekvatorial Qvineya",
    "Eritreya",
    "Estoniya",
    "Esvatini",
    "Efiopiya",
    "Ekvador",
    "Fiji",
    "Finlandiya",
    "Fransa",
    "Filippin",
    "Qabon",
    "Qambiya",
    "Qrenada",
    "Qvatemala",
    "Qvineya",
    "Qvineya Bisau",
    "Qayana",
    "Qətər",
    "Qana",
    "Qırğızıstan",
    "Qazaxıstan",
    "Gürcüstan",
    "Haiti",
    "Honduras",
    "Hindistan",
    "Hollandiya",
    "İslandiya",
    "İndoneziya",
    "İran",
    "İraq",
    "İrlandiya",
    "İordaniya",
    "İspaniya",
    "İsveçrə",
    "İngiltərə",
    "İsveç",
    "İsrail",
    "İtaliya",
    "Yunanistan",
    "Yamayka",
    "Yaponiya",
    "Kenya",
    "Kiribati",
    "Koreya, Cənubi",
    "Koreya, Şimali",
    "Kuveyt",
    "Laos",
    "Latviya",
    "Lebanon",
    "Lesoto",
    "Liberiya",
    "Liviya",
    "Lixtenşteyn",
    "Litva",
    "Luksemburq",
    "Madaqaskar",
    "Malavi",
    "Malayziya",
    "Maldiv",
    "Mali",
    "Malta",
    "Mauritaniya",
    "Mauritius",
    "Meksika",
    "Mikroneziya, Federal ştatları",
    "Moldova",
    "Monako",
    "Monqoliya",
    "Monteneqro",
    "Morokko",
    "Mozambik",
    "Myanmar (Burma)",
    "Namibiya",
    "Nauru",
    "Nepal",
    "Nikaraqua",
    "Niger",
    "Nigeriya",
    "Norveç",
    "Şimali Makedoniya",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua Yeni Qvineya",
    "Paraqvay",
    "Peru",
    "Polşa",
    "Portuqaliya",
    "Rumıniya",
    "Rusiya",
    "Ruanda",
    "Sent Kits və Nevis",
    "Sent Lusiya",
    "Samoa",
    "San Marino",
    "Sao Tom və Prinsip",
    "Səüdiyyə Ərəbistan",
    "Seneqal",
    "Serbiya",
    "Seyşel adaları",
    "Syerra Leone",
    "Sinqapur",
    "Slovakiya",
    "Sloveniya",
    "Solomon İslandiya",
    "Somali",
    "Sudan",
    "Sudan, Cənubi",
    "Surinam",
    "Suriya",
    "Salvador",
    "Şri Lanka",
    "Tacikistan",
    "Tanzaniya",
    "Tayland",
    "Toqo",
    "Tonqa",
    "Trinidad və Tabaqo",
    "Tunis",
    "Türkiyə",
    "Türkmənistan",
    "Tuvalu",
    "Uqanda",
    "Ukrayna",
    "Uruqvay",
    "Özbəkistan",
    "Vanuatu",
    "Vatikan",
    "Venesuela",
    "Vyetnam",
    "Yəmən",
    "Yeni Zellandiya",
    "Zambiya",
    "Zimbabve",
  ];

  public region_array = [
    "Abşeron rayonu",
    "Ağcabədi rayonu",
    "Ağdam rayonu",
    "Ağdaş rayonu",
    "Ağstafa rayonu",
    "Ağsu rayonu",
    "Astara rayonu",
    "Bakı şəhəri",
    "Balakən rayonu",
    "Bərdə rayonu",
    "Beyləqan rayonu",
    "Biləsuvar rayonu",
    "Cəbrayıl rayonu",
    "Cəlilabad rayonu",
    "Daşkəsən rayonu",
    "Füzuli rayonu",
    "Gəncə şəhəri",
    "Gədəbəy rayonu",
    "Goranboy rayonu",
    "Göyçay rayonu",
    "Göygöl rayonu",
    "Hacıqabul rayonu",
    "Xaçmaz rayonu",
    "Xızı rayonu",
    "Xocalı rayonu",
    "Xocavənd rayonu",
    "İmişli rayonu",
    "İsmayıllı rayonu",
    "Kəlbəcər rayonu",
    "Kürdəmir rayonu",
    "Qax rayonu",
    "Qazax rayonu",
    "Qəbələ rayonu",
    "Qobustan rayonu",
    "Quba rayonu",
    "Qubadlı rayonu",
    "Qusar rayonu",
    "Laçın rayonu",
    "Lerik rayonu",
    "Lənkəran rayonu",
    "Masallı rayonu",
    "Mingəçevir şəhəri",
    "Naftalan şəhəri",
    "Neftçala rayonu",
    "Oğuz rayonu",
    "Saatlı rayonu",
    "Sabirabad rayonu",
    "Salyan rayonu",
    "Samux rayonu",
    "Siyəzən rayonu",
    "Sumqayıt şəhəri",
    "Şabran rayonu",
    "Şamaxı rayonu",
    "Şəki rayonu",
    "Şəmkir rayonu",
    "Şirvan şəhəri",
    "Şuşa rayonu",
    "Tərtər rayonu",
    "Tovuz rayonu",
    "Ucar rayonu",
    "Yardımlı rayonu",
    "Yevlax rayonu",
    "Zaqatala rayonu",
    "Zəngilan rayonu",
    "Zərdab rayonu",
  ];

  public appeal_form = { success: false, is_valid: false };
  public POST_DATA: IAppealPage = {} as any;
  public fileInputElement;
  public fileInputBlock: string = "off";
  public fileInputError: string =
    "Xahiş edirik düzgün formatda olan file yükləyin.";
  public fileInputErrorFormat: string =
    "İcazə verilən formatlar: .mp4, .mov, .mpeg";
  public sendButtonInner: string = "Göndər";

  ngOnInit() {
    let lang_for_datepicker = localStorage.getItem("language");
    localStorage.getItem("language") == "az"
      ? (lang_for_datepicker = "az")
      : (lang_for_datepicker = localStorage.getItem("language"));
    // console.log(this.file_input.loading);

    this.SPINNER.start();
    this.HS.ScrollTo();
    setTimeout(() => {
      this.SPINNER.stop();
    }, 1200);
  }

  SubmitAppealForm() {}

  NameInputChange() {
    this.name_input.touched = true;
    this.name_input.name.length >= 3
      ? (this.name_input.validate = true)
      : (this.name_input.validate = false);
    this.CheckFormValidation();
  }

  SurnameInputChange() {
    this.surname_input.touched = true;
    this.surname_input.surname.length >= 3
      ? (this.surname_input.validate = true)
      : (this.surname_input.validate = false);
    this.CheckFormValidation();
  }

  PatronymicInputChange() {
    this.patronymic_input.touched = true;
    this.patronymic_input.patronymic.length >= 3
      ? (this.patronymic_input.validate = true)
      : (this.patronymic_input.validate = false);
    this.CheckFormValidation();
  }

  PhoneInputChange() {
    this.phone_input.touched = true;
    this.phone_input.phone.includes("994") &&
    this.phone_input.phone.length == 12
      ? (this.phone_input.validate = true)
      : (this.phone_input.validate = false);
    this.CheckFormValidation();
  }

  EmailInputChange() {
    this.email_input.touched = true;
    this.email_input.mail.includes("@") &&
    this.email_input.mail.includes(".") &&
    this.email_input.mail.length > 5
      ? (this.email_input.validate = true)
      : (this.email_input.validate = false);
    // this.CheckFormValidation();
  }

  changeExperienceTrue() {
    this.show_experience = true;
  }

  changeExperienceFalse() {
    this.show_experience = false;
  }

  InstrumentInputChange() {
    this.instrument_input.touched = true;
    this.instrument_input.instrument.length >= 1
      ? (this.instrument_input.validate = true)
      : (this.instrument_input.validate = false);
    // this.CheckFormValidation();
  }

  ProjectExperienceInputChange() {
    this.project_experience_input.touched = true;
    this.project_experience_input.project.length >= 1
      ? (this.project_experience_input.validate = true)
      : (this.project_experience_input.validate = false);
    // this.CheckFormValidation();
  }

  CityInputChange() {
    this.city_input.touched = true;
    this.city_input.city.length >= 3
      ? (this.city_input.validate = true)
      : (this.city_input.validate = false);
    this.CheckFormValidation();
  }

  ExternalUrlInputChange() {
    this.external_url_input.touched = true;
    this.external_url_input.external_url.length >= 5
      ? (this.external_url_input.validate = true)
      : (this.external_url_input.validate = false);
    this.CheckFormValidation();
  }

  DateInputChange(value: Date): void {
    this.date_input.date = value;
    setTimeout(() => {
      let date = $("#date_input_format").html();
      this.date_input.formatted_date = date;
      if (date.length) {
        this.date_input.touched = true;
        this.date_input.formatted_date
          ? (this.date_input.validate = true)
          : (this.date_input.validate = false);
        this.CheckFormValidation();
      }
    }, 300);
  }

  CheckFileLoading(status) {
    if (this.file_input.loading == status) {
      return true;
    } else {
      return false;
    }
  }

  // FileInputChange(event) {
  //   for (let index = 0; index < event.length; index++) {
  //     const element = event[index];
  //     this.files.push(element.name)
  //   }
  // }
  FileInputChange(event) {
    this.fileInputElement = <File>event.currentTarget.files[0];
    if (event.currentTarget.files.length > 0) {
      let dot = this.fileInputElement.name.indexOf(".");
      let checkFileFormat = this.fileInputElement.name
        .substring(dot, this.fileInputElement.length)
        .toLowerCase();
      if (
        checkFileFormat == ".mp4" ||
        checkFileFormat == ".mov" ||
        checkFileFormat == ".mpeg" ||
        checkFileFormat == ".midi" ||
        checkFileFormat == ".mid" ||
        checkFileFormat == ".mpga" ||
        checkFileFormat == ".wav" ||
        checkFileFormat == ".avi"
      ) {
        this.fileInputBlock = "off";
        const FD = new FormData();
        FD.append("file", this.fileInputElement);
        this.file_input.required = true;
        this.file_input.loading = "loading";
        this.CheckFormValidation();

        this.FileUploadService.Events(FD).subscribe((EVENT) => {
          this.progress_percent = `${Math.round(
            (100 * EVENT.loaded) / EVENT.total
          )}%`;

          this.all_volume = `${(EVENT.total / (1024 * 1024)).toFixed(1)}Mb`;
          this.volume_now = `${(EVENT.loaded / (1024 * 1024)).toFixed(1)}Mb`;
          if (EVENT.body) {
            if (EVENT.status == 201) {
              this.file_input.value = EVENT.body.data.file;
              this.file_input.validate = true;
              this.file_input.loading = "loaded";
              this.CheckFormValidation();
            }
          }
        });

        // this.FileUploadService.UploadFile(FD).subscribe((RESPONSE) => {
        //   console.log(`File is completely uploaded`);
        //   this.file_input.value = RESPONSE.data.file;
        //   this.file_input.validate = true;
        //   this.file_input.loading = "loaded";
        //   this.CheckFormValidation();
        // });

        //SEND FILE |||||||||||||||||||||||||||||||||||||||||||||||||
        // this.FileUploadService.UploadFile(FD).subscribe(
        //   (RESPONSE) => {
        //     this.file_input.value = RESPONSE.data.file;
        //     this.file_input.validate = true;
        //     this.file_input.loading = "loaded";
        //     this.CheckFormValidation();
        //   },
        //   (ERROR) => {}
        // );
      } else {
        this.fileInputBlock = "on";
        document.addEventListener("click", () => {
          setTimeout(() => {
            this.fileInputBlock = "off";
          }, 3000);
        });
      }
    } else {
      this.file_input.loading = "0";
    }
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      this.fileInputElement = event[index];
      this.files.push(this.fileInputElement.name);
      if (this.files.length > 0) {
        let dot = this.fileInputElement.name.indexOf(".");
        let checkFileFormat = this.fileInputElement.name
          .substring(dot, this.fileInputElement.length)
          .toLowerCase();
        if (
          checkFileFormat == ".mp4" ||
          checkFileFormat == ".mov" ||
          checkFileFormat == ".mpeg" ||
          checkFileFormat == ".midi" ||
          checkFileFormat == ".mid" ||
          checkFileFormat == ".mpga" ||
          checkFileFormat == ".wav"
        ) {
          this.fileInputBlock = "off";
          const FD = new FormData();
          FD.append("file", this.fileInputElement);
          this.file_input.required = true;
          this.file_input.loading = "loading";
          this.CheckFormValidation();

          this.FileUploadService.Events(FD).subscribe((EVENT) => {
            this.progress_percent = `${Math.round(
              (100 * EVENT.loaded) / EVENT.total
            )}%`;

            this.all_volume = `${(EVENT.total / (1024 * 1024)).toFixed(1)}Mb`;
            this.volume_now = `${(EVENT.loaded / (1024 * 1024)).toFixed(1)}Mb`;
            if (EVENT.body) {
              if (EVENT.status == 201) {
                this.file_input.value = EVENT.body.data.file;
                this.file_input.validate = true;
                this.file_input.loading = "loaded";
                this.CheckFormValidation();
              }
            }
          });

          // console.log(
          //   `${(this.fileInputElement.size / (1024 * 1024)).toFixed(1)}Mb`
          // );
          // this.FileUploadService.Events(FD).subscribe((EVENT) => {
          //   console.log(EVENT);
          //   // const percentDone = Math.round((100 * EVENT.loaded) / EVENT.total);
          //   // console.log(`File is ${percentDone}% uploaded`);
          // });

          // this.FileUploadService.UploadFile(FD).subscribe((RESPONSE) => {
          //   console.log(`File is completely uploaded`);
          //   this.file_input.value = RESPONSE.data.file;
          //   this.file_input.validate = true;
          //   this.file_input.loading = "loaded";
          //   this.CheckFormValidation();
          // });

          //SEND FILE |||||||||||||||||||||||||||||||||||||||||||||||||
          // this.FileUploadService.UploadFile(FD).subscribe(
          //   (RESPONSE) => {
          //     this.file_input.value = RESPONSE.data.file;
          //     this.file_input.validate = true;
          //     this.file_input.loading = "loaded";
          //     this.CheckFormValidation();
          //   },
          //   (ERROR) => {}
          // );
        } else {
          this.fileInputBlock = "on";
          document.addEventListener("click", () => {
            setTimeout(() => {
              this.fileInputBlock = "off";
            }, 3000);
          });
        }
      } else {
        this.file_input.loading = "0";
      }
    }
  }

  deleteAttachment() {
    this.file_input.loading = "0";
    this.fileInputElement = "0";
    this.file_input.validate = false;
    this.CheckFormValidation();
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  SubmitForm() {
    if (this.show_region_input == false) {
      if (
        this.name_input.validate == true &&
        this.surname_input.validate == true &&
        this.phone_input.validate == true &&
        // this.email_input.validate == true &&
        this.date_input.validate == true &&
        this.file_input.validate == true &&
        this.patronymic_input.validate == true &&
        this.country_input_valid == true &&
        this.city_input.validate == true &&
        // this.external_url_input.validate == true &&
        this.sendButtonInner == "Göndər"
      ) {
        //|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
        this.sendButtonInner = "Zəhmət olmasa gözləyin";
        this.POST_DATA.name = this.name_input.name;
        this.POST_DATA.surname = this.surname_input.surname;
        this.POST_DATA.patronymic = this.patronymic_input.patronymic;
        this.POST_DATA.tel = this.phone_input.phone;
        this.POST_DATA.email = this.email_input.mail;
        this.POST_DATA.date = this.date_input.formatted_date;
        this.POST_DATA.contestant_place = `${this.country_input}, ${this.city_input.city}`;
        this.POST_DATA.musical_instrument = this.instrument_input.instrument;
        this.POST_DATA.project_experience = this.project_experience_input.project;
        this.POST_DATA.external_url = this.external_url_input.external_url;
        this.POST_DATA.file = this.file_input.value;

        //SUBMIT FORM TO API |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
        this.AppealPageService.PostAppealPageForm(this.POST_DATA).subscribe(
          (RESPONSE) => {
            this.appeal_form.success = true;
          },
          (ERROR) => {}
        );
        //|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
      }
    } else if (this.show_region_input == true) {
      if (
        this.name_input.validate == true &&
        this.surname_input.validate == true &&
        this.phone_input.validate == true &&
        // this.email_input.validate == true &&
        this.date_input.validate == true &&
        this.file_input.validate == true &&
        this.patronymic_input.validate == true &&
        this.country_input_valid == true &&
        this.region_input_valid == true &&
        // this.external_url_input.validate == true &&
        this.sendButtonInner == "Göndər"
      ) {
        //|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
        this.sendButtonInner = "Zəhmət olmasa gözləyin";
        this.POST_DATA.name = this.name_input.name;
        this.POST_DATA.surname = this.surname_input.surname;
        this.POST_DATA.patronymic = this.patronymic_input.patronymic;
        this.POST_DATA.tel = this.phone_input.phone;
        this.POST_DATA.email = this.email_input.mail;
        this.POST_DATA.date = this.date_input.formatted_date;
        this.POST_DATA.contestant_place = `${this.country_input}, ${this.region_input}`;
        this.POST_DATA.musical_instrument = this.instrument_input.instrument;
        this.POST_DATA.project_experience = this.project_experience_input.project;
        this.POST_DATA.external_url = this.external_url_input.external_url;
        this.POST_DATA.file = this.file_input.value;
        //SUBMIT FORM TO API |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
        this.AppealPageService.PostAppealPageForm(this.POST_DATA).subscribe(
          (RESPONSE) => {
            this.appeal_form.success = true;
          },
          (ERROR) => {}
        );
        //|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
      }
    }
    this.CheckFormValidation();
  }

  CheckFormValidation() {
    if (this.show_region_input == false) {
      if (
        this.name_input.validate == true &&
        this.surname_input.validate == true &&
        this.patronymic_input.validate == true &&
        this.phone_input.validate == true &&
        // this.email_input.validate == true &&
        this.date_input.validate == true &&
        this.country_input_valid == true &&
        this.city_input.validate == true &&
        // this.external_url_input.validate == true &&
        this.file_input.validate == true
      ) {
        this.appeal_form.is_valid = true;
      } else {
        this.appeal_form.is_valid = false;
      }
    } else if (this.show_region_input == true) {
      if (
        this.name_input.validate == true &&
        this.surname_input.validate == true &&
        this.patronymic_input.validate == true &&
        this.phone_input.validate == true &&
        // this.email_input.validate == true &&
        this.date_input.validate == true &&
        this.country_input_valid == true &&
        this.region_input_valid == true &&
        // this.external_url_input.validate == true &&
        this.file_input.validate == true
      ) {
        this.appeal_form.is_valid = true;
      } else {
        this.appeal_form.is_valid = false;
      }
    }
  }

  CustomSelectBtnClick(event) {
    event.currentTarget.classList.toggle("active");
    event.currentTarget.parentElement
      .querySelector(".custom_select_content")
      .classList.toggle("active");
  }

  CustomOptionClick(event) {
    event.currentTarget.parentElement.classList.remove("active");
    event.currentTarget.parentElement.parentElement.querySelector(
      ".select_selected"
    ).innerHTML = event.currentTarget.innerHTML;
    event.currentTarget.parentElement.parentElement
      .querySelector(".custom_select_btn")
      .classList.remove("active");

    switch (event.currentTarget.dataset.input) {
      case "country_input":
        this.country_input = event.currentTarget.innerHTML.trim();
        this.country_input_valid = true;
        if (this.country_input == "Azərbaycan") {
          this.show_region_input = true;
          this.show_city_input = false;
        } else {
          this.show_region_input = false;
          this.show_city_input = true;
        }
        this.CheckFormValidation();
        break;
      case "region_input":
        this.region_input = event.currentTarget.innerHTML.trim();
        this.region_input_valid = true;
        this.CheckFormValidation();
        break;
      default:
        break;
    }
  }
}
