import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'structure-item',
  templateUrl: './structure-item.component.html',
  styleUrls: ['./structure-item.component.scss']
})
export class StructureItemComponent implements OnInit {

  constructor() { }
  @Input() data :IStructure;

  ngOnInit() {
  }

}
export interface IStructure{
  id: number;
  name: string;
  position: string;
  email: string;
  image: string;
}