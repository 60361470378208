import { ILiveTvProgram } from 'src/app/components/pages/live-tv/models/live-tv';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class TestService {

  constructor(private http: HttpClient) { }

  private _live_tv_program: string = "/assets/json/live_tv_program.json"

  GetLiveTvProgram() : Observable<ILiveTvProgram[]>{
    return this.http.get<ILiveTvProgram[]>(this._live_tv_program);
  }

}
