import { HttpHeaders, HttpClient } from "@angular/common/http";
import { IAppealVacancy } from "../models/IAppealVacancy";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UrlService } from "src/app/services/url/url.service";
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Injectable({ providedIn: "root" })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class AppealVacancyService {
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({ "Content-Type": "application/json" });
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  constructor(private HttpClient: HttpClient, private UrlService: UrlService) {}
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  PostAppealPageForm(DATA: IAppealVacancy): Observable<any> {
    return this.HttpClient.post<IAppealVacancy>(
      this.UrlService.GetUrl().vacancy,
      DATA,
      { observe: "response", headers: this.header }
    );
  }
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
