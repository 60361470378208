import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from 'src/app/services/url/url.service';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class SearchService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HTTP: HttpClient,
    private URLS: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GeneralSearchByKeyword(keyword) : Observable<any>{
    return this.HTTP.get<ISearchResult[]>(
      this.URLS.GetUrl(keyword).GeneralSearchByKeyword,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

}
export interface ISearchResult{
  id: number;
  title: string;
  image: string;
  type: string;
  angular_root:string;
}
