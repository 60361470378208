import { Component, OnInit } from "@angular/core";
import * as Plyr from "../video-player/plyr.plugin/plyr.polyfilled.js";
import * as HLS from "./hls.js";
import { Meta } from "@angular/platform-browser";
declare var $: any;
@Component({
  selector: "live-radio-player",
  templateUrl: "./live-radio-player.component.html",
  styleUrls: ["./live-radio-player.component.scss"],
})
export class LiveRadioPlayerComponent implements OnInit {
  constructor(private META: Meta) {
    this.META.addTag({
      name: "title",
      content: "How to use Angular 4 meta service",
    });
    this.META.addTag({ name: "description", content: "talkingdotnet" });
    this.META.addTag({ name: "keywords", content: "Angular, Meta Service" });
  }
  // <!-- <meta property="og:url"           content="https://itv.saffman.uk" />
  // <meta property="og:type"          content="website" />
  // <meta property="og:title"         content="Your Website Titleasa" />
  // <meta property="og:description"   content="Your descriptiosn" />
  // <meta property="og:image"         content="https://itv-api.saffman.uk/storage/news/btr8iJOc44oH0Gluyuzgyo8B8M9hJnVQLJYB0vct.jpeg" />
  // <meta property="og:image" content="https://itv-api.saffman.uk/storage/news/btr8iJOc44oH0Gluyuzgyo8B8M9hJnVQLJYB0vct.jpeg" />
  // <meta property="og:image:secure_url" content="https://itv-api.saffman.uk/storage/news/btr8iJOc44oH0Gluyuzgyo8B8M9hJnVQLJYB0vct.jpeg" />
  // <meta property="og:image:type" content="image/jpeg" />
  // <meta property="og:image:width" content="400" />
  // <meta property="og:image:height" content="300" /> -->

  // public hls;
  // ngOnInit() {
  //   const player = new Plyr("#player", {
  //     title: "Example Title",
  //     settings: ["speed", "quality"],
  //   });
  //   player.source = {
  //     type: "video",
  //     sources: [
  //       {
  //         // src: "https://85.132.81.13:8888/2702",
  //         src: "https://stream.itv.az/rtmp:/radio/playlist.m3u8",
  //         type: "video/mp4a",
  //         size: 360,
  //       },
  //     ],
  //   };
  //   $(".plyr video").css({
  //     background: "url(/assets/images/important/radio_logo.png)",
  //     "background-position": "center",
  //     "background-size": "contain",
  //     "background-repeat": "no-repeat",
  //     "background-color": "#ffe215",
  //   });
  //   // $('.plyr video').css("background","#ffe215",)
  //   $(".plyr--video").css("background", "#ffe215");
  // }
  public hls;
  ngOnInit() {
    var video = document.querySelector("video");
    if (HLS.isSupported()) {
      this.hls = new HLS();
      this.hls.loadSource("https://stream.itv.az/rtmp:/radio/playlist.m3u8");

      // this.hls.loadSource("https://node19.connect.az:8086/ch1/pl.m3u8");
      // this.hls.loadSource('https://itv-api.saffman.uk/itv.m3u8');
      this.hls.attachMedia(video);
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = "https://stream.itv.az/rtmp:/radio/playlist.m3u8";
      // video.src = "https://node19.connect.az:8086/ch1/pl.m3u8";
      // video.src = 'https://itv-api.saffman.uk/itv.m3u8';
      video.addEventListener("loadedmetadata", function () {
        video.play();
      });
    } else {
    }
    var player = new Plyr(video, {
      resetOnEnd: true,
      renderers: ["native_hls", "html5"],
    });
    player.on("ready", function (event) {
      player.play();
    });

    $(".plyr video").css({
      background: "url(/assets/images/important/radio_logo.png)",
      "background-position": "center",
      "background-size": "contain",
      "background-repeat": "no-repeat",
      "background-color": "#ffe215",
    });

    $(".plyr__video-wrapper").css({ height: "100%" });
    // $('.plyr video').css("background","#ffe215",)
    $(".plyr--video").css("background", "#ffe215");
  }

  ngOnDestroy(): void {}
}
