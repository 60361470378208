import { HelperService } from 'src/app/services/helper/helper.service';
import { MoviesService } from '../movies-page/service/movies.service';
import { ISingleMovie } from '../movies-page/models/movies';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'app-movies-detail-page',
  templateUrl: './movies-detail-page.component.html',
  styleUrls: ['./movies-detail-page.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class MoviesDetailPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private MoviesService:MoviesService,
    private HS:HelperService,
    private SS: NgxUiLoaderService,
    private ActivatedRoute:ActivatedRoute,
    private Router:Router,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Filmlər");
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public movie:ISingleMovie;
  public movie_id:string;
  public active = {
    player:false
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    //SCROOL TOP
    this.HS.ScrollTo();
    //GET MOVIE ID FROM URL
    this.movie_id = this.ActivatedRoute.snapshot.params.id;
    //GET MOVIE
    this.GetSingleMovieById();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetSingleMovieById(){
    //START SPINNER
    this.SS.start();

    this.MoviesService.GetSingleMovieById(this.movie_id).subscribe(
      RESPONSE => {
        this.movie = RESPONSE.body;
        setTimeout(() => {this.SS.stop();}, 0);
      },
      ERROR => {
        setTimeout(() => {this.SS.stop();}, 0);
        this.Router.navigate(['/**']);
      }
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
