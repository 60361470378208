import { Component, OnInit } from '@angular/core';
import { EurovisionService } from 'src/app/services/apis/eurovision/eurovision.service';
import { ActivatedRoute } from '@angular/router';
import { IEurovisionDetail } from 'src/app/models/eurovision/IEurovisionNewsDetail';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-eurovision-news-detail',
  templateUrl: './eurovision-news-detail.component.html',
  styleUrls: ['./eurovision-news-detail.component.scss']
})
export class EurovisionNewsDetailComponent implements OnInit {

  constructor(
    private ACTIVATED_ROUTE:ActivatedRoute,
    private EUROVISION_SERVICE:EurovisionService,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Eurovision Xəbərlər");
  }

  public news_id:number;
  public slider_images:string[];
  public news:IEurovisionDetail;
  ngOnInit() {
    this.news_id = this.ACTIVATED_ROUTE.snapshot.params.id;
    this.GetEurovisionNewsDetail_Api(this.news_id );
  }

  GetEurovisionNewsDetail_Api(id:number){
    this.EUROVISION_SERVICE.GetEurovisionNewsDetail(id).subscribe(
      RESPONSE => {
        this.news = RESPONSE.body;
        this.slider_images = RESPONSE.body.slider_images;
      },
      ERROR => {}
    );
  }

}
