import { Component, OnInit } from '@angular/core';
declare var window:any;

@Component({
  selector: 'share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      let buttons = document.querySelectorAll("button.button");
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].setAttribute("data-url",window.location.href);
        buttons[i].setAttribute("data-title","ITV");
        buttons[i].setAttribute("data-title","");
      }
      window.Sharer.init();
    }, 1000);
  }

}
