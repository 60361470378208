import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { UrlService } from "../url/url.service";
import { Observable, BehaviorSubject } from "rxjs";
import { IGeneralInformation } from "src/app/models/IGeneralInformation";
@Injectable({ providedIn: "root" })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class CommonService {
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(private HTTP: HttpClient, private URLS: UrlService) {}

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  public general_information: IGeneralInformation;
  public banners = new BehaviorSubject<IBanner[]>([]);

  header = new HttpHeaders({ "Content-Type": "application/json" });

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMainSlider(): Observable<any> {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetMainSlider, {
      observe: "response",
      headers: this.header,
    });
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetGeneralInfo(): Observable<any> {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetGeneralInfo, {
      observe: "response",
      headers: this.header,
    });
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMainTodaySchedule() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetScheduleToDayAll, {
      observe: "response",
      headers: this.header,
    });
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAbout() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetAbout, {
      observe: "response",
      headers: this.header,
    });
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAward() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetAward, {
      observe: "response",
      headers: this.header,
    });
  }

  GetPrivacy() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetPrivacy, {
      observe: "response",
      headers: this.header,
    });
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAboutCharter() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetAboutCharter, {
      observe: "response",
      headers: this.header,
    });
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAboutStructure() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetAboutStructure, {
      observe: "response",
      headers: this.header,
    });
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMainNews() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetMainnews, {
      observe: "response",
      headers: this.header,
    });
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMainTvshows() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetMainTvshows, {
      observe: "response",
      headers: this.header,
    });
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMainTvseries() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetMainTvseries, {
      observe: "response",
      headers: this.header,
    });
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMenu() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetMenu, {
      observe: "response",
      headers: this.header,
    });
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SubscribeWithEmail(email: string) {
    return this.HTTP.post<any>(
        this.URLS.GetUrl().SubscribeWithEmail,
        { email: email },
        { observe: "response", headers: this.header }
    );
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetBanners() {
    return this.HTTP.get<any>(this.URLS.GetUrl().GetBanners, {
      observe: "response",
      headers: this.header,
    });
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetBannerByName(name: string) {
    return this.HTTP.get<any>(this.URLS.GetUrl(name).GetBannerByName, {
      observe: "response",
      headers: this.header,
    });
  }

  GetLiveStream() {
    return this.HTTP.get<any>('https://api.itv.az/api/liveVideoLink', {
      observe: "response",
      headers: this.header,
    });
  }

  GetBroadCastingBoard() {
    return this.HTTP.get<any>('https://api.itv.az/api/broad-casting-boards', {
      observe: "response",
      headers: this.header,
    });
  }


} //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export interface IBanner {
  id: string;
  title: string;
  position: string;
  link: string;
  image: string;
}
