import { IAdamiAward } from "./../../../models/IAdamiAward";
import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common/common.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { e } from "@angular/core/src/render3";
import { HelperService } from "src/app/services/helper/helper.service";
import { Title } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-adami-award",
  templateUrl: "./adami-award.component.html",
  styleUrls: ["./adami-award.component.scss"],
})
export class AdamiAwardComponent implements OnInit {
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private COMMON_SERVICE: CommonService,
    private SPINNER: NgxUiLoaderService,
    private HS: HelperService,
    private TS: Title
  ) {
    this.TS.setTitle("İTV - Adami Mükafatı");
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public adami: IAdamiAward;

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.GetAbout_Api();
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAbout_Api() {
    this.SPINNER.start();
    this.HS.ScrollTo();
    this.COMMON_SERVICE.GetAward().subscribe(
      (RESPONSE) => {
        this.adami = RESPONSE.body;
        setTimeout(() => {
          this.SetToggleToContent();
        }, 1000);
        setTimeout(() => {
          this.SPINNER.stop();
        }, 500);
      },
      (ERROR) => {
        this.SPINNER.stop();
      }
    );
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SetToggleToContent() {
    $(".accordion .head").click((e) => {
      $(e.target)
        .closest(".accordion")
        .find(".body")
        .slideToggle("fast", function () {});
      $(e.target).closest(".accordion").find(".head").toggleClass("active");
    });
  }
} //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
