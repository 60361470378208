import { Component, OnInit, Input } from '@angular/core';
declare var $:any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'app-content-logo',
  templateUrl: './content-logo.component.html',
  styleUrls: ['./content-logo.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class ContentLogoComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor() { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  @Input('title') title:string;
  public firsth_letter:string;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit() {
    setTimeout(() => {
      this.firsth_letter = $('.content_logo h2').html()[0];
    }, 300);
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

