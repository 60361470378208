import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UrlService } from 'src/app/services/url/url.service';
import { Observable } from 'rxjs';
import { ISchedule } from '../models/schedule';
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class ScheduleService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HTTP: HttpClient,
    private URLS: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetScheduleByDay(day_id:number) : Observable<any>{
    return this.HTTP.get<ISchedule>(
      this.URLS.GetUrl(day_id).GetScheduleByDay,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetScheduleToDay() : Observable<any>{
    return this.HTTP.get<ISchedule>(
      this.URLS.GetUrl().GetScheduleToDay,
      { observe: 'response', headers: this.header }
    )
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

