import { Component, OnInit } from '@angular/core';
import { ISchedule } from './models/schedule';
import { HelperService } from 'src/app/services/helper/helper.service';
import { ScheduleService } from './service/schedule.service';
import { DateService } from 'src/app/services/date/date.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';
declare var $: any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ 
  selector: 'app-schedule-page', 
  templateUrl: './schedule-page.component.html', 
  styleUrls: ['./schedule-page.component.scss'] 
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class SchedulePageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HS:HelperService,
    private SCHEDULE_SERVICE:ScheduleService,
    private DATE_SERVICE:DateService,
    private SS: NgxUiLoaderService,
    private TS:Title
  ) {
    this.TS.setTitle("İTV - Proqram");
   }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public week_days:IWeekDay[];
  public day:IWeekDay;
  public active = { day:0 };
  public schedule:ISchedule;
  public week_days_slider:boolean = false;
  public day_slider:boolean = false;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.GetScheduleToDay();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetScheduleToDay(){
    this.SS.start();
    this.SCHEDULE_SERVICE.GetScheduleToDay().subscribe(
        RESPONSE => {
            this.schedule = RESPONSE.body;
            this.schedule.data.evening ? this.schedule.data.evening = this.ConvertAngularRoot(this.schedule.data.evening) : "";
            this.schedule.data.morning ? this.schedule.data.morning = this.ConvertAngularRoot(this.schedule.data.morning) : "";
            this.schedule.data.afternoon ? this.schedule.data.afternoon = this.ConvertAngularRoot(this.schedule.data.afternoon) : "";
            this.schedule.data.night ? this.schedule.data.night = this.ConvertAngularRoot(this.schedule.data.night) : "";
            this.active.day = this.schedule.dayOfWeek;
            this.schedule.week_end_day = this.DATE_SERVICE.RemoveFirsthZero(this.schedule.week_end_day);
            this.schedule.week_start_day = this.DATE_SERVICE.RemoveFirsthZero(this.schedule.week_start_day);
            this.schedule.week_end_month = this.DATE_SERVICE.GetMonthName(this.schedule.week_end_month);
            this.schedule.week_start_month = this.DATE_SERVICE.GetMonthName(this.schedule.week_start_month);
            this.schedule.week_month = this.DATE_SERVICE.GetMonthName(this.schedule.week_month);
            this.ModifyWeekdays();
            setTimeout(() => {
                this.InitializeSliders();
                if(this.HS.GetDocumentWidth() > 501){
                  this.InitializeSlidersDay();
                }
                
            }, 20);
            setTimeout(() => {
                this.SS.stop();
                this.week_days_slider = true;
                this.day_slider = true
            }, 500);
        },
        ERROR => {setTimeout(() => {this.SS.stop();}, 500);}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  InitializeSliders() {
    $(".week-days").slick({
            slidesToShow: 7,
            slidesToScroll: 1,
            dots: false,
            loop: false,
            infinite: false,
            responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1
                }
            },
            {
            breakpoint: 1180,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
            },
            {
            breakpoint: 1000,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
            },
            {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
            },
            {
            breakpoint: 400,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ],
        prevArrow: "<div class='prev' ARROW-LEFT-GREY-ICON></div>",
        nextArrow: "<div class='next' ARROW-RIGHT-GREY-ICON></div>"
    });
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  InitializeSlidersDay() {
    $(".day-body").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        loop: false,
        infinite: false,
        responsive: [
        {
        breakpoint: 1140,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
        },
        {
        breakpoint: 840,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
        },
        {
        breakpoint: 540,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
        }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ],
    // arrows: true,
    prevArrow: "<div class='prev' ARROW-LEFT-GREY-ICON></div>",
    nextArrow: "<div class='next' ARROW-RIGHT-GREY-ICON></div>"
    });
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetScheduleByDay(day_id:number){
    this.day_slider = false;
    if(this.HS.GetDocumentWidth() > 501){
      $('.day-body').slick('unslick');
    }
    
    this.SS.start();
    this.SCHEDULE_SERVICE.GetScheduleByDay(day_id).subscribe(
      RESPONSE => {
        this.schedule.data = RESPONSE.body.data;
        this.schedule.data.evening ? this.schedule.data.evening = this.ConvertAngularRoot(this.schedule.data.evening) : "";
        this.schedule.data.morning ? this.schedule.data.morning = this.ConvertAngularRoot(this.schedule.data.morning) : "";
        this.schedule.data.afternoon ? this.schedule.data.afternoon = this.ConvertAngularRoot(this.schedule.data.afternoon) : "";
        this.schedule.data.night ? this.schedule.data.night = this.ConvertAngularRoot(this.schedule.data.night) : "";
      
        setTimeout(() => {this.SS.stop();}, 0);
        if(this.HS.GetDocumentWidth() > 501){
          setTimeout(() => {this.InitializeSlidersDay();}, 1);
        }
        
        setTimeout(() => {this.day_slider = true}, 2);
        
      },
      ERROR => {setTimeout(() => {this.SS.stop();}, 500);}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ModifyWeekdays(){
    for (let i = 0; i < this.schedule.weekdays.length; i++) {
      var split_date = this.DATE_SERVICE.SplitDate(this.schedule.weekdays[i].date);
      this.schedule.weekdays[i].date_day = this.DATE_SERVICE.RemoveFirsthZero(split_date.dd);
      this.schedule.weekdays[i].date_month = this.DATE_SERVICE.GetMonthName(split_date.mm);
      this.schedule.weekdays[i].date_year = split_date.yyyy;
    }
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ConvertAngularRoot(data){
    for (let i = 0; i < data.length; i++) {
      if(data[i].type_id){
        data[i].angular_root = this.HS.ConvertAngularRoot(data[i].type);
      }
      if(data[i].type == "newss"){
        data[i].angular_root ="news";
        data[i].type_id ="";
      }
    }
    return data;
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit() {
    this.HS.ScrollTo ();
  }

 //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ActivateDay(day){
    this.active.day = day;
    this.GetScheduleByDay(parseInt(day));
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


export interface IWeekDay{
  day_id:number;
  name:string;
  date:string;
}
