import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UrlService } from 'src/app/services/url/url.service';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export class VoicekidsService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HTTP: HttpClient,
    private URLS: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  header = new HttpHeaders({'Content-Type':  'application/json'});

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetVoicekidsCollection() : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl().GetVoicekidsCollection,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAllVoicekidsChapters() : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl().GetAllVoicekidsChapters,
      { observe: 'response', headers: this.header }
    )
  }


//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

// GetVoicekidsCoaches() : Observable<any>{
//   return this.HTTP.get<any>(
//     this.URLS.GetUrl().GetVoicekidsCoaches,
//     { observe: 'response', headers: this.header }
//   )
// }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetVoicekidsCoachesById(id) : Observable<any>{
  return this.HTTP.get<any>(
    this.URLS.GetUrl(id).GetVoicekidsCoachesById,
    { observe: 'response', headers: this.header }
  )
}

// /////////////////////////////////////////////////////////////////////

GetVoicekidsMembersById(id) : Observable<any>{
  return this.HTTP.get<any>(
    this.URLS.GetUrl(id).GetVoicekidsMembersById,
    { observe: 'response', headers: this.header }
  )
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetVoicekidsChapter(id) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(id).GetVoicekidsChapter,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetVoicekidsChaptersById(id:number) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(id).GetVoicekidsChaptersById,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetVoicekidsChaptersByIdAndKey(key:string) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(key).GetVoicekidsChaptersByIdAndKey,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetVoicekidsNewsChapters() : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl().GetVoicekidsNewsChapters,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetVoicekidsNewsDetail(id:number) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(id).GetVoicekidsNewsDetail,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetVoicekidsChaptersNextId(id:number) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(id).GetVoicekidsChaptersNextId,
      { observe: 'response', headers: this.header }
    )
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetVoicekidsRules() : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl().GetVoicekidsRules,
      { observe: 'response', headers: this.header }
    )
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


