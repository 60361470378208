import { SportService } from './service/sport.service';
import { Component, OnInit } from '@angular/core';
import { ISportCategories } from './models/sport';
import { Title } from '@angular/platform-browser';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'app-sport-page',
  templateUrl: './sport-page.component.html',
  styleUrls: ['./sport-page.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export class SportPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private SPORT_SERVICE:SportService,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - İdman");
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  public sport_categories:ISportCategories;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.GetSportCategories();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetSportCategories(){
    this.SPORT_SERVICE.GetSportCategories().subscribe(
      RESPONSE => {
        this.sport_categories = RESPONSE.body;
      },
      ERROR => {}
    )
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


