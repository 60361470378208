import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })


export class DateService {
  constructor() {}

  SplitDate(date) {
    var date_array = date.split("-");
    return {
      dd: date_array[0],
      mm: date_array[1],
      yyyy: date_array[2]
    };
  }

  GetMonthName(month: any) {
    if(month == null || parseInt(month) < 1 || parseInt(month) > 12){
      return null;
    }
    if(month[0] == "0"){
      month = month[1];
    }
    month = parseInt(month);
    var month_name: string;
    switch (month) {
      case 1:
        month_name = "january";
        break;
      case 2:
        month_name = "february";
        break;
      case 3:
        month_name = "march";
        break;
      case 4:
        month_name = "april";
        break;
      case 5:
        month_name = "may";
        break;
      case 6:
        month_name = "june";
        break;
      case 7:
        month_name = "july";
        break;
      case 8:
        month_name = "august";
        break;
      case 9:
        month_name = "september";
        break;
      case 10:
        month_name = "october";
        break;
      case 11:
        month_name = "november";
        break;
      case 12:
        month_name = "december";
        break;
    }
    return month_name;
  }


  RemoveFirsthZero(number:any){
    if(number == null){
      return null;
    }
    if(number[0] == "0"){
      number = number[1];
    }
    number = parseInt(number);
    return number
  }
}
