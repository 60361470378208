import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

@Component({
  selector: 'chapter-partial',
  templateUrl: './chapter-partial.component.html',
  styleUrls: ['./chapter-partial.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class ChapterPartialComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(private HS:HelperService) {}
  
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  public body_width:number;
  //NEW
  @Input('id') id : number;
  @Input('image') image : string;
  @Input('view_count') view_count : string;
  @Input('duration') duration : string;
  @Input('title') title : string;
  @Input('date') date: string;
  @Input('season') season: number;


  @Input('sport_id')            sport_id : number = 1 ;
  @Input('current_season_id')   current_season_id : number = 1 ;
  @Input('tvshow_id')           tvshow_id : number = 1 ;
  @Input('current_chapter_id')  current_chapter_id : number = 1 ;
  @Input('type')                type : string ;

  @Output() play: EventEmitter<number> = new EventEmitter();

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  ngOnInit() {
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      this.body_width = this.HS.GetDocumentWidth();
    }, 10);
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  _Play(id:number){
    this.play.emit(id);
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||