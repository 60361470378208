import { INewsReleases, INews, IDetailNews } from '../models/news';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UrlService } from 'src/app/services/url/url.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class NewsService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HTTP: HttpClient,
    private URLS: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetInitialNews() : Observable<any>{
    return this.HTTP.get<INewsReleases>(
      this.URLS.GetUrl().GetInitialNews,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreNews(page_id) : Observable<any>{
    return this.HTTP.get<INewsReleases>(
      this.URLS.GetUrl(page_id).GetMoreNews,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  UpNewsViewCount(news_id:number) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(news_id).UpNewsViewCount,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetPlayedNews(id) : Observable<any>{
    return this.HTTP.get<INews>(
      this.URLS.GetUrl(id).GetPlayedNews,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetDetailNews(news_id,detail_id) : Observable<any>{
    return this.HTTP.get<IDetailNews>(
      this.URLS.GetUrl(news_id,detail_id).GetDetailNews,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SearchNews(key) : Observable<any>{
    return this.HTTP.get<IDetailNews>(
      this.URLS.GetUrl(key).SearchNews,
      { observe: 'response', headers: this.header }
    )
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

