import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { FileUploadService } from 'src/app/services/file-upload/file-upload.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { MobilReporterService } from './services/mobil-reporter.service';
import { IMobilReporter } from './models/IMobilReporter';
import { Title } from '@angular/platform-browser';
declare var $:any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-mobil-reporter-page', templateUrl: './mobil-reporter-page.component.html', styleUrls: ['./mobil-reporter-page.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class MobilReporterPageComponent implements OnInit {
  constructor(
    private localeService : BsLocaleService,
    private FileUploadService : FileUploadService,
    private MobilReporterService : MobilReporterService,
    private HS: HelperService,
    private TS:Title
  ){
    this.TS.setTitle("İTV - Mobil müxbir");
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
  }
  locales = listLocales();
  public maxDate;
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  public email_input              = { mail:null,    required:false,validate:false,error_message:'',touched:false}
  public phone_input              = { phone:'994',required:false, validate:false,error_message:'',touched:false}
  public reportage_name_input     = { name:'',    required:true, validate:false,error_message:'',touched:false}
  public date_input               = { date:null,formatted_date:null,  required:true, validate:false,error_message:'',touched:false}
  public reportage_location_input = { name:null,    latitude:null,longitude:null,required:true, validate:false, error_message:'',touched:false}
  public reportage_text_input     = { value:'',   required:true, validate:false, error_message:'', touched:false}
  public reportage_terms_checkbox = { value:false,   required:true, validate:false, error_message:'', touched:false}
  public file_input               = { value:null,   required:false, validate:false, error_message:'', touched:false,loading:'0'}
  public reportage_form           = { success:false , is_valid:false }
  public POST_DATA: IMobilReporter = {} as any;

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ngOnInit() {
    let lang_for_datepicker = localStorage.getItem("language");
    localStorage.getItem("language") ==  'az' ? lang_for_datepicker = 'az' : lang_for_datepicker = localStorage.getItem("language") ;
    // this.localeService.use(lang_for_datepicker);
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SubmitMobilReportForm(){

  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  EmailInputChange(){
    this.email_input.touched = true;
    this.email_input.mail.includes('@') 
    && this.email_input.mail.includes('.')
    &&  this.email_input.mail.length > 5
    ? this.email_input.validate = true 
    : this.email_input.validate = false  ;
    this.CheckFormValidation();

  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  PhoneInputChange(){
    this.phone_input.touched = true;
    this.phone_input.phone.includes('994') 
    &&  this.phone_input.phone.length == 12
    ? this.phone_input.validate = true 
    : this.phone_input.validate = false  ;
    this.CheckFormValidation();

  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ReportageNameInputChange(){
    this.reportage_name_input.touched = true;
    this.reportage_name_input.name.length > 5
    ? this.reportage_name_input.validate = true 
    : this.reportage_name_input.validate = false ;
    this.CheckFormValidation();

  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  DateInputChange(value: Date): void {
    this.date_input.date = value;
    setTimeout(() => {
      let date = $('#date_input_format').html();
      this.date_input.formatted_date = date;
      if(date.length){
        this.date_input.touched = true;
        this.date_input.formatted_date
        ? this.date_input.validate = true 
        : this.date_input.validate = false ;
        this.CheckFormValidation();
      }
    }, 300);
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ReportageLocationInputChange(){
    this.reportage_location_input.touched = true;
    this.reportage_location_input.name.length > 5 
    || (this.reportage_location_input.latitude && this.reportage_location_input.longitude)
    ? this.reportage_location_input.validate = true 
    : this.reportage_location_input.validate = false ;
    this.CheckFormValidation();

  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ReportageTextInputChange(){
    this.reportage_text_input.touched = true;
    this.reportage_text_input.value.length > 10 
    ? this.reportage_text_input.validate = true 
    : this.reportage_text_input.validate = false ;
    this.CheckFormValidation();

  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  OpenMapModal(){
    $('#map_modal').slideDown('fast',function(){});
    $('body').css('overflow','hidden');
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  OpenTermsModal(){
    $('#terms_modal').slideDown('fast',function(){});
    $('body').css('overflow','hidden');

  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ReportageTermsInputChange(){
    this.reportage_terms_checkbox.touched = true;
    this.reportage_terms_checkbox.value == true
    ? this.reportage_terms_checkbox.validate = true 
    : this.reportage_terms_checkbox.validate = false ;
    this.CheckFormValidation();
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  FileInputChange(event){
    let selected_file = <File>event.target.files[0];
    if(event.target.files.length > 0){
      const FD = new FormData();
      FD.append('file',selected_file);
      this.file_input.required = true;
      this.file_input.loading = 'loading';
      this.CheckFormValidation();
  
      //SEND FILE |||||||||||||||||||||||||||||||||||||||||||||||||
      this.FileUploadService.UploadFile(FD).subscribe(
        RESPONSE => {
          this.file_input.value = RESPONSE.data.file;
          this.file_input.validate = true;
          this.file_input.loading = 'loaded';
          this.CheckFormValidation();
        },
        ERROR => {}
      )
    }
    else{
      this.file_input.loading = '0';
    }
   
    //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  CheckFileLoading(status){
    if(this.file_input.loading == status){ return true }
    else{ return false }
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  SubmitForm(){
    if(
      this.reportage_name_input.validate == true
      && this.date_input.validate == true
      && this.reportage_location_input.validate == true
      && this.reportage_text_input.validate == true
      && this.reportage_terms_checkbox.validate == true
    )
    {
      //|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
      this.POST_DATA.email    = this.email_input.mail;
      this.POST_DATA.tel      = this.phone_input.phone;
      this.POST_DATA.title    = this.reportage_name_input.name;
      this.POST_DATA.date     = this.date_input.formatted_date;
      this.POST_DATA.location = this.reportage_location_input.name;
      this.POST_DATA.long     = this.reportage_location_input.latitude;
      this.POST_DATA.lat     = this.reportage_location_input.longitude;
      this.POST_DATA.text     = this.reportage_text_input.value;
      this.POST_DATA.rules    = this.reportage_terms_checkbox.value;
      this.POST_DATA.file     = this.file_input.value;
      //SUBMIT FORM TO API |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
      this.MobilReporterService.PostMobilReporterForm(this.POST_DATA).subscribe(
        RESPONSE => {
          this.reportage_form.success = true;
        },
        ERROR => {}
      )
      //|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
    }
    this.CheckFormValidation();
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  CheckFormValidation(){
    if(
      this.reportage_name_input.validate == true
      && this.date_input.validate == true
      && this.reportage_location_input.validate == true
      && this.reportage_text_input.validate == true
      && this.reportage_terms_checkbox.validate == true
    )
    {
      if(this.file_input.required == true && this.file_input.validate == true){ this.reportage_form.is_valid = true; }
      else if(this.file_input.required == false){ this.reportage_form.is_valid = true; }
      else{this.reportage_form.is_valid = false;}
    }
    else{ this.reportage_form.is_valid = false; }
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ngAfterViewInit(){
    this.HS.ScrollTo();
  }

  GetLocation(e){
    this.reportage_location_input.name = "Xəritədən seçildi"
    this.reportage_location_input.longitude = e.lng;
    this.reportage_location_input.latitude = e.lat;

  }
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
