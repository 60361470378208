import { Component, OnInit } from '@angular/core';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'app-president-banner',
  templateUrl: './president-banner.component.html',
  styleUrls: ['./president-banner.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


export class PresidentBannerComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor() { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() { }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



