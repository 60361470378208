import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DateService } from 'src/app/services/date/date.service';
import { ScheduleService } from '../schedule-page/service/schedule.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { IWeekDay } from '../schedule-page/schedule-page.component';
import { ISchedule, IScheduleItem } from '../schedule-page/models/schedule';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
declare var $: any;

@Component({ 
  selector: 'app-schedule-detail-page', 
  templateUrl: './schedule-detail-page.component.html', 
  styleUrls: ['./schedule-detail-page.component.scss'] 
})

export class ScheduleDetailPageComponent implements OnInit {

  constructor(    
    private HS:HelperService,
    private SCHEDULE_SERVICE:ScheduleService,
    private DATE_SERVICE:DateService,
    private SS: NgxUiLoaderService,
    private AR: ActivatedRoute,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Proqram");
    this.AR.snapshot.params.day ? this.active.day_url = this.AR.snapshot.params.day : "";
    this.GetScheduleToDay();
  }
  
  public week_days:IWeekDay[];
  public day:IWeekDay;
  public active = { day:0,today:"1",day_url:null };
  public schedule:ISchedule;
  public schedule_list:IScheduleItem[] = [];
  public week_days_opacity:boolean = false;
  ngOnInit() {
    
  }


  GetScheduleToDay(){
    this.SS.start();
    this.SCHEDULE_SERVICE.GetScheduleToDay().subscribe(
      RESPONSE => {
        this.schedule = RESPONSE.body;
        this.active.day = this.schedule.dayOfWeek;
        this.active.today = this.schedule.dayOfWeek.toString();
        this.schedule.week_end_day = this.DATE_SERVICE.RemoveFirsthZero(this.schedule.week_end_day);
        this.schedule.week_start_day = this.DATE_SERVICE.RemoveFirsthZero(this.schedule.week_start_day);
        this.schedule.week_month = this.DATE_SERVICE.GetMonthName(this.schedule.week_month);
        this.ModifyWeekdays();
        this.ConcatSchedule(
          this.schedule.data.night,
          this.schedule.data.morning,
          this.schedule.data.afternoon,
          this.schedule.data.evening
        );
        setTimeout(() => {this.SS.stop();}, 1200);
        setTimeout(() => {this.InitializeSliders();}, 1);

        if(this.active.day_url && (this.active.day != this.active.day_url)){
          this.ActivateDay(this.active.day_url);
        }
      },
      ERROR => {setTimeout(() => {this.SS.stop();}, 500);}
    );
  }

  ConcatSchedule(a1,a2,a3,a4){
    this.schedule_list = [];
    a1 = a1 ? a1: []; 
    a2 = a2 ? a2: []; 
    a3 = a3 ? a3: []; 
    a4 = a4 ? a4: []; 
    a1.forEach(element => {this.schedule_list.push(element);});
    a2.forEach(element => {this.schedule_list.push(element);});
    a3.forEach(element => {this.schedule_list.push(element);});
    a4.forEach(element => {this.schedule_list.push(element);});
    setTimeout(() => {
        this.week_days_opacity = true;
    }, 600);
  }

  GetScheduleByDay(day_id:number){
    
    this.SS.start();
    this.SCHEDULE_SERVICE.GetScheduleByDay(day_id).subscribe(
      RESPONSE => {
        this.schedule.data = RESPONSE.body.data;
        this.ConcatSchedule(
          this.schedule.data.night,
          this.schedule.data.morning,
          this.schedule.data.afternoon,
          this.schedule.data.evening
        );
        setTimeout(() => {this.SS.stop();}, 1200);
        


      },
      ERROR => {setTimeout(() => {this.SS.stop();}, 500);}
    );
  }

  ModifyWeekdays(){
    for (let i = 0; i < this.schedule.weekdays.length; i++) {
      var split_date = this.DATE_SERVICE.SplitDate(this.schedule.weekdays[i].date);
      this.schedule.weekdays[i].date_day = this.DATE_SERVICE.RemoveFirsthZero(split_date.dd);
      this.schedule.weekdays[i].date_month = this.DATE_SERVICE.GetMonthName(split_date.mm);
      this.schedule.weekdays[i].date_year = split_date.yyyy;
    }
  }

  ngAfterViewInit() {
    this.HS.ScrollTo ();
  }

  ActivateDay(day){
    this.active.day = day;
    this.GetScheduleByDay(parseInt(day));
  }

  InitializeSliders() {
    $(".week-days").slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        dots: false,
        loop: false,
        infinite: false,
        responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1
            }
        },
        {
        breakpoint: 1180,
        settings: {
            slidesToShow: 5,
            slidesToScroll: 1
        }
        },
        {
        breakpoint: 1000,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 1
        }
        },
        {
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
        },
        {
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
        },
        {
        breakpoint: 400,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
        }
    ],
    prevArrow: "<div class='prev' ARROW-LEFT-GREY-ICON></div>",
    nextArrow: "<div class='next' ARROW-RIGHT-GREY-ICON></div>"
    });
}

}
