import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UrlService } from 'src/app/services/url/url.service';
import { Observable } from 'rxjs';
import { ITvSeries, ISeasons, IEpisodes, IEpisode, ILocationCategory } from '../models/tvseries';
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class TvSeriesService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HTTP: HttpClient,
    private URLS: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetInitialTvSeries() : Observable<any>{
    return this.HTTP.get<ITvSeries>(
      this.URLS.GetUrl().GetInitialTvSeries,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreTvSeries(next_page) : Observable<any>{
    return this.HTTP.get<ITvSeries>(
      this.URLS.GetUrl(next_page).GetMoreTvSeries,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetSeasonsByTvsseriesId(id) : Observable<any>{
    return this.HTTP.get<ISeasons>(
      this.URLS.GetUrl(id).GetSeasonsByTvsseriesId,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEpisodesBySeasonId(tvseries_id,season_id) : Observable<any>{
    return this.HTTP.get<IEpisodes>(
      this.URLS.GetUrl(tvseries_id,season_id).GetEpisodesBySeasonId,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreEpisodes(tvseries_id,season_id,page_id) : Observable<any>{
    return this.HTTP.get<IEpisodes>(
      this.URLS.GetUrl(tvseries_id,season_id,page_id).GetMoreEpisodes,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEpisode(tvseries_id,season_id,episode_id) : Observable<any>{
    return this.HTTP.get<IEpisode>(
      this.URLS.GetUrl(tvseries_id,season_id,episode_id).GetEpisode,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetTvseriesLocationCategory() : Observable<any>{
    return this.HTTP.get<ILocationCategory>(
      this.URLS.GetUrl().GetTvseriesLocationCategory,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


FilterTvseries(location_id) : Observable<any>{
    return this.HTTP.get<ITvSeries>(
      this.URLS.GetUrl(location_id).FilterTvseries,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
