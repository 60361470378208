import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from 'src/app/services/url/url.service';
import { IContactForm } from '../models/i-contact';
//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Injectable({ providedIn: 'root' })
//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class ContactService {
//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HttpClient: HttpClient,
    private UrlService: UrlService
  ) { }

//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  PostContactForm(DATA:IContactForm) : Observable<any>{
    return this.HttpClient.post<IContactForm>(
      this.UrlService.GetUrl().contact_form,
      DATA,
      { observe: 'response', headers: this.header }
    )
  }
  
//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
