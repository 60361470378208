import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ITvshows } from './models/tvshows';
import { TvshowsService } from './service/tvshows.service';
import { Title } from '@angular/platform-browser';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-tvshows-page', templateUrl: './tvshows-page.component.html', styleUrls: ['./tvshows-page.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class TvshowsPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HS:HelperService,
    private SS:NgxUiLoaderService,
    private TvshowsService:TvshowsService,
    private TS:Title
  ) {
    this.TS.setTitle("İTV - Verilişlər")
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public tvshows;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
      this.GetInitialTvshows();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetInitialTvshows(){
    this.SS.start();
    this.TvshowsService.GetInitialTvshows().subscribe(
      RESPONSE =>{
        this.tvshows = RESPONSE.body;
        var a=[];
        for(var b in this.tvshows.data) {
          if(parseInt(b, 10) != 0){
            a.push(this.tvshows.data[b]);
          }
        };
        this.tvshows={'data':a};
        setTimeout(() => {this.SS.stop();}, 100);
      },
      ERROR=>{setTimeout(() => {this.SS.stop();}, 100);}
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit(){
    this.HS.ScrollTo();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
