import { Component } from "@angular/core";
import { CommonService, IBanner } from "./services/common/common.service";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  constructor(private COMMON_SERVICE: CommonService) {
    this.GetGeneralInfo_Api();
    this.GetBanners_Api();
  }
  title = "app";

  ngOnInit(): void {}

  GetGeneralInfo_Api() {
    this.COMMON_SERVICE.GetGeneralInfo().subscribe(
      RESPONSE => {
        this.COMMON_SERVICE.general_information = RESPONSE.body;
      },
      ERROR => {}
    );
  }

  GetBanners_Api() {
    this.COMMON_SERVICE.GetBanners().subscribe(
      RESPONSE => {
        this.COMMON_SERVICE.banners.next(RESPONSE.body.data);
      },
      ERROR => {}
    );
  }
}
