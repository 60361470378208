//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common/common.service";
import { Router } from "@angular/router";
import { HelperService } from "src/app/services/helper/helper.service";
declare var $: any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: "app-today-slider",
  templateUrl: "./today-slider.component.html",
  styleUrls: ["./today-slider.component.scss"]
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class TodaySliderComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private COMMON_SERVICE: CommonService,
    private ROUTER: Router,
    private HS: HelperService
  ) {}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public schedule: ISchedule[];
  public slider_items: ISchedule[];
  public live_id: number;
  public opacity:boolean = false

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.GetMainTodayScheduleApi();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GoLive(id) {
    if (id == this.live_id) {
      this.ROUTER.navigate(["/live-tv"]);
    } else {
      return;
    }
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMainTodayScheduleApi() {
    this.COMMON_SERVICE.GetMainTodaySchedule().subscribe(
      RESPONSE => {
        this.schedule = RESPONSE.body.data;
        this.live_id = RESPONSE.body.isLiveScheduleId;
        this.slider_items = this.SelectFromScheduleBeginLiveEndLast(
          this.schedule,
          this.live_id
        );
        setTimeout(() => {
          this.InitializeSliders();
        }, 10);
        setTimeout(() => {
          this.opacity = true;
        }, 100);
      },
      ERROR => {}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SelectFromScheduleBeginLiveEndLast(schedule, live_id) {
    schedule;
    let live_id_index = 0;
    let selected_items = [];
    for (let i = 0; i < schedule.length; i++) {
      if (schedule[i].id == live_id) {
        live_id_index = i;
      }
    }
    for (let i = 0; i < schedule.length; i++) {
      if (i >= live_id_index) {
        selected_items.push(schedule[i]);
      }
    }
    return selected_items;
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  InitializeSliders() {
    $(".today_slider").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      loop: false,
      responsive: [
        {
          breakpoint: 970,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
      ],
    // arrows: true,
      prevArrow: "<div class='prev' ARROW-LEFT-GREY-ICON></div>",
      nextArrow: "<div class='next' ARROW-RIGHT-GREY-ICON></div>"
    });

  //   if (this.HS.GetDocumentWidth() < 500) {
  //     $("#today .slick-arrow.next").css({
  //       position: "absolute",
  //       top: "33%",
  //       right: "-2px",
  //       width: "8px",
  //       height: "14px"
  //     });
  //     $("#today .slick-arrow.prev").css({
  //       position: "absolute",
  //       top: "33%",
  //       left: "-2px",
  //       width: "8px",
  //       height: "14px"
  //     });
  //   } else {
  //     $("#today .slick-arrow.next").css({
  //       position: "absolute",
  //       top: "36%",
  //       right: "-22px"
  //     });
  //     $("#today .slick-arrow.prev").css({
  //       position: "absolute",
  //       top: "36%",
  //       left: "-22px"
  //     });
  //   }
  }
  
}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export interface ISchedule {
  id: number;
  start_time: string;
  title: string;
  badge: string;
  badge_2: string;
  type_id: number;
  type: string;
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
