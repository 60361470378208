//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { Component, OnInit } from '@angular/core';
import { TvshowsService } from '../../tvshows-page/service/tvshows.service';
import { ITvShowItem } from 'src/app/models/tvshows/ITvShowItem';
import { ILinks } from 'src/app/models/ILinks';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-company-projects-partial', templateUrl: './company-projects-partial.component.html', styleUrls: ['./company-projects-partial.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class CompanyProjectsPartialComponent implements OnInit {

  public projects = [
    {id:'1',img:'assets/images/carchi-logo.svg'},
    {id:'1',img:'assets/images/radius-logo.svg'},
    {id:'1',img:'assets/images/dikdaban-logo.svg'},
    {id:'1',img:'assets/images/bolfutbol-logo.svg'},
    {id:'1',img:'assets/images/kimharadanece-logo.svg'},
    {id:'1',img:'assets/images/zetradio-logo.svg'},
    {id:'1',img:'assets/images/sesazerbaijan-logo.svg'},
    {id:'1',img:'assets/images/talkshow-logo.svg'},
    {id:'1',img:'assets/images/itvgranada-logo.svg'},
    {id:'1',img:'assets/images/lci-logo.svg'},
    {id:'1',img:'assets/images/tubi-logo.svg'},
    {id:'1',img:'assets/images/biznesaze-logo.svg'},
    {id:'1',img:'assets/images/voicekids-logo.svg'},
    {id:'1',img:'assets/images/eurovision-logo.svg'},
    {id:'1',img:'assets/images/milyoncu-logo.svg'},
    {id:'1',img:'assets/images/itvtech-logo.svg'},
  ]

  public datename

  public current_year = new Date().getFullYear();

  // CONSTRUCTOR  ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  constructor(
    private TVSHOWS_SERVICE: TvshowsService
  ) { this.GetTvShows_Api(); }

  public tvshows:ITvShowItem[];
  public links:ILinks;

  // PAGE ON INIT  |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ngOnInit() {

  }

  GetTvShows_Api(){
    this.TVSHOWS_SERVICE.GetInitialTvshows().subscribe(
      RESPONSE => {
        this.tvshows = this.RemoveWithoutImage(RESPONSE.body.data).filter(projectStatus => projectStatus.projects_status == true);
        this.links = RESPONSE.body.links;
      },
      ERROR => {}
    );
    this.TVSHOWS_SERVICE.GetDate().subscribe(
      RESPONSE => {
        this.datename = RESPONSE.body;

      },
      ERROR => {}
    );
  }

  RemoveWithoutImage(tvshows){
    tvshows = tvshows.filter(function(value, index, arr){
      return value.logo != null;
    });
    return tvshows;
  }


  // GetTvShows_Api(){
  //   this.TVSHOWS_SERVICE.G().subscribe(
  //     RESPONSE => {
  //       this.tvshows = RESPONSE.body.data;
  //       this.links = RESPONSE.body.links;
  //     },
  //     ERROR => {}
  //   );
  // }



}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
