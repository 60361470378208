import { CommonService } from "src/app/services/common/common.service";
import { HelperService } from "src/app/services/helper/helper.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Title } from "@angular/platform-browser";
import { HeaderPartialComponent } from "./../../partials/header-partial/header-partial.component";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { listLocales } from "ngx-bootstrap/chronos";
import { FooterPartialComponent } from "../../partials/footer-partial/footer-partial.component";
import { IAppealVacancy } from "./models/IAppealVacancy";
import { AppealVacancyService } from "./services/appeal-vacancy.service";
import { DOCUMENT } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-vacancy",
  templateUrl: "./vacancy.component.html",
  styleUrls: ["./vacancy.component.scss"],
})
export class VacancyComponent implements OnInit {
  constructor(
    private localeService: BsLocaleService,
    private AppealVacancyService: AppealVacancyService,
    private SPINNER: NgxUiLoaderService,
    private HS: HelperService,
    private COMMON_SERVICE: CommonService,
    private TS: Title
  ) {
    this.TS.setTitle("İTV - Karyera");
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 36500);
    this.maxDate.setDate(this.maxDate.getDate() - 6588);
  }

  @ViewChild("cv_form") mainForm: ElementRef;
  @ViewChild("custom_nav") customNav: ElementRef;

  public POST_DATA: IAppealVacancy = {} as any;

  private store = [];
  private tempObj = {};

  locales = listLocales();
  public maxDate;
  public minDate;

  public formSuccess = false;

  private stepInfo = "step-1";
  private stepReady = false;

  public phoneInput = false;
  public emailInput = false;

  public drivingInput = "false";

  public education_degrees = [
    "Tam orta təhsil",
    "İlk peşə-iхtisas təhsili",
    "Bakalavriat",
    "Magistratura",
    "Dоktоrantura",
  ];

  public months = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "İyul",
    "Avqust",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr",
  ];

  public years = [
    "1919",
    "1920",
    "1921",
    "1922",
    "1923",
    "1924",
    "1925",
    "1926",
    "1927",
    "1928",
    "1929",
    "1930",
    "1931",
    "1932",
    "1933",
    "1934",
    "1935",
    "1936",
    "1937",
    "1938",
    "1939",
    "1940",
    "1941",
    "1942",
    "1943",
    "1944",
    "1945",
    "1946",
    "1947",
    "1948",
    "1949",
    "1950",
    "1951",
    "1952",
    "1953",
    "1954",
    "1955",
    "1956",
    "1957",
    "1958",
    "1959",
    "1960",
    "1961",
    "1962",
    "1963",
    "1964",
    "1965",
    "1966",
    "1967",
    "1968",
    "1969",
    "1970",
    "1971",
    "1972",
    "1973",
    "1974",
    "1975",
    "1976",
    "1977",
    "1978",
    "1979",
    "1980",
    "1981",
    "1982",
    "1983",
    "1984",
    "1985",
    "1986",
    "1987",
    "1988",
    "1989",
    "1990",
    "1991",
    "1992",
    "1993",
    "1994",
    "1995",
    "1996",
    "1997",
    "1998",
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
  ];

  public driving_license_categories = [
    "Yoxdur",
    "A1",
    "B",
    "BC",
    "C",
    "D",
    "BE",
    "CE",
    "DE",
  ];

  public languages = [
    "Azərbaycan dili",
    "Rus dili",
    "İngilis dili",
    "Alman dili",
    "Türk dili",
    "Fransız dili",
    "İspan dili",
    "Çin dili",
    "Ərəb dili",
    "Fars dili",
  ];

  public knowledge_level = ["Əla", "Yaxşı", "Kafi"];

  public date_input = {
    date: null,
    formatted_date: null,
    required: true,
    validate: false,
    error_message: "",
    touched: false,
  };

  public org_member_true = false;
  public kindred_true = false;
  public friend_true = false;
  public reccomendation_true = false;
  public have_work_experience = false;

  ngOnInit(): void {
    (document.querySelector("#header") as HTMLElement).style.display = "none";
    (document.querySelector(
      "section.header_area_mobil"
    ) as HTMLElement).style.display = "none";
    (document.querySelector("#footer") as HTMLElement).style.display = "none";
    let lang_for_datepicker = localStorage.getItem("language");
    localStorage.getItem("language") == "az"
      ? (lang_for_datepicker = "az")
      : (lang_for_datepicker = localStorage.getItem("language"));
    this.tempObj["salary"] = "";
    this.tempObj["sex"] = "";
    this.tempObj["birth_date"] = "";
    this.tempObj["marital_status"] = "";
    this.tempObj["military_service"] = "";
    this.tempObj["driving_license"] = "";
    this.tempObj["static_phone"] = "";
    this.tempObj["appeal_before"] = "";
    this.tempObj["organization_member"] = "";
    this.tempObj["kindred"] = "";
    this.tempObj["friends"] = "";
    this.tempObj["reccomendation"] = "";
    this.SPINNER.start();
    this.HS.ScrollTo();
    setTimeout(() => {
      this.SPINNER.stop();
    }, 1200);
  }

  ngOnDestroy() {
    (document.querySelector("#header") as HTMLElement).style.display = "block";
    (document.querySelector("#footer") as HTMLElement).style.display = "block";
  }

  onInputFocus(event) {
    let target = event.currentTarget;
    target.parentElement.classList.remove("error");
    target.parentElement.classList.add("type");
  }

  onInputClick(event) {
    let target = event.currentTarget;
    target.parentElement.classList.remove("error");
    target.parentElement.classList.add("type");
  }

  onBlurMethod(event) {
    let target = event.currentTarget;
    if (target.validity.valid || target.value.length < 1) {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.remove("error");
    } else {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.add("error");
    }
    this.tempObj[target.getAttribute("name")] = target.value;
    this.checkStepReady();
  }

  onPhoneBlur(event) {
    let target = event.currentTarget;
    if (target.value.length == 18 && target.value.includes(994)) {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.remove("error");
      this.tempObj[target.getAttribute("name")] = target.value;
      this.phoneInput = true;
    } else {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.add("error");
      this.phoneInput = false;
    }
    this.checkStepReady();
  }

  onEmailBlur(event) {
    let target = event.currentTarget;
    if (
      target.value.includes("@") &&
      target.value.includes(".") &&
      target.value.length > 5
    ) {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.remove("error");
      this.tempObj[target.getAttribute("name")] = target.value;
      this.emailInput = true;
    } else {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.add("error");
      this.emailInput = false;
    }

    this.checkStepReady();
  }

  onCustomRadioClick(event) {
    let target = event.currentTarget.querySelector("input");
    this.tempObj[target.getAttribute("name")] = target.value;
    this.checkStepReady();
    if (target.name == "organization_member") {
      this.org_member_true = false;
    }
    if (target.name == "kindred") {
      this.kindred_true = false;
    }
    if (target.name == "friends") {
      this.friend_true = false;
    }
    if (target.name == "reccomendation") {
      this.reccomendation_true = false;
    }
    if (target.name == "have_work_experience") {
      this.have_work_experience = false;
      this.checkStepReady();
    }
  }

  showHiddenInput(event) {
    let target = event.currentTarget.querySelector("input");
    if (target.name == "organization_member") {
      this.org_member_true = true;
    }
    if (target.name == "kindred") {
      this.kindred_true = true;
    }
    if (target.name == "friends") {
      this.friend_true = true;
    }
    if (target.name == "reccomendation") {
      this.reccomendation_true = true;
    }
    if (target.name == "have_work_experience") {
      this.have_work_experience = true;
      this.checkStepReady();
    }
  }

  CustomSelectBtnClick(event) {
    event.currentTarget.classList.toggle("active");
    event.currentTarget.parentElement
      .querySelector(".custom_select_content")
      .classList.toggle("active");
  }

  DrivingLicenceOptionsClick(event) {
    let target = event.currentTarget;
    target.parentElement.classList.remove("active");
    target.parentElement.parentElement.querySelector(
      ".select_selected"
    ).innerHTML = target.innerHTML;
    target.parentElement.parentElement
      .querySelector(".custom_select_btn")
      .classList.remove("active");
    this.drivingInput = target.innerHTML;
    this.checkStepReady();
  }

  DateInputChange(value: Date): void {
    document
      .querySelector(".date_input")
      .parentElement.parentElement.classList.remove("error");
    this.date_input.date = value;
    setTimeout(() => {
      let date = $("#date_input_format").html();
      this.date_input.formatted_date = date;
      this.tempObj["birth_date"] = date;
      if (date.length) {
        this.date_input.touched = true;
        this.date_input.formatted_date
          ? (this.date_input.validate = true)
          : (this.date_input.validate = false);
        this.checkStepReady();
      }
    }, 300);
  }

  // Education accordeons   ~~~~~~~~~~~~~~~~~~~

  public education_accordeons = [{}];
  public tempEduDegree = "";
  public tempEduName = "";
  public tempEduFaculty = "";
  public tempEduSpeciality = "";
  public tempEduBegM = "";
  public tempEduBegY = "";
  public tempEduEndM = "";
  public tempEduEndY = "";

  onEduBlur(event) {
    let target = event.currentTarget;
    if (target.validity.valid || target.value.length < 1) {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.remove("error");
    } else {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.add("error");
    }

    switch (target.className) {
      case "education_name_input":
        this.tempEduName = target.value;
        break;
      case "faculty_input":
        this.tempEduFaculty = target.value;
        break;
      case "specialty_input":
        this.tempEduSpeciality = target.value;
        break;
      default:
        break;
    }
    this.EduAccordeonValidity();
  }

  EducationOptionsClick(event) {
    let target = event.currentTarget;
    target.parentElement.classList.remove("active");
    target.parentElement.parentElement.querySelector(
      ".select_selected"
    ).innerHTML = target.innerHTML;
    target.parentElement.parentElement
      .querySelector(".custom_select_btn")
      .classList.remove("active");

    switch (target.dataset.input) {
      case "education_degree":
        this.tempEduDegree = target.innerHTML;
        break;
      case "education_begin_month":
        this.tempEduBegM = target.innerHTML;
        break;
      case "education_begin_year":
        this.tempEduBegY = target.innerHTML;
        break;
      case "education_end_month":
        this.tempEduEndM = target.innerHTML;
        break;
      case "education_end_year":
        this.tempEduEndY = target.innerHTML;
        break;
      default:
        break;
    }

    this.EduAccordeonValidity();
  }

  public EduAccordeonValid = false;
  EduAccordeonValidity() {
    if (
      this.tempEduDegree.length >= 3 &&
      this.tempEduName.length >= 3 &&
      this.tempEduFaculty.length >= 3 &&
      this.tempEduSpeciality.length >= 3 &&
      this.tempEduBegM.length >= 3 &&
      this.tempEduBegY.length >= 3 &&
      this.tempEduEndM.length >= 3 &&
      this.tempEduEndY.length >= 3
    ) {
      this.EduAccordeonValid = true;
    } else {
      this.EduAccordeonValid = false;
    }
  }

  AddNewEducation(event, index) {
    this.EduAccordeonValidity();
    if (this.EduAccordeonValid == true) {
      this.education_accordeons[index]["degree"] = this.tempEduDegree;
      this.education_accordeons[index]["name"] = this.tempEduName;
      this.education_accordeons[index]["faculty"] = this.tempEduFaculty;
      this.education_accordeons[index]["speciality"] = this.tempEduSpeciality;
      this.education_accordeons[index][
        "beginDate"
      ] = `${this.tempEduBegM},${this.tempEduBegY}`;
      this.education_accordeons[index][
        "endDate"
      ] = `${this.tempEduEndM},${this.tempEduEndY}`;
      this.education_accordeons.push({});

      let target = event.currentTarget;

      target.style.display = "none";
      target.parentElement.parentElement.classList.remove("active");
      this.clearEduTempValues();
      this.EduAccordeonValidity();
      this.checkStepReady();
    }
  }

  clearEduTempValues() {
    this.tempEduDegree = "";
    this.tempEduName = "";
    this.tempEduFaculty = "";
    this.tempEduSpeciality = "";
    this.tempEduBegM = "";
    this.tempEduBegY = "";
    this.tempEduEndM = "";
    this.tempEduEndY = "";
  }

  ShowEduAccordeon(event, index) {
    let target = event.currentTarget;

    if (target.classList.contains("active")) {
      if (this.tempEduDegree.length >= 3) {
        this.education_accordeons[index]["degree"] = this.tempEduDegree;
      }
      if (this.tempEduName.length >= 3) {
        this.education_accordeons[index]["name"] = this.tempEduName;
      }
      if (this.tempEduFaculty.length >= 3) {
        this.education_accordeons[index]["faculty"] = this.tempEduFaculty;
      }
      if (this.tempEduSpeciality.length >= 3) {
        this.education_accordeons[index]["speciality"] = this.tempEduSpeciality;
      }
      // begin date
      if (this.tempEduBegM.length >= 3) {
        this.education_accordeons[index]["beginDate"] = `${this.tempEduBegM},${
          this.education_accordeons[index]["beginDate"].split(",")[1]
        }`;
      }
      if (this.tempEduBegY.length >= 3) {
        this.education_accordeons[index]["beginDate"] = `${
          this.education_accordeons[index]["beginDate"].split(",")[0]
        },${this.tempEduBegY}`;
      }
      // begin date
      // end date
      if (this.tempEduEndM.length >= 3) {
        this.education_accordeons[index]["endDate"] = `${this.tempEduEndM},${
          this.education_accordeons[index]["endDate"].split(",")[1]
        }`;
      }
      if (this.tempEduEndY.length >= 3) {
        this.education_accordeons[index]["endDate"] = `${
          this.education_accordeons[index]["endDate"].split(",")[0]
        },${this.tempEduEndY}`;
      }
      // end date

      target.parentElement.parentElement.classList.remove("show");
      target.parentElement.parentElement.style.height = `${target.parentElement.offsetHeight}px`;
      target.classList.remove("active");
      this.checkStepReady();
    } else {
      target.classList.add("active");
      target.parentElement.parentElement.classList.add("show");
      target.parentElement.parentElement.style.height = `calc(${
        target.parentElement.offsetHeight
      }px + ${
        target.parentElement.parentElement.querySelector(".vacancy_acc_content")
          .offsetHeight
      }px)`;
    }
  }

  RemoveEducation(index) {
    this.education_accordeons.splice(index, 1);
    this.clearEduTempValues();
    this.checkStepReady();
  }

  // Education accordeons   ~~~~~~~~~~~~~~~~~~~

  // WORK EXPERIENCE accordeons   ~~~~~~~~~~~~~~~~~~~
  public work_accordeons = [{}];
  public tempWorkName = "";
  public tempWorkPosition = "";
  public tempWorkBegM = "";
  public tempWorkBegY = "";
  public tempWorkEndM = "";
  public tempWorkEndY = "";
  public tempWorkExitReason = "";

  onWorkBlur(event) {
    let target = event.currentTarget;
    if (target.validity.valid || target.value.length < 1) {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.remove("error");
    } else {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.add("error");
    }

    switch (target.className) {
      case "work_name_input":
        this.tempWorkName = target.value;
        break;
      case "work_position":
        this.tempWorkPosition = target.value;
        break;
      case "work_exit_reason":
        this.tempWorkExitReason = target.value;
        break;
      default:
        break;
    }
    this.WorkAccordeonValidity();
  }

  WorkOptionsClick(event) {
    let target = event.currentTarget;
    target.parentElement.classList.remove("active");
    target.parentElement.parentElement.querySelector(
      ".select_selected"
    ).innerHTML = target.innerHTML;
    target.parentElement.parentElement
      .querySelector(".custom_select_btn")
      .classList.remove("active");

    switch (target.dataset.input) {
      case "work_begin_month":
        this.tempWorkBegM = target.innerHTML;
        break;
      case "work_begin_year":
        this.tempWorkBegY = target.innerHTML;
        break;
      case "work_end_month":
        this.tempWorkEndM = target.innerHTML;
        break;
      case "work_end_year":
        this.tempWorkEndY = target.innerHTML;
        break;
      default:
        break;
    }

    this.WorkAccordeonValidity();
  }

  public WorkAccordeonValid = false;
  WorkAccordeonValidity() {
    if (
      this.tempWorkName.length >= 3 &&
      this.tempWorkPosition.length >= 3 &&
      this.tempWorkBegM.length >= 3 &&
      this.tempWorkBegY.length >= 3 &&
      this.tempWorkEndM.length >= 3 &&
      this.tempWorkEndY.length >= 3 &&
      this.tempWorkExitReason.length >= 3
    ) {
      this.WorkAccordeonValid = true;
    } else {
      this.WorkAccordeonValid = false;
    }
  }

  AddNewWork(event, index) {
    this.WorkAccordeonValidity();
    if (this.WorkAccordeonValid == true) {
      this.work_accordeons[index]["work_name"] = this.tempWorkName;
      this.work_accordeons[index]["work_position"] = this.tempWorkPosition;
      this.work_accordeons[index][
        "beginDate"
      ] = `${this.tempWorkBegM},${this.tempWorkBegY}`;
      this.work_accordeons[index][
        "endDate"
      ] = `${this.tempWorkEndM},${this.tempWorkEndY}`;
      this.work_accordeons[index]["work_exit_reason"] = this.tempWorkExitReason;
      this.work_accordeons.push({});

      let target = event.currentTarget;

      target.style.display = "none";
      target.parentElement.parentElement.classList.remove("active");
      this.clearWorkTempValues();
      this.WorkAccordeonValidity();
      this.checkStepReady();
    }
  }

  clearWorkTempValues() {
    this.tempWorkName = "";
    this.tempWorkPosition = "";
    this.tempWorkBegM = "";
    this.tempWorkBegY = "";
    this.tempWorkEndM = "";
    this.tempWorkEndY = "";
    this.tempWorkExitReason = "";
  }

  ShowWorkAccordeon(event, index) {
    let target = event.currentTarget;

    if (target.classList.contains("active")) {
      if (this.tempWorkName.length >= 3) {
        this.work_accordeons[index]["work_name"] = this.tempWorkName;
      }
      if (this.tempWorkPosition.length >= 3) {
        this.work_accordeons[index]["work_position"] = this.tempWorkPosition;
      }

      // begin date
      if (this.tempWorkBegM.length >= 3) {
        this.work_accordeons[index]["beginDate"] = `${this.tempWorkBegM},${
          this.work_accordeons[index]["beginDate"].split(",")[1]
        }`;
      }
      if (this.tempWorkBegY.length >= 3) {
        this.work_accordeons[index]["beginDate"] = `${
          this.work_accordeons[index]["beginDate"].split(",")[0]
        },${this.tempWorkBegY}`;
      }
      // begin date
      // end date
      if (this.tempWorkEndM.length >= 3) {
        this.work_accordeons[index]["endDate"] = `${this.tempWorkEndM},${
          this.work_accordeons[index]["endDate"].split(",")[1]
        }`;
      }
      if (this.tempWorkEndY.length >= 3) {
        this.work_accordeons[index]["endDate"] = `${
          this.work_accordeons[index]["endDate"].split(",")[0]
        },${this.tempWorkEndY}`;
      }
      // end date
      if (this.tempWorkExitReason.length >= 3) {
        this.work_accordeons[index][
          "work_exit_reason"
        ] = this.tempWorkExitReason;
      }

      target.parentElement.parentElement.classList.remove("show");
      target.parentElement.parentElement.style.height = `${target.parentElement.offsetHeight}px`;
      target.classList.remove("active");
      this.checkStepReady();
    } else {
      target.classList.add("active");
      target.parentElement.parentElement.classList.add("show");
      target.parentElement.parentElement.style.height = `calc(${
        target.parentElement.offsetHeight
      }px + ${
        target.parentElement.parentElement.querySelector(".vacancy_acc_content")
          .offsetHeight
      }px)`;
    }
  }

  RemoveWork(index) {
    this.work_accordeons.splice(index, 1);
    this.clearWorkTempValues();
    this.checkStepReady();
  }

  // WORK EXPERIENCE accordeons   ~~~~~~~~~~~~~~~~~~~

  // Language accordeons   ~~~~~~~~~~~~~~~~~~~
  public language_accordeons = [{}];
  public tempLangName = "";
  public tempLangKnowledgeLevel = "";

  LanguageOptionsClick(event) {
    let target = event.currentTarget;
    target.parentElement.classList.remove("active");
    target.parentElement.parentElement.querySelector(
      ".select_selected"
    ).innerHTML = target.innerHTML;
    target.parentElement.parentElement
      .querySelector(".custom_select_btn")
      .classList.remove("active");

    switch (target.dataset.input) {
      case "language_name":
        {
          this.tempLangName = target.innerHTML;
          this.LanguageAccordeonValidity();
        }
        break;
      case "language_level":
        {
          this.tempLangKnowledgeLevel = target.innerHTML;
          this.LanguageAccordeonValidity();
        }
        break;
      default:
        break;
    }
  }

  AddNewLanguage(event, index) {
    if (this.LanguageAccordeonValid == true) {
      this.language_accordeons[index]["language"] = this.tempLangName;
      this.language_accordeons[index][
        "knowledge_level"
      ] = this.tempLangKnowledgeLevel;
      this.language_accordeons.push({});

      let target = event.currentTarget;
      target.style.display = "none";
      target.parentElement.parentElement.classList.remove("active");
      this.clearLanguageTempValues();
      this.LanguageAccordeonValidity();
      this.checkStepReady();
    }
  }

  ShowLanguageAccordeon(event, index) {
    let target = event.currentTarget;

    if (target.classList.contains("active")) {
      if (this.tempLangName.length >= 3) {
        this.language_accordeons[index]["language"] = this.tempLangName;
      }
      if (this.tempLangKnowledgeLevel.length >= 3) {
        this.language_accordeons[index][
          "knowledge_level"
        ] = this.tempLangKnowledgeLevel;
      }

      target.parentElement.parentElement.classList.remove("show");
      target.parentElement.parentElement.style.height = `${target.parentElement.offsetHeight}px`;
      target.classList.remove("active");
      this.checkStepReady();
    } else {
      target.classList.add("active");
      target.parentElement.parentElement.classList.add("show");
      target.parentElement.parentElement.style.height = `calc(${
        target.parentElement.offsetHeight
      }px + ${
        target.parentElement.parentElement.querySelector(".vacancy_acc_content")
          .offsetHeight
      }px)`;
    }
  }

  public LanguageAccordeonValid = false;
  LanguageAccordeonValidity() {
    if (
      this.tempLangName.length >= 3 &&
      this.tempLangKnowledgeLevel.length >= 3
    ) {
      this.LanguageAccordeonValid = true;
    } else {
      this.LanguageAccordeonValid = false;
    }
  }

  clearLanguageTempValues() {
    this.tempLangName = "";
    this.tempLangKnowledgeLevel = "";
  }

  RemoveLanguage(index) {
    this.language_accordeons.splice(index, 1);
    this.clearLanguageTempValues();
    this.checkStepReady();
  }

  // Language accordeons   ~~~~~~~~~~~~~~~~~~~

  // program accordeons   ~~~~~~~~~~~~~~~~~~~
  public program_accordeons = [{}];
  public tempProgramName = "";
  public tempProgramKnowledgeLevel = "";

  onProgramBlur(event) {
    let target = event.currentTarget;
    if (target.validity.valid || target.value.length < 1) {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.remove("error");
    } else {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.add("error");
    }

    switch (target.className) {
      case "program_name":
        this.tempProgramName = target.value;
        break;
      default:
        break;
    }
    this.ProgramAccordeonValidity();
  }

  ProgramOptionsClick(event) {
    let target = event.currentTarget;
    target.parentElement.classList.remove("active");
    target.parentElement.parentElement.querySelector(
      ".select_selected"
    ).innerHTML = target.innerHTML;
    target.parentElement.parentElement
      .querySelector(".custom_select_btn")
      .classList.remove("active");

    switch (target.dataset.input) {
      case "program_level":
        {
          this.tempProgramKnowledgeLevel = target.innerHTML;
          this.ProgramAccordeonValidity();
        }
        break;
      default:
        break;
    }
  }

  AddNewProgram(event, index) {
    if (this.ProgramAccordeonValid == true) {
      this.program_accordeons[index]["program_name"] = this.tempProgramName;
      this.program_accordeons[index][
        "knowledge_level"
      ] = this.tempProgramKnowledgeLevel;
      this.program_accordeons.push({});

      let target = event.currentTarget;
      target.style.display = "none";
      target.parentElement.parentElement.classList.remove("active");
      this.clearProgramTempValues();
      this.ProgramAccordeonValidity();
      this.checkStepReady();
    }
  }

  ShowProgramAccordeon(event, index) {
    let target = event.currentTarget;

    if (target.classList.contains("active")) {
      if (this.tempProgramName.length >= 3) {
        this.program_accordeons[index]["program_name"] = this.tempProgramName;
      }
      if (this.tempProgramKnowledgeLevel.length >= 3) {
        this.program_accordeons[index][
          "knowledge_level"
        ] = this.tempProgramKnowledgeLevel;
      }

      target.parentElement.parentElement.classList.remove("show");
      target.parentElement.parentElement.style.height = `${target.parentElement.offsetHeight}px`;
      target.classList.remove("active");
      this.checkStepReady();
    } else {
      target.classList.add("active");
      target.parentElement.parentElement.classList.add("show");
      target.parentElement.parentElement.style.height = `calc(${
        target.parentElement.offsetHeight
      }px + ${
        target.parentElement.parentElement.querySelector(".vacancy_acc_content")
          .offsetHeight
      }px)`;
    }
  }

  public ProgramAccordeonValid = false;
  ProgramAccordeonValidity() {
    if (
      this.tempProgramName.length >= 3 &&
      this.tempProgramKnowledgeLevel.length >= 3
    ) {
      this.ProgramAccordeonValid = true;
    } else {
      this.ProgramAccordeonValid = false;
    }
  }

  clearProgramTempValues() {
    this.tempProgramName = "";
    this.tempProgramKnowledgeLevel = "";
  }

  RemoveProgram(index) {
    this.program_accordeons.splice(index, 1);
    this.clearProgramTempValues();
    this.checkStepReady();
  }

  // Program accordeons   ~~~~~~~~~~~~~~~~~~~

  // Trainings accordeons   ~~~~~~~~~~~~~~~~~~~
  public training_accordeons = [{}];
  public tempTrainingName = "";
  public tempTrainingTheme = "";
  public tempTrainingBegM = "";
  public tempTrainingBegY = "";
  public tempTrainingEndM = "";
  public tempTrainingEndY = "";

  onTrainingBlur(event) {
    let target = event.currentTarget;
    if (target.validity.valid || target.value.length < 1) {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.remove("error");
    } else {
      target.parentElement.classList.remove("type");
      target.parentElement.classList.add("error");
    }

    switch (target.className) {
      case "training_name":
        this.tempTrainingName = target.value;
        break;
      case "training_theme":
        this.tempTrainingTheme = target.value;
        break;
      default:
        break;
    }
    this.TrainingAccordeonValidity();
  }

  TrainingOptionsClick(event) {
    let target = event.currentTarget;
    target.parentElement.classList.remove("active");
    target.parentElement.parentElement.querySelector(
      ".select_selected"
    ).innerHTML = target.innerHTML;
    target.parentElement.parentElement
      .querySelector(".custom_select_btn")
      .classList.remove("active");

    switch (target.dataset.input) {
      case "training_begin_month":
        this.tempTrainingBegM = target.innerHTML;
        break;
      case "training_begin_year":
        this.tempTrainingBegY = target.innerHTML;
        break;
      case "training_end_month":
        this.tempTrainingEndM = target.innerHTML;
        break;
      case "training_end_year":
        this.tempTrainingEndY = target.innerHTML;
        break;
      default:
        break;
    }

    this.TrainingAccordeonValidity();
  }

  public TrainingAccordeonValid = false;
  TrainingAccordeonValidity() {
    if (
      this.tempTrainingName.length >= 3 &&
      this.tempTrainingTheme.length >= 3 &&
      this.tempTrainingBegM.length >= 3 &&
      this.tempTrainingBegY.length >= 3 &&
      this.tempTrainingEndM.length >= 3 &&
      this.tempTrainingEndY.length >= 3
    ) {
      this.TrainingAccordeonValid = true;
    } else {
      this.TrainingAccordeonValid = false;
    }
  }

  AddNewTraining(event, index) {
    this.TrainingAccordeonValidity();
    if (this.TrainingAccordeonValid == true) {
      this.training_accordeons[index]["training_name"] = this.tempTrainingName;
      this.training_accordeons[index][
        "training_theme"
      ] = this.tempTrainingTheme;
      this.training_accordeons[index][
        "beginDate"
      ] = `${this.tempTrainingBegM},${this.tempTrainingBegY}`;
      this.training_accordeons[index][
        "endDate"
      ] = `${this.tempTrainingEndM},${this.tempTrainingEndY}`;
      this.training_accordeons.push({});

      let target = event.currentTarget;

      target.style.display = "none";
      target.parentElement.parentElement.classList.remove("active");
      this.clearTrainingTempValues();
      this.TrainingAccordeonValidity();
      this.checkStepReady();
    }
  }

  clearTrainingTempValues() {
    this.tempTrainingName = "";
    this.tempTrainingTheme = "";
    this.tempTrainingBegM = "";
    this.tempTrainingBegY = "";
    this.tempTrainingEndM = "";
    this.tempTrainingEndY = "";
  }

  ShowTrainingAccordeon(event, index) {
    let target = event.currentTarget;

    if (target.classList.contains("active")) {
      if (this.tempTrainingName.length >= 3) {
        this.training_accordeons[index][
          "training_name"
        ] = this.tempTrainingName;
      }
      if (this.tempTrainingTheme.length >= 3) {
        this.training_accordeons[index][
          "training_theme"
        ] = this.tempTrainingTheme;
      }

      // begin date
      if (this.tempTrainingBegM.length >= 3) {
        this.training_accordeons[index]["beginDate"] = `${
          this.tempTrainingBegM
        },${this.training_accordeons[index]["beginDate"].split(",")[1]}`;
      }
      if (this.tempTrainingBegY.length >= 3) {
        this.training_accordeons[index]["beginDate"] = `${
          this.training_accordeons[index]["beginDate"].split(",")[0]
        },${this.tempTrainingBegY}`;
      }
      // begin date
      // end date
      if (this.tempTrainingEndM.length >= 3) {
        this.training_accordeons[index]["endDate"] = `${
          this.tempTrainingEndM
        },${this.training_accordeons[index]["endDate"].split(",")[1]}`;
      }
      if (this.tempTrainingEndY.length >= 3) {
        this.training_accordeons[index]["endDate"] = `${
          this.training_accordeons[index]["endDate"].split(",")[0]
        },${this.tempTrainingEndY}`;
      }
      // end date

      target.parentElement.parentElement.classList.remove("show");
      target.parentElement.parentElement.style.height = `${target.parentElement.offsetHeight}px`;
      target.classList.remove("active");
      this.checkStepReady();
    } else {
      target.classList.add("active");
      target.parentElement.parentElement.classList.add("show");
      target.parentElement.parentElement.style.height = `calc(${
        target.parentElement.offsetHeight
      }px + ${
        target.parentElement.parentElement.querySelector(".vacancy_acc_content")
          .offsetHeight
      }px)`;
    }
  }

  RemoveTraining(index) {
    this.training_accordeons.splice(index, 1);
    this.clearTrainingTempValues();
    this.checkStepReady();
  }

  // Trainings accordeons   ~~~~~~~~~~~~~~~~~~~

  nextStep(event) {
    event.preventDefault();

    switch (this.stepInfo) {
      case "step-1":
        {
          this.checkStepReady();
          if (this.stepReady == true) {
            this.mainForm.nativeElement.classList.add("step-2");
            this.customNav.nativeElement.classList.add("step-2");
            this.customNav.nativeElement.classList.remove("step-1");
            this.mainForm.nativeElement.classList.remove("step-1");
            this.stepInfo = "step-2";
            this.stepReady = false;
            this.checkStepReady();
          }
        }
        break;
      case "step-2":
        {
          this.checkStepReady();
          if (this.stepReady == true) {
            this.customNav.nativeElement.classList.remove("step-2");
            this.mainForm.nativeElement.classList.remove("step-2");
            this.customNav.nativeElement.classList.add("step-3");
            this.mainForm.nativeElement.classList.add("step-3");
            this.stepInfo = "step-3";
            this.stepReady = false;
            this.checkStepReady();
          }
        }
        break;
      case "step-3":
        {
          this.checkStepReady();
          if (this.stepReady == true) {
            this.customNav.nativeElement.classList.remove("step-3");
            this.mainForm.nativeElement.classList.remove("step-3");
            this.customNav.nativeElement.classList.add("step-4");
            this.mainForm.nativeElement.classList.add("step-4");
            this.stepInfo = "step-4";
            this.tempObj["educations"] = this.education_accordeons;
            this.stepReady = true;
            // this.checkStepReady();
          }
        }
        break;
      case "step-4":
        {
          this.checkStepReady();
          if (this.stepReady == true) {
            this.customNav.nativeElement.classList.remove("step-4");
            this.mainForm.nativeElement.classList.remove("step-4");
            this.customNav.nativeElement.classList.add("step-5");
            this.mainForm.nativeElement.classList.add("step-5");
            this.stepInfo = "step-5";
            this.tempObj["work_experience"] = this.work_accordeons;
            this.checkStepReady();
          }
        }
        break;
      case "step-5":
        {
          this.checkStepReady();
          if (this.stepReady == true) {
            this.customNav.nativeElement.classList.remove("step-5");
            this.mainForm.nativeElement.classList.remove("step-5");
            this.customNav.nativeElement.classList.add("step-6");
            this.mainForm.nativeElement.classList.add("step-6");
            this.stepInfo = "step-6";
            this.tempObj["programs"] = this.program_accordeons;
            this.tempObj["languages"] = this.language_accordeons;
            this.tempObj["trainings"] = this.training_accordeons;
            this.stepReady = false;
            this.checkStepReady();
          }
        }
        break;
      case "step-6":
        {
          this.checkStepReady();
          if (this.stepReady == true) {
            console.log(this.tempObj);
            this.POST_DATA.sex = this.tempObj["sex"];
            this.POST_DATA.marital_status = this.tempObj["marital_status"];
            this.POST_DATA.military_service = this.tempObj["military_service"];
            // this.POST_DATA.driving_license = this.tempObj["driving_license"];
            this.POST_DATA.driving_license = this.drivingInput;
            this.POST_DATA.appeal_before = this.tempObj["appeal_before"];
            this.POST_DATA.birth_date = this.tempObj["birth_date"];
            this.POST_DATA.organization_member = this.tempObj[
              "organization_member"
            ];
            this.POST_DATA.kindred = this.tempObj["kindred"];
            this.POST_DATA.friends = this.tempObj["friends"];
            this.POST_DATA.reccomendation = this.tempObj["reccomendation"];
            this.POST_DATA.structure = this.tempObj["structure"];
            this.POST_DATA.position = this.tempObj["position"];
            this.POST_DATA.salary = this.tempObj["salary"];
            this.POST_DATA.fullname = this.tempObj["fullname"];
            this.POST_DATA.citizenship = this.tempObj["citizenship"];
            this.POST_DATA.address = this.tempObj["address"];
            this.POST_DATA.reg_address = this.tempObj["reg_address"];
            this.POST_DATA.static_phone = this.tempObj["static_phone"];
            this.POST_DATA.phone = this.tempObj["phone"];
            this.POST_DATA.email = this.tempObj["email"];
            this.POST_DATA.educations = this.tempObj["educations"];
            this.POST_DATA.work_experience = this.tempObj["work_experience"];
            this.POST_DATA.programs = this.tempObj["programs"];
            this.POST_DATA.languages = this.tempObj["languages"];
            this.POST_DATA.trainings = this.tempObj["trainings"];
            this.POST_DATA.from_where_about_vacancy = this.tempObj[
              "from_where_about_vacancy"
            ];
            this.POST_DATA.additional_information = this.tempObj[
              "additional_information"
            ];

            console.log(this.POST_DATA);

            this.AppealVacancyService.PostAppealPageForm(
              this.POST_DATA
            ).subscribe(
              (RESPONSE) => {
                this.formSuccess = true;
              },
              (ERROR) => {}
            );
          }
        }
        break;
      default:
        break;
    }
  }

  prevStep(event) {
    event.preventDefault();
    switch (this.stepInfo) {
      case "step-2":
        {
          this.customNav.nativeElement.classList.remove("step-2");
          this.mainForm.nativeElement.classList.remove("step-2");
          this.customNav.nativeElement.classList.add("step-1");
          this.mainForm.nativeElement.classList.add("step-1");
          this.stepInfo = "step-1";
          this.checkStepReady();
        }
        break;
      case "step-3":
        {
          this.customNav.nativeElement.classList.remove("step-3");
          this.mainForm.nativeElement.classList.remove("step-3");
          this.customNav.nativeElement.classList.add("step-2");
          this.mainForm.nativeElement.classList.add("step-2");
          this.stepInfo = "step-2";
          this.checkStepReady();
        }
        break;
      case "step-4":
        {
          this.customNav.nativeElement.classList.remove("step-4");
          this.mainForm.nativeElement.classList.remove("step-4");
          this.customNav.nativeElement.classList.add("step-3");
          this.mainForm.nativeElement.classList.add("step-3");
          this.stepInfo = "step-3";
          this.checkStepReady();
        }
        break;
      case "step-5":
        {
          this.customNav.nativeElement.classList.remove("step-5");
          this.mainForm.nativeElement.classList.remove("step-5");
          this.customNav.nativeElement.classList.add("step-4");
          this.mainForm.nativeElement.classList.add("step-4");
          this.stepInfo = "step-4";
          // this.checkStepReady();
        }
        break;
      case "step-6":
        {
          this.customNav.nativeElement.classList.remove("step-6");
          this.mainForm.nativeElement.classList.remove("step-6");
          this.customNav.nativeElement.classList.add("step-5");
          this.mainForm.nativeElement.classList.add("step-5");
          this.stepInfo = "step-5";
          this.checkStepReady();
        }
        break;
      default:
        break;
    }
  }

  checkStepReady() {
    switch (this.stepInfo) {
      case "step-1":
        {
          if (
            this.tempObj["structure"] != null &&
            this.tempObj["structure"].length >= 2 &&
            this.tempObj["position"] != null &&
            this.tempObj["position"].length >= 2
          ) {
            this.stepReady = true;
          } else {
            if (
              this.tempObj["structure"] == null ||
              this.tempObj["structure"].length < 2
            ) {
              document
                .querySelector("#structure_input")
                .parentElement.classList.add("error");
            }
            if (
              this.tempObj["position"] == null ||
              this.tempObj["position"].length < 2
            ) {
              document
                .querySelector("#position_input")
                .parentElement.classList.add("error");
            }
          }
        }
        break;
      case "step-2":
        {
          if (
            this.tempObj["fullname"] != null &&
            this.tempObj["fullname"].length >= 11 &&
            this.tempObj["citizenship"] != null &&
            this.tempObj["citizenship"].length >= 3 &&
            this.tempObj["birth_date"] != null &&
            this.tempObj["birth_date"].length >= 10 &&
            this.tempObj["address"] != null &&
            this.tempObj["address"].length >= 8 &&
            this.tempObj["reg_address"] != null &&
            this.tempObj["reg_address"].length >= 8 &&
            this.phoneInput == true &&
            this.emailInput == true &&
            this.tempObj["sex"].length >= 2 &&
            this.tempObj["marital_status"].length >= 2 &&
            this.tempObj["military_service"].length >= 2 &&
            this.drivingInput != "false"
          ) {
            this.stepReady = true;
          } else {
            if (
              this.tempObj["fullname"] == null ||
              this.tempObj["fullname"].length < 11
            ) {
              document
                .querySelector("#fullname_input")
                .parentElement.classList.add("error");
            }
            if (
              this.tempObj["citizenship"] == null ||
              this.tempObj["citizenship"].length < 3
            ) {
              document
                .querySelector("#citizenship_input")
                .parentElement.classList.add("error");
            }
            if (
              this.tempObj["birth_date"] == null ||
              this.tempObj["birth_date"].length < 10
            ) {
              document
                .querySelector(".date_input")
                .parentElement.parentElement.classList.add("error");
            }
            if (
              this.tempObj["address"] == null ||
              this.tempObj["address"].length < 8
            ) {
              document
                .querySelector("#address_input")
                .parentElement.classList.add("error");
            }
            if (
              this.tempObj["reg_address"] == null ||
              this.tempObj["reg_address"].length < 8
            ) {
              document
                .querySelector("#reg_address_input")
                .parentElement.classList.add("error");
            }
            if (this.phoneInput == false) {
              document
                .querySelector("#phone_input")
                .parentElement.classList.add("error");
            }
            if (this.emailInput == false) {
              document
                .querySelector("#email_input")
                .parentElement.classList.add("error");
            }
          }
        }
        break;
      case "step-3":
        {
          if (this.education_accordeons.length > 1) {
            for (let i = 0; i < this.education_accordeons.length - 1; i++) {
              if (
                this.education_accordeons[i]["degree"].length >= 3 &&
                this.education_accordeons[i]["name"].length >= 3 &&
                this.education_accordeons[i]["faculty"].length >= 3 &&
                this.education_accordeons[i]["speciality"].length >= 3 &&
                this.education_accordeons[i]["beginDate"].length >= 6 &&
                this.education_accordeons[i]["endDate"].length >= 6
              ) {
                this.stepReady = true;
              }
            }
          } else {
            this.stepReady = false;
          }
        }
        break;
      case "step-4":
        {
          if (this.have_work_experience == true) {
            if (this.work_accordeons.length > 1) {
              for (let i = 0; i < this.work_accordeons.length - 1; i++) {
                if (
                  this.work_accordeons[i]["work_name"].length >= 3 &&
                  this.work_accordeons[i]["work_position"].length >= 3 &&
                  this.work_accordeons[i]["beginDate"].length >= 6 &&
                  this.work_accordeons[i]["endDate"].length >= 6 &&
                  this.work_accordeons[i]["work_exit_reason"].length >= 3
                ) {
                  this.stepReady = true;
                }
              }
            } else {
              this.stepReady = false;
            }
          } else {
            this.stepReady = true;
          }
        }
        break;
      case "step-5":
        {
          if (this.program_accordeons.length > 1) {
            this.stepReady = false;
            for (let i = 0; i < this.program_accordeons.length - 1; i++) {
              if (
                this.program_accordeons[i]["program_name"].length >= 3 &&
                this.program_accordeons[i]["knowledge_level"].length >= 3
              ) {
                this.stepReady = true;
              }
            }
          } else {
            this.stepReady = true;
          }
          if (this.language_accordeons.length > 1) {
            this.stepReady = false;
            for (let i = 0; i < this.language_accordeons.length - 1; i++) {
              if (
                this.language_accordeons[i]["language"].length >= 3 &&
                this.language_accordeons[i]["knowledge_level"].length >= 3
              ) {
                this.stepReady = true;
              }
            }
          } else {
            this.stepReady = true;
          }
          if (this.training_accordeons.length > 1) {
            this.stepReady = false;
            for (let i = 0; i < this.training_accordeons.length - 1; i++) {
              if (
                this.training_accordeons[i]["training_name"].length >= 3 &&
                this.training_accordeons[i]["training_theme"].length >= 3 &&
                this.training_accordeons[i]["beginDate"].length >= 6 &&
                this.training_accordeons[i]["endDate"].length >= 6
              ) {
                this.stepReady = true;
              }
            }
          } else {
            this.stepReady = true;
          }
        }
        break;
      case "step-6": {
        if (
          this.tempObj["appeal_before"].length >= 2 &&
          this.tempObj["organization_member"].length >= 2 &&
          this.tempObj["kindred"].length >= 2 &&
          this.tempObj["friends"].length >= 2 &&
          this.tempObj["reccomendation"].length >= 2
        ) {
          this.stepReady = true;
        }
      }
      default:
        break;
    }
  }
}
