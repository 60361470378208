import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { TvSeriesService } from '../tvseries-page/service/tvseries.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ISeason, IEpisode, ITvSerie } from '../tvseries-page/models/tvseries';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ISelectModel } from '../../common/custom-select/custom-select.component';
import { ILinks } from 'src/app/models/ILinks';
import { Title } from '@angular/platform-browser';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-tvseries-detail-page', templateUrl: './tvseries-detail-page.component.html', styleUrls: ['./tvseries-detail-page.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class TvseriesDetailPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private TvSeriesService:TvSeriesService,
    private HS:HelperService,
    private SS: NgxUiLoaderService,
    private ActivatedRoute:ActivatedRoute,
    private Router:Router,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Seriallar");
    this.Ctor();
    this.DedectRootChange();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public seasons: ISeason[];
  public tv_serie: ITvSerie;
  public active_season: ISeason;
  public episodes:IEpisode[];
  public links: ILinks;
  public active_episode :IEpisode;
  public tvseries_id:number;
  public season_id:number;
  public episode_id:number;
  public season_filter_select_items:ISelectModel[] = [];
  public select_default:string;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  Ctor(){
    this.SS.start();
    this.tvseries_id = this.ActivatedRoute.snapshot.params.id;
    this.season_id = this.ActivatedRoute.snapshot.queryParams.season;
    this.episode_id = this.ActivatedRoute.snapshot.queryParams.episode;
    this.GetSeasonsByTvsseriesId();
  }

  ngOnInit() {}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit(): void {
    this.HS.ScrollTo();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  DedectRootChange(){
    this.Router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        const id = this.ActivatedRoute.snapshot.params.id;
        if(id != this.tvseries_id){
          this.Ctor();
        }
      }
    });
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SeasonSelectChange(e){
    this.season_id = e;
    this.GetEpisodesBySeasonId(this.tvseries_id,this.season_id);
  }
  
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  CreateSeasonSelectItems(seasons:ISeason[]){
    this.season_filter_select_items = [];
    seasons.forEach((e)=>{
      var item:ISelectModel = {} as any;
      item.name = e.title;
      item.value = e.id.toString();
      this.season_filter_select_items.push(item);
    });
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetSeasonsByTvsseriesId(){
    //GET SEASONS 
    this.TvSeriesService.GetSeasonsByTvsseriesId(this.tvseries_id).subscribe(
      RESPONSE => {
        this.seasons = RESPONSE.body.data;
        this.tv_serie = RESPONSE.body.tvserie;
        
        this.CreateSeasonSelectItems(this.seasons);
        if(this.season_id){
          this.seasons.forEach((s)=>{
            if(s.id == this.season_id){
              this.active_season = s;
              this.select_default = this.active_season.title;
            }
          })
          this.GetEpisodesBySeasonId(this.tvseries_id,this.season_id);
          if(this.episode_id){
            this.PlayTargetEpisode(this.episode_id);
          }
        }
        else{
          this.active_season = this.seasons[this.seasons.length - 1];
          this.select_default = this.active_season.title;
          this.season_id = this.active_season.id;
          this.GetEpisodesBySeasonId(this.tvseries_id,this.season_id);
        }
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => {
        setTimeout(() => {this.SS.stop();}, 500);
        this.Router.navigate(['/**']);
      }
    );
  }

// //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreEpisodes(){
    this.SS.start();
    let page_id = this.links.next.split('page=')[1];
    this.TvSeriesService.GetMoreEpisodes(this.tvseries_id,this.season_id,page_id).subscribe(
      RESPONSE => { 
        RESPONSE.body.data.forEach(element => {this.episodes.push(element)});
        this.links = RESPONSE.body.links;
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => {
        setTimeout(() => {this.SS.stop();}, 500);
        this.Router.navigate(['/**']);
      }
    )
  }

// //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEpisodesBySeasonId(tvseries_id,current_season_id){
    this.TvSeriesService.GetEpisodesBySeasonId(tvseries_id,current_season_id).subscribe(
      RESPONSE => {
        this.episodes = RESPONSE.body.data;
        this.links = RESPONSE.body.links;
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => {
        setTimeout(() => {this.SS.stop();}, 500);
        this.Router.navigate(['/**']);
      }
    );
  }

// //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEpisode(){
    this.TvSeriesService.GetEpisode(this.tvseries_id,this.season_id,this.episode_id).subscribe(
      RESPONSE => {
        this.active_episode = RESPONSE.body;
        setTimeout(() => {this.SS.stop();}, 100);
        setTimeout(() => {
          this.HS.ScrollToElementId("app-embed-player"); 
        }, 100);
      },
      ERROR => {
        setTimeout(() => {this.SS.stop();}, 500);
        this.Router.navigate(['/**']);
      }
    );
  }

// //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  PlayTargetEpisode(episode_id:number){
    this.episode_id = episode_id;
    this.GetEpisode();
  }

}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
