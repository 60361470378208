//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { Component, OnInit } from '@angular/core';
import { SearchService, ISearchResult } from './service/search.service';
import { HelperService } from 'src/app/services/helper/helper.service';
declare var $:any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ 
  selector: 'search-modal', 
  templateUrl: './search-modal.component.html', 
  styleUrls: ['./search-modal.component.scss'] 
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class SearchModalComponent implements OnInit {



  // CONSTRUCTOR  ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  constructor(
    private SEARCH_SERVICE:SearchService,
    private HS:HelperService
  ) { }

  public search_result:ISearchResult[];
  public display_result:ISearchResult[];
  public search_input:string;
  public active_bar:string = "all";
  // CLOSE SEARCH MODAL |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  

  
  // PAGE ON INIT  |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ngOnInit() {
  }
  
  SearchInputChange(){
    if(this.search_input.length >= 1){
      this.GeneralSearchByKeyword(this.search_input);
    }
  }

  GeneralSearchByKeyword(keyword){
    this.SEARCH_SERVICE.GeneralSearchByKeyword(keyword).subscribe(
      RESPONSE => {
        this.search_result = RESPONSE.body.data;
        this.ConvertAngularRoot();
      },
      ERROR =>{}
    )
  }

  ConvertAngularRoot(){
    for (let i = 0; i < this.search_result.length; i++) {
      this.search_result[i].angular_root = this.HS.ConvertAngularRoot(this.search_result[i].type);
    }
    this.display_result = this.search_result;
    if(this.active_bar != "all"){
      this.FilterByCategory(this.active_bar);
    }
  }

  FilterByCategory(category){
    this.active_bar = category;
    if(this.search_result == null || this.search_result.length == 0){
      return;
    }
    if(category == "all"){
      this.display_result = this.search_result;
    }
    else if(category == "tvseries"){
      this.display_result = [];
      this.search_result.forEach(element => {
        if(element.type == "tvseries"){
          this.display_result.push(element);
        }
      });
    }
    else if(category == "movies"){
      this.display_result = [];
      this.search_result.forEach(element => {
        if(element.type == "films"){
          this.display_result.push(element);
        }
      });
    }
    else if(category == "news"){
      this.display_result = [];
      this.search_result.forEach(element => {
        if(element.type == "news"){
          this.display_result.push(element);
        }
      });
    }
    else if(category == "tvshows"){
      this.display_result = [];
      this.search_result.forEach(element => {
        if(element.type == "programs"){
          this.display_result.push(element);
        }
      });
    }
    
  }

  
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
