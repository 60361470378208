import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-about-charter',
  templateUrl: './about-charter.component.html',
  styleUrls: ['./about-charter.component.scss']
})
export class AboutCharterComponent implements OnInit {

  constructor(private COMMON_SERVICE : CommonService) {
    this.GetCharter_Api();
   }

  ngOnInit() {
  }
  public body:string;

  GetCharter_Api(){
    this.COMMON_SERVICE.GetAboutCharter().subscribe(
      RESPONSE => {
        this.body = RESPONSE.body.body;
      },
      ERROR => {}
    );
  }

}
