import { Component, OnInit, Input } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import * as Plyr from '../video-player/plyr.plugin/plyr.polyfilled.js';
import { IVideoSource } from '../video-player/video-player.component.js';
declare var $:any;


@Component({
  selector: 'app-embed-player',
  templateUrl: './embed-player.component.html',
  styleUrls: ['./embed-player.component.scss']
})
export class EmbedPlayerComponent implements OnInit {

  @Input('video') video;
  @Input('type') type:string;
  @Input('index') index:number;
  @Input('news_length') news_length:number;
  @Input('youtube_url') youtube_url:string;
  @Input('presenter') presenter:string;
  @Input('news_description') news_description:string;
  @Input('description') description:string;
  @Input('view_count') view_count:number;
  @Input('schedule') schedule:string;
  @Input('guests') guests:string;
  @Input('title') title:string;
  @Input('cast') cast:string;
  public youtube_url_not = false;
  
  @Input("date") date: string;
  @Input("director") director: string;
  @Input("year") year: string;
  @Input("imdb") imdb: string;
  @Input("duration") duration: string;
  @Input("age_restriction") age_restriction: string;
  @Input("countries") countries;
  @Input("categories") categories;
  @Input("source") source: IVideoSource;
  constructor(private META: Meta) { 

  }

  ngOnInit() {
  }
  
  ngAfterViewInit(): void {
    const player = new Plyr('#player');
  }



  ngOnChanges(changes): void {
    if(!this.youtube_url){
      setTimeout(() => {
        this.youtube_url_not = true;
      }, 4000);
    } 
    this.youtube_url = this.MakeYoutubeUrlsEmbed(this.youtube_url); 
  }

  MakeYoutubeUrlsEmbed (youtube_url){
    if(!youtube_url){
      return;
    }
  
    var video_id = youtube_url.split('v=')[1];
    if(video_id === undefined){
      return;
    }
    var ampersand_position = video_id.indexOf('&');
    if(ampersand_position != -1) {
      video_id = video_id.substring(0, ampersand_position);
    }
    return youtube_url = 'https://www.youtube.com/embed/' + video_id + '?rel=0'+'&enablejsapi=1'+'&wmode=opaque'+'&start=1'+'&modestbranding=1'+'&showinfo=0'+'&output=embed';
    // this.youtube_url = 'https://www.youtube.com/embed/-SmDVqD51JQ'  + '?rel=0&enablejsapi=1&wmode=opaque&start=1&modestbranding=1&showinfo=0&showinfo=0&controls=0'+'&output=embed';
  }

}

export const   MakeYoutubeUrlsEmbed = (youtube_url):string => {
  if(!youtube_url){
    return;
  }
  var video_id = youtube_url.split('v=')[1];
  if(video_id === undefined){
    return;
  }
  var ampersand_position = video_id.indexOf('&');
  if(ampersand_position != -1) {
    video_id = video_id.substring(0, ampersand_position);
  }
  return youtube_url = 'https://www.youtube.com/embed/' + video_id + '?rel=0'+'&enablejsapi=1'+'&wmode=opaque'+'&start=1'+'&modestbranding=1'+'&showinfo=0'+'&output=embed';
  // this.youtube_url = 'https://www.youtube.com/embed/-SmDVqD51JQ'  + '?rel=0&enablejsapi=1&wmode=opaque&start=1&modestbranding=1&showinfo=0&showinfo=0&controls=0'+'&output=embed';
}


