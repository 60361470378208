import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { HelperService } from 'src/app/services/helper/helper.service';
declare var $:any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-movies-and-tvseries-slider', templateUrl: './movies-and-tvseries-slider.component.html', styleUrls: ['./movies-and-tvseries-slider.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class MoviesAndTvseriesSliderComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private COMMON: CommonService,
    private HS: HelperService
  ) { this.GetMainTvseriesApi();}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

    public slider_items: ISliderItem[];

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
 
  ngOnInit() {
    
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  InitializeSlider(){

    $(".tv_series_slider").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      centerMode: false,
      focusOnSelect: true,
      loop:true,
      responsive: [
        {
          breakpoint: 970,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          },
          
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          },
          
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          },
          
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
      // arrows: true,
      prevArrow:
        "<div class='prev' ARROW-LEFT-GREY-ICON></div>",
      nextArrow:
        "<div class='next' ARROW-RIGHT-GREY-ICON></div>",
        
    });

  if(this.HS.GetDocumentWidth() < 500){
    $("#moviesandseries .slick-arrow.next").css({
      position: "absolute",
      top: "50%",
      right: "-4px",
      width:"8px",
      height:"14px"
    });
    $("#moviesandseries .slick-arrow.prev").css({
      position: "absolute",
      top: "50%",
      left: "-4px",
      width:"8px",
      height:"14px"
    });
  }
  else{
    $("#moviesandseries .slick-arrow.next").css({
      position: "absolute",
      top: "50%",
      right: "-22px"
    });
    $("#moviesandseries .slick-arrow.prev").css({
      position: "absolute",
      top: "50%",
      left: "-22px"
    });
  }
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMainTvseriesApi(){
    this.COMMON.GetMainTvseries().subscribe(
      RESPONSE =>{
        this.slider_items = RESPONSE.body.data;
        setTimeout(() => {
          this.InitializeSlider();
        }, 10);
      },
      ERROR =>{}
    )
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export  interface ISliderItem{
  id: number;
  title: string;
  director: string;
  schedule: string;
  live: boolean;
  image: string;
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

