import { Component, OnInit, Input } from '@angular/core';
import { ISchedule } from '../../schedule-page/models/schedule';

@Component({
  selector: 'schedule-item',
  templateUrl: './schedule-item.component.html',
  styleUrls: ['./schedule-item.component.scss']
})
export class ScheduleItemComponent implements OnInit {

  constructor() { }

  // @Input("data") item:ISchedule;
  @Input("data") item;
  @Input("live_id") live_id:number;
  ngOnInit() {
  }

}
