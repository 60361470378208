import { Component, OnInit, Input } from '@angular/core';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'app-movie',
  templateUrl: './movie.component.html',
  styleUrls: ['./movie.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class MovieComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor() { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  @Input('data') data;
  @Input('index') index:number;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


