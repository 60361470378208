import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common/common.service";
import { HelperService } from "src/app/services/helper/helper.service";
declare var $: any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ 
  selector: "app-main-slider", 
  templateUrl: "./main-slider.component.html", 
  styleUrls: [ "./main-slider.component.scss" ] 
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class MainSliderComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  constructor(
    private COMMON:CommonService,
    private HS: HelperService
  ) {
    this.GetMainSliderApi();
  }
    
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public slider_items:IMainSliderItem[];
  public width:number;
  public responsive = {
    sm:false,
    md:true
  }
  public opacity:boolean = false
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.width = this.HS.GetDocumentWidth();
    this.HS.GetDocumentWidth()
    if( this.HS.GetDocumentWidth() < 500){
      this.responsive.sm = true;
      this.responsive.md = false;
    }
  }
  ngAfterViewInit(): void {

  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  InitializeSlider(){
    $(".slider-for").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: ".slider-nav",
      autoplay: false
    });
    $(".slider-nav").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: ".slider-for",
      dots: false,
      centerMode: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 2220,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1650,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1170,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 970,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
      // arrows: true,
      prevArrow:
        "<img class='prev' src='assets/images/left-arrow-white-icon.svg'>",
      nextArrow:
        "<img class='next' src='assets/images/right-arrow-white-icon.svg'>",
        
    });

    // if(this.HS.GetDocumentWidth() < 500){
    //   setTimeout(() => {
    //     $("#main-slider .slick-arrow.next").css({
    //       position: "absolute",
    //       top: "-71%",
    //       right: "10px"
    //     });
    //     $("#main-slider .slick-arrow.prev").css({
    //       position: "absolute",
    //       top: "-71%",
    //       left: "10px"
    //     });
    //   }, 400);
    // }
    // else{
    //   setTimeout(() => {
    //     $("#main-slider .slick-arrow.next").css({
    //       position: "absolute",
    //       top: "31%",
    //       right: "-18px"
    //     });
    //     $("#main-slider .slick-arrow.prev").css({
    //       position: "absolute",
    //       top: "31%",
    //       left: "-18px"
    //     });
    //   }, 200);
    // }
  }
  
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMainSliderApi(){
    this.COMMON.GetMainSlider().subscribe(
      RESPONSE => {
        this.slider_items =  RESPONSE.body.data;
        setTimeout(() => {
          this.InitializeSlider();
        }, 10);
        setTimeout(() => {
          this.opacity = true;
          $('.fancybox-media').fancybox({
            openEffect  : 'none',
            closeEffect : 'none',
            helpers : {
              media : {}
            }
          });
        }, 100);
        
      },
      ERROR => {}
    )
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export interface IMainSliderItem{
  cover: string;
  detail: string;
  id: number;
  image: string;
  logo: null
  presenter: string;
  schedule: string;
  slider_image: string;
  slider_mob_image: string;
  title: string;
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
