//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class LanguageService {

  // INITIAL LANGUAGE  |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  currentLang = 'az';
  
  // CONSTRUCTOR  ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  constructor(private translate: TranslateService) {
    //SET INITIAL LANGUAGE 
    this.setDefaultLang(this.currentLang);
  }



  // ON PAGE RELOAD GET SELECTED LANG FROM LOCAL STORAGE  ||||||||||||||||||||||||||||||||||||||
  setDefaultLang(lang: string) {
    if(localStorage['language']){
      let local_lang = localStorage['language'];
      this.translate.setDefaultLang(local_lang);
      this.translate.use(local_lang)
    }
    else{
      localStorage.setItem('language', lang);
      this.translate.setDefaultLang(lang)
      this.translate.use(lang)
    }

  }


  // SHITCH LANGUAGE  ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('language', language);
    window.location.reload();
  }


}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
