import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { IPrivacy } from 'src/app/models/IPrivacy';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { e } from '@angular/core/src/render3';
import { HelperService } from 'src/app/services/helper/helper.service';
import { Title } from '@angular/platform-browser';
declare var $: any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||




export class PrivacyComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private COMMON_SERVICE:CommonService,
    private SPINNER: NgxUiLoaderService,
    private HS:HelperService,
    private TS:Title
  ) {
    this.TS.setTitle("İTV - Privacy Policy");
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public privacy:IPrivacy;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.GetAbout_Api();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAbout_Api(){
    this.SPINNER.start();
    this.HS.ScrollTo();
    this.COMMON_SERVICE.GetPrivacy().subscribe(
      RESPONSE =>{
        this.privacy = RESPONSE.body;
        setTimeout(() => {
          this.SetToggleToContent();

        }, 1000);
        setTimeout(() => { this.SPINNER.stop();}, 500);
      },
      ERROR =>{this.SPINNER.stop();}
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SetToggleToContent(){
    $(".accordion .head").click((e)=>{
      $(e.target).closest(".accordion").find(".body").slideToggle("fast",function(){

      });
      $(e.target).closest(".accordion").find(".head").toggleClass("active");
    })
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

