import { Component, OnInit } from '@angular/core';
import { MoviesService } from './service/movies.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {  IMovie, IMovieGenre } from './models/movies';
import { Title } from '@angular/platform-browser';
declare var $:any;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-movies-page', templateUrl: './movies-page.component.html', styleUrls: ['./movies-page.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class MoviesPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private MoviesService:MoviesService,
    private HS:HelperService,
    private SS: NgxUiLoaderService,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Filmlər");
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  public movies:IMovie;
  public genres:IMovieGenre;
  public countries:IMovieGenre;
  public active ={
    next_page_btn:false,
    content:false,
    next_page_id:null
  }
  public years = [];
  public imdbs = [
    { id:5, title:'5-6' },
    { id:6, title:'6-7' },
    { id:7, title:'7-8' },
    { id:8, title:'8-9' },
    { id:9, title:'9-10' }
  ];


//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  GetYearsForSelect(){
    let current_year = new Date().getFullYear();
    for (let i = current_year; i > 1970; i--) {
      this.years.push(i);
    }
  }

  ngOnInit() {
    this.GetYearsForSelect();
    this.GetInitialTMovies();
    this.GetMovieGenres();
    this.GetMovieCountries();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreTMovies(){
    this.SS.start();
    this.MoviesService.GetMoreMovies(this.active.next_page_id).subscribe(
      RESPONSE => { 
        RESPONSE.body.data.forEach(element => {this.movies.data.push(element)});
        this.movies.links = RESPONSE.body.links;
        this.GetNextPageId();
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR    => { setTimeout(() => {this.SS.stop();}, 500); }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetInitialTMovies(){
    this.SS.start();
    this.MoviesService.GetInitialMovies().subscribe(
      RESPONSE => {
        this.movies = RESPONSE.body;
        this.active.content = true;
        this.GetNextPageId();
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => {setTimeout(() => {this.SS.stop();}, 500);}
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMovieGenres(){
    this.MoviesService.GetMovieGenres().subscribe(
      RESPONSE => {
        this.genres = RESPONSE.body;
      },
      ERROR => {}
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMovieCountries(){
    this.MoviesService.GetMovieCountries().subscribe(
      RESPONSE => {
        this.countries = RESPONSE.body;
      },
      ERROR => {}
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetNextPageId(){
    this.movies.links.next != null ? this.active.next_page_id = this.movies.links.next.split('page=')[1] : this.active.next_page_id = null;
    this.active.next_page_id != null ? this.active.next_page_btn = true : this.active.next_page_btn = false;
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit() {
    this.HS.ScrollTo();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  FilterMoviesBtn(){
    let year       = $('input[name="year_select"]').val();
    let imdb       = $('input[name="imdb_select"]').val();
    let genre_id   = $('input[name="genre_select"]').val();
    let country_id = $('input[name="country_select"]').val();
    this.FilterMovies(year,imdb,genre_id,country_id);
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  FilterMovies(year,imdb,genre_id,country_id){
    this.SS.start();
    this.ToggleFilterBox();
    this.MoviesService.FilterMovies(year,imdb,genre_id,country_id).subscribe(
      RESPONSE => { 
        this.movies  = RESPONSE.body;
        this.GetNextPageId();
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => { setTimeout(() => {this.SS.stop();}, 500); }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreMovies(){
    this.SS.start();
    this.MoviesService.GetMoreMovies(this.active.next_page_id).subscribe(
      RESPONSE => { 
        RESPONSE.body.data.forEach(element => {this.movies.data.push(element)});
        this.movies.links = RESPONSE.body.links;
        this.GetNextPageId();
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => { setTimeout(() => {this.SS.stop();}, 500); }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  ToggleFilterBox(){
    $('.filter_body').slideToggle('fast',() => {});
    $('.filter_body .select_body').slideUp('fast',() => {});
    $('.filter_body .select_m').removeClass('active');
    $('.filter_body .select_head svg').removeClass('rotate');

  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  ToggleSelect(e){

    let select = $(e.target).closest('.select_out');
    $(select).find('.select_head svg').toggleClass('rotate');
    $(select).find('.select_m').toggleClass('active');
    $(select).find('.select_body').slideToggle('fast',() => {});

  }

  SelectItem(e){
    let select = $(e.target).closest('.select_out');
    let item_id = $(e.target).attr('data-id');
    let item_name = $(e.target).html();
    $(select).find('.select_head input').val(item_id);
    $(select).find('.select_head h4').html(item_name);
    this.ToggleSelect(e);
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
