import { Injectable } from "@angular/core";
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Injectable({ providedIn: "root" })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class UrlService {
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  constructor() {}
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  // ROOT_URL = 'https://itv-api.saffman.uk/api/';
  ROOT_URL = "https://api.itv.az/api/";
  // ROOT_URL = "http://localhost:8000/api/";
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  GetUrl(param: any = 1, param2: any = 1, param3: any = 1, param4: any = 1) {
    return {
      file_upload: "" + this.ROOT_URL + "report/file",
      mobil_reporter: "" + this.ROOT_URL + "report",
      appeal_page: "" + this.ROOT_URL + "voice/appeal",
      appeal_file: "" + this.ROOT_URL + "voice/appeal/file",
      vacancy: "" + this.ROOT_URL + "vacancy-apply",
      contact_form: "" + this.ROOT_URL + "contact/send",
      GetAllPodcasts: "" + this.ROOT_URL + "podcasts",
      GetInitialNews: "" + this.ROOT_URL + "news",
      GetMoreNews: "" + this.ROOT_URL + "news?page=" + param + "",
      GetPlayedNews: "" + this.ROOT_URL + "news/" + param + "/chapters",
      GetDetailNews:
        "" + this.ROOT_URL + "news/" + param + "/chapters/" + param2 + "",
      SearchNews: "" + this.ROOT_URL + "news/?title=" + param + "",
      UpNewsViewCount:
        "" + this.ROOT_URL + "newschapters/" + param + "/increase/",

      GetInitialTvSeries: "" + this.ROOT_URL + "tvseries",
      GetMoreTvSeries: "" + this.ROOT_URL + "tvseries?page=" + param + "",
      GetTvseriesLocationCategory: "" + this.ROOT_URL + "tvserieCategories/",
      FilterTvseries: "" + this.ROOT_URL + "tvseries?category=" + param + "",
      GetSeasonsByTvsseriesId:
        "" + this.ROOT_URL + "tvseries/" + param + "/seasons/",
      GetEpisodesBySeasonId:
        "" +
        this.ROOT_URL +
        "tvseries/" +
        param +
        "/seasons/" +
        param2 +
        "/episodes/",
      GetMoreEpisodes:
        "" +
        this.ROOT_URL +
        "tvseries/" +
        param +
        "/seasons/" +
        param2 +
        "/episodes?page=" +
        param3 +
        "",
      GetEpisode:
        "" +
        this.ROOT_URL +
        "tvseries/" +
        param +
        "/seasons/" +
        param2 +
        "/episodes/" +
        param3 +
        "",

      GetInitialMovies: "" + this.ROOT_URL + "films",
      GetMoreMovies: "" + this.ROOT_URL + "films?page=" + param + "",
      GetSingleMovieById: "" + this.ROOT_URL + "films/" + param + "",
      GetMovieGenres: "" + this.ROOT_URL + "filmCategories",
      GetMovieCountries: "" + this.ROOT_URL + "countries",
      FilterMovies:
        "" +
        this.ROOT_URL +
        `films?year=${param}&imdb=${param2}&category=${param3}&country=${param4}`,

      GetInitialTvshows: "" + this.ROOT_URL + "programs/",
      GetDate: "" + this.ROOT_URL + "settings/chaptertime",
      SearchTvshows:
        "" +
        this.ROOT_URL +
        "programs/" +
        param +
        "/chapters?title=" +
        param2 +
        "",
      GetChaptersByTvshowId:
        "" + this.ROOT_URL + "programs/" + param + "/chapters/",
      GetChapter:
        "" + this.ROOT_URL + "programs/" + param + "/chapters/" + param2 + "/",
      GetMoreChapters: `${this.ROOT_URL}programs/${param}/chapters?page=${param2}`,

      GetScheduleByDay: `${this.ROOT_URL}schedules/${param}`,
      GetScheduleToDay: `${this.ROOT_URL}schedules/`,
      GetScheduleToDayAll: `${this.ROOT_URL}schedules/?home=1`,

      GetSportCategories: `${this.ROOT_URL}sportCategories/`,
      GetSport: `${this.ROOT_URL}sportCategories/${param}`,
      GetSportChaptersById: `${this.ROOT_URL}sportVideos?category=${param}`,

      GeneralSearchByKeyword: `${this.ROOT_URL}search/?q=${param}`,

      GetMainSlider: `${this.ROOT_URL}programs/?slider=1`,
      GetMainnews: `${this.ROOT_URL}news/?home=1`,
      GetMainTvshows: `${this.ROOT_URL}programs/?latest=1`,
      GetMainTvseries: `${this.ROOT_URL}tvseries/?home=1`,
      GetMenu: `${this.ROOT_URL}menu`,

      GetEurovisionCollection: `${this.ROOT_URL}eurovisions`,
      GetAllEurovisionChapters: `${this.ROOT_URL}eurovisionEpisodes`,
      GetEurovisionChapter: `${this.ROOT_URL}eurovisionEpisodes/${param}`,
      GetEurovisionChaptersById: `${this.ROOT_URL}eurovisionEpisodes?eurovision_id=${param}`,
      GetEurovisionChaptersByIdAndKey: `${this.ROOT_URL}eurovisionEpisodes?${param}`,
      GetEurovisionNewsChapters: `${this.ROOT_URL}eurovisionArticles`,
      GetEurovisionNewsDetail: `${this.ROOT_URL}eurovisionArticles/${param}`,
      GetEurovisionChaptersNextId: `${this.ROOT_URL}eurovisionEpisodes?page=${param}`,
      GetEurovisionRules: `${this.ROOT_URL}rules`,
      Pagination: `${param}`,

      GetVoicekidsCollection: `${this.ROOT_URL}voice/seasons/`,
      GetAllVoicekidsChapters: `${this.ROOT_URL}voice/episodes`,
      GetVoicekidsChapter: `${this.ROOT_URL}voice/episodes/${param}`,
      GetMaskaChapter: `${this.ROOT_URL}maska/episodes/${param}`,
      GetVoicekidsChaptersById: `${this.ROOT_URL}voice/episodes/?season_id=${param}`,
      GetMaskasChaptersById: `${this.ROOT_URL}maska/episodes`,
      GetVoicekidsChaptersByIdAndKey: `${this.ROOT_URL}voice/episodes/${param}`,
      GetVoicekidsCoaches: `${this.ROOT_URL}voice/coaches/`,
      GetVoicekidsCoachesById: `${this.ROOT_URL}voice/coaches/?season_id=${param}`,
      GetVoicekidsMembersById: `${this.ROOT_URL}voice/members/${param}`,
      GetVoicekidsNewsChapters: `${this.ROOT_URL}voice/articles`,
      MaskaNewsChapters: `${this.ROOT_URL}maska/articles`,
      GetVoicekidsNewsDetail: `${this.ROOT_URL}voice/articles/${param}`,
      GetMaskaNewsDetail: `${this.ROOT_URL}maska/articles/${param}`,
      GetVoicekidsChaptersNextId: `${this.ROOT_URL}voice/episodes/?page=${param}`,
      GetMaskaChaptersNextId: `${this.ROOT_URL}maska/episodes/?page=${param}`,
      GetVoicekidsRules: `${this.ROOT_URL}rules`,

      GetAbout: `${this.ROOT_URL}aboutus`,
      GetAward: `${this.ROOT_URL}award`,
      GetPrivacy: `${this.ROOT_URL}privacy`,
      SubscribeWithEmail: `${this.ROOT_URL}subscribe`,
      GetGeneralInfo: `${this.ROOT_URL}settings/contact`,

      GetBanners: `${this.ROOT_URL}banners/`,
      GetBannerByName: `${this.ROOT_URL}banners/${param}`,
      GetAboutCharter: `${this.ROOT_URL}regulation`,
      GetAboutStructure: `${this.ROOT_URL}staffs`,
    };
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

//general-information :GET
// {
//   "location":"Bakı şəhəri, Şərifzadə küçəsi 241, AZ1012",
//   "latitude":"12312412",
//   "longitude":"515235234",
//   "phone":["(+ 994 12) 430-23-04"],
//   "fax":"Fax (+994 12) 430-34-26,
//   "email":"info@itv.az",
//   "facebook":"info@itv.az",
//   "twitter":"info@itv.az",
//   "youtube":"info@itv.az",
//   "instagram":"info@itv.az"
// }

// contact :POST

// {
//   "name":"",[require] [string]
//   "email":"",[require] [string]
//   "phone":"",[] [string]
//   "title":"",[require] [string]
//   "text":"",[require] [string]
// }
