import { Component, OnInit, Input} from '@angular/core';
import { VoicekidsService } from 'src/app/services/apis/voicekids/voicekids.service';
import { IVoicekids } from 'src/app/models/voicekids/IVoicekids';
import { ISelectModel } from '../../common/custom-select/custom-select.component';
import { IVoicekidsChapter } from 'src/app/models/voicekids/IVoicekidsChapter';
import { IVoicekidsCoaches } from 'src/app/models/voicekids/IVoicekidsCoaches';
import { IVoicekidsMember } from 'src/app/models/voicekids/IVoicekidsMember';
import { ILinks } from 'src/app/models/ILinks';
import { ActivatedRoute, Router } from '@angular/router';
import { IVoicekidsNewsChapter } from 'src/app/models/voicekids/IVoicekidsNewsChapter';
import { HelperService } from 'src/app/services/helper/helper.service';
import { PlatformLocation } from '@angular/common';
import { Title, BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { templateSourceUrl } from '@angular/compiler';
declare var $: any;


@Component({
  selector: 'app-voice-kids',
  templateUrl: './voice-kids.component.html',
  styleUrls: ['./voice-kids.component.scss']
})
export class VoicekidsComponent implements OnInit {

  imports: [HttpClientModule, HttpHeaders, FormsModule, BrowserModule]

  constructor(
    private VOICEKIDS_SERVICE: VoicekidsService,
    private ACTIVATED_ROUTE: ActivatedRoute,
    private HS: HelperService,
    private ROUTER: Router,
    private LOCATION: PlatformLocation,
    private TS: Title,
    private http: HttpClient
  ) {
    this.TS.setTitle("İTV - VoiceKids");
    LOCATION.onPopState(() => {
      setTimeout(() => {
        this.ngOnInit();
      }, 10);
    });
  }


public voicekidss: IVoicekids[];
public voicekids_active: IVoicekids = {} as any;
public year_filter_select_items:ISelectModel[] = [];

public voicekids_chapters:IVoicekidsChapter[];
public voicekids_coaches: IVoicekidsCoaches[];
public voicekids_member: IVoicekidsMember[];
public voicekids_played_chapter:IVoicekidsChapter;
public voicekids_chapters_links:ILinks;

public voicekids_news_chapters:IVoicekidsNewsChapter[];
public voicekids_news_chapters_links:ILinks;

public active_season_id: number;
public active_chapter_id: number;
public active_member_id: number = 1;
public active_member_episodes: any[];
public active_chapter_content: string = "chapters";
public chaptes_search_key: string;
public year_select_detaul_value: string ='Bütün buraxılışlar'
public rules: any;
public coach_id: number = 0;
public member_id: number;
public members: any;


ngOnInit() {
  this.active_season_id = this.ACTIVATED_ROUTE.snapshot.queryParams.season;
  this.active_chapter_id = this.ACTIVATED_ROUTE.snapshot.queryParams.chapter;
  // this.ACTIVATED_ROUTE.queryParams
  this.active_chapter_id ? "" : '';
  this.GetVoicekidsCollection_Api();
  this.GetVoicekidsCoachesById_Api(this.active_season_id);
  this.GetVoicekidsMemberById_Api(this.active_member_id);
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

ngAfterViewInit(): void {
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

ChaptersSearchInputChange(){

  if(this.chaptes_search_key.length >= 1){
    if(this.active_season_id){
      this.GetVoicekidsChaptersByIdAndKey_Api(`voicekids_id=${this.active_season_id}&title=${this.chaptes_search_key}`);
    }
    else{
      this.GetVoicekidsChaptersByIdAndKey_Api(`title=${this.chaptes_search_key}`);
    }
  }

  else if (this.chaptes_search_key.length == 0){
    if(this.active_season_id){
      this.GetVoicekidsChaptersById_Api(this.active_season_id);
    }
    else{
      this.GetAllVoicekidsChapters_Api();
    }
  }
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetAllVoicekidsChapters_Api(){
  this.VOICEKIDS_SERVICE.GetAllVoicekidsChapters().subscribe(
    RESPONSE => {
      this.voicekids_chapters = RESPONSE.body.data;
      this.voicekids_chapters_links = RESPONSE.body.links;
    },
    ERROR => {}
  );
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetVoicekidsRules_Api(){
  this.VOICEKIDS_SERVICE.GetVoicekidsRules().subscribe(
    RESPONSE => {
      this.rules = RESPONSE.body;
      setTimeout(() => {
        this.SetToggleToContent();
      }, 300);
    },
    ERROR => {}
  );
}

/////////////////////////////////////////////////////////////////////////

// GetVoicekidsCoaches_Api(){
//   this.VOICEKIDS_SERVICE.GetVoicekidsCoaches().subscribe(
//     RESPONSE =>{
//       this.voicekids_coaches = RESPONSE.body.data;
//       this.members = this.voicekids_coaches[this.coach_id].members;
//     },
//     ERROR => {}
//   );
// }


//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetVoicekidsCoachesById_Api(id:number){
  this.VOICEKIDS_SERVICE.GetVoicekidsCoachesById(id).subscribe(
    RESPONSE => {
        this.voicekids_coaches = RESPONSE.body.data;
        this.members = this.voicekids_coaches[this.coach_id].members;
    },
    ERROR => {}
  );
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetVoicekidsMemberById_Api(id:number){
  this.VOICEKIDS_SERVICE.GetVoicekidsMembersById(id).subscribe(
    RESPONSE => {
        this.voicekids_member = RESPONSE.body;
        this.active_member_episodes = RESPONSE.body.episodes;
    },
    ERROR => {}
  );
}


//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetVoicekidsChaptersById_Api(id:number){
  this.VOICEKIDS_SERVICE.GetVoicekidsChaptersById(id).subscribe(
    RESPONSE => {
      this.voicekids_chapters = RESPONSE.body.data
      this.voicekids_chapters_links = RESPONSE.body.links;
      if(this.active_chapter_id){
        this.PlayTargetChapter(this.active_chapter_id);
      }
    },
    ERROR => {}
  );
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetVoicekidsCollection_Api(){
  this.VOICEKIDS_SERVICE.GetVoicekidsCollection().subscribe(
    RESPONSE => {
      this.voicekidss = RESPONSE.body.data;
      this.voicekids_active = this.voicekidss[0];
      this.GetSeasonByUrlParams();
      this.CreateSeasonSelectItems(this.voicekidss);
    },
    ERROR => {}
  );
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

ChangeChapterNav(route){
  if(this.active_chapter_content != route){
    this.active_chapter_content = route;
    if(route == "news" && !this.voicekids_news_chapters){
      this.GetVoicekidsNewsChapters_Api();
    }
    if(route == "chapters" && !this.voicekids_chapters){
      this.GetSeasonByUrlParams();
    }
    if(route == "teams" && !this.voicekids_coaches){
      this.GetVoicekidsCoachesById_Api(this.active_season_id);
    }
    if(route == "member" && !this.voicekids_member)
    {
      this.GetVoicekidsMemberById_Api(this.active_member_id);
    }
    if(route == "memberinside" && !this.voicekids_member)
    {
      this.GetVoicekidsMemberById_Api(this.active_member_id);
    }
    if(route == "rules" && !this.rules){
      this.GetVoicekidsRules_Api();
    }
    else{
      setTimeout(() => {
        this.SetToggleToContent();
      }, 300);
    }
  }
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetVoicekidsNewsChapters_Api(){
  this.VOICEKIDS_SERVICE.GetVoicekidsNewsChapters().subscribe(
    RESPONSE => {
      this.voicekids_news_chapters = RESPONSE.body.data;
      this.voicekids_news_chapters_links = RESPONSE.body.links;

    },
    ERROR => {}
  );
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetSeasonByUrlParams(){
  if(this.active_season_id){
    this.GetVoicekidsChaptersById_Api(this.active_season_id);
    this.GetVoicekidsCoachesById_Api(this.active_season_id);
    this.voicekidss.forEach(e => {
      if(e.id == this.active_season_id){
        this.voicekids_active = e;
        this.year_select_detaul_value = this.voicekids_active.title;
      }
    })
  }
  else{
    this.voicekids_active = this.voicekidss[0];
    this.active_season_id = this.voicekids_active.id;
    this.year_select_detaul_value = this.voicekids_active.title;
    this.ROUTER.navigate([], {
      queryParams: {
        season: this.active_season_id
      },
      queryParamsHandling: 'merge',
    });
    this.GetVoicekidsChaptersById_Api(this.active_season_id);
    this.GetVoicekidsCoachesById_Api(this.active_season_id);
    // this.GetAllVoicekidsChapters_Api();
  }
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetMoreChapters(){
  this.GetVoicekidsChaptersNextId_Api();
  // this.Paginate_Api(this.voicekids_chapters_links.next);
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetVoicekidsChaptersByIdAndKey_Api(key){
  this.VOICEKIDS_SERVICE.GetVoicekidsChaptersByIdAndKey(key).subscribe(
    RESPONSE => {
      this.voicekids_chapters = RESPONSE.body.data;
      this.voicekids_chapters_links = RESPONSE.body.links;
    },
    ERROR => {}
  );
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

YearSelectChange(value:any){
  this.active_season_id = parseInt(value);
  this.voicekidss.forEach(e => {
    if(e.id == this.active_season_id){
      this.voicekids_active = e;
    }
  })
  this.GetVoicekidsChaptersById_Api(this.active_season_id);
  this.GetVoicekidsCoachesById_Api(this.active_season_id);
  this.ROUTER.navigate([], {
    queryParams: {
      season: this.active_season_id
    },
    queryParamsHandling: 'merge',
  });
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

CreateSeasonSelectItems(voicekidss:IVoicekids[]){
  voicekidss.forEach((e)=>{
    var item:ISelectModel = {} as any;
    item.name = e.title;
    item.value = e.id.toString();
    this.year_filter_select_items.push(item);
  });
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

PlayTargetChapter(id:any){
  this.voicekids_chapters.forEach((chapter) =>{
    if(chapter.id == id){
      this.voicekids_played_chapter = chapter;
      this.VOICEKIDS_SERVICE.GetVoicekidsChapter(id).subscribe(
        RESPONSE =>{}
      )
    }
  })
  this.HS.ScrollToElementId("app-embed-player");
}



PlayTargetChapterInside(id:any){

  this.voicekids_chapters.forEach((chapter) =>{
    if(chapter.id == id){
      this.voicekids_played_chapter = chapter;
      this.ChangeChapterNav("memberinside");
      this.VOICEKIDS_SERVICE.GetVoicekidsChapter(id).subscribe(
        RESPONSE =>{}
      )
    }
  })
  this.HS.ScrollToElementId("app-embed-player");
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

GetVoicekidsChaptersNextId_Api(){
  var next_id:number = parseInt(this.voicekids_chapters_links.next.split("?page=")[1]);
  this.VOICEKIDS_SERVICE.GetVoicekidsChaptersNextId(next_id).subscribe(
    RESPONSE =>{
      RESPONSE.body.data.forEach(chapter => {
        this.voicekids_chapters.push(chapter);
      });
      this.voicekids_chapters_links = RESPONSE.body.links;
    },
    ERROR => {}
  )
}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

SetToggleToContent(){
  $(".accordion .head").click((e)=>{
    $(e.target).closest(".accordion").find(".body").slideToggle("fast",function(){});
    $(e.target).closest(".accordion").find(".head").toggleClass("active");
  })
}


changeCoache(id){
  if(this.voicekids_coaches.length > 0){
    this.coach_id = id;
    this.members = this.voicekids_coaches[this.coach_id].members;
  }
}

activateChosenMember(){
  setTimeout(()=>{
    this.ChangeChapterNav("member");
  }, 500);
}

selectChosenMember(id){
  this.active_member_id = id;
  this.GetVoicekidsMemberById_Api(id);
}

changeCoacheOnMember(id){
  this.changeCoache(id);
  this.ChangeChapterNav("teams");
}



// onFetchPosts(){
//   this.fetchPosts();
// }
// private fetchPosts(){
//   this.http.get('http://api.itv.az/api/voice/coaches',
//   { observe: 'response', headers: {'Content-Type':  'application/json'} }
//   ).subscribe(posts => {
//     console.log(posts);
//     var teams = posts;
//   });
// }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


