import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { IStructure } from './structure-item/structure-item.component';

@Component({
  selector: 'app-about-structure',
  templateUrl: './about-structure.component.html',
  styleUrls: ['./about-structure.component.scss']
})
export class AboutStructureComponent implements OnInit {

  constructor(private COMMON_SERVICE : CommonService) {
    this.GetStructure_Api();
  }
  public structures:IStructure[];


  ngOnInit() {
  }

  GetStructure_Api(){
    this.COMMON_SERVICE.GetAboutStructure().subscribe(
      RESPONSE => {
        this.structures = RESPONSE.body.data;
      },
      ERROR => {}
    );
  }

}
