import { Component, OnInit } from '@angular/core';
import { IGeneralInformation } from 'src/app/models/IGeneralInformation';
import { HelperService } from 'src/app/services/helper/helper.service';
import { IContactForm } from './models/i-contact';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ContactService } from './services/contact.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Title } from '@angular/platform-browser';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-contact-page', templateUrl: './contact-page.component.html', styleUrls: ['./contact-page.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class ContactPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public POST_DATA    : IContactForm        = {} as any;
  public name_input    = { value:null, required:true, validate:false, error_message:'', touched:false}
  public email_input   = { value:null, required:true, validate:false, error_message:'', touched:false}
  public phone_input   = { value:'', required:true, validate:false, error_message:'', touched:false}
  public title_input   = { value:null, required:true, validate:false, error_message:'', touched:false}
  public text_input    = { value:null, required:true, validate:false, error_message:'', touched:false}
  public form          = { success:false , is_valid:false }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HS: HelperService,
    private ContactService: ContactService,
    private SS: NgxUiLoaderService,
    private COMMON_SERVICE: CommonService,
    private TS:Title
  ) {
    this.TS.setTitle("İTV - Əlaqə");
  }

  public general_information:IGeneralInformation;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.SS.start();
    setTimeout(() => {this.SS.stop();}, 500);
  }

  GetGeneralInformation(){
    setTimeout(() => {
      this.general_information = this.COMMON_SERVICE.general_information;
    }, 3000);
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  NameInputChange(){
    this.name_input.touched = true;
    this.name_input.value.length > 2
    ? this.name_input.validate = true 
    : this.name_input.validate = false  ;
    this.CheckFormValidation();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  EmailInputChange(){
    this.email_input.touched = true;
    this.email_input.value.includes('@') 
    && this.email_input.value.includes('.')
    &&  this.email_input.value.length > 5
    ? this.email_input.validate = true 
    : this.email_input.validate = false  ;
    this.CheckFormValidation();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  PhoneInputChange(){
    this.phone_input.touched = true;
    this.phone_input.value.length > 9
    ? this.phone_input.validate = true 
    : this.phone_input.validate = false  ;
    this.CheckFormValidation();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  PhoneInputFocus(){
    this.phone_input.value.length <= 3 ? this.phone_input.value = '994' : '' ;
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  
  TitleInputChange(){
    this.title_input.touched = true;
    this.title_input.value.length > 5
    ? this.title_input.validate = true 
    : this.title_input.validate = false  ;
    this.CheckFormValidation();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  TextInputChange(){
    this.text_input.touched = true;
    this.text_input.value.length > 5
    ? this.text_input.validate = true 
    : this.text_input.validate = false  ;
    this.CheckFormValidation();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  CheckFormValidation(){
    if(
      this.name_input.validate == true
      && this.email_input.validate == true
      && this.title_input.validate == true
      && this.text_input.validate == true
    )
    { this.form.is_valid = true; return true; }
    else{ this.form.is_valid = false; return false;}
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit(){
    this.HS.ScrollTo();
    this.GetGeneralInformation();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SubmitForm(){
    if( this.CheckFormValidation())
    {
      //|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
      this.POST_DATA.name    = this.name_input.value;
      this.POST_DATA.email   = this.email_input.value;
      this.POST_DATA.phone   = this.phone_input.value;
      this.POST_DATA.title   = this.title_input.value;
      this.POST_DATA.text    = this.text_input.value;

      //SUBMIT FORM TO API |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
      this.ContactService.PostContactForm(this.POST_DATA).subscribe(
        RESPONSE => {
          this.form.success = true;
          
        },
        ERROR => {}
      )
      //|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
    }
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
