import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../../services/common/common.service';
import {IStructure} from '../about-structure/structure-item/structure-item.component';

@Component({
    selector: 'app-broadcasting-board-page',
    templateUrl: './broadcasting-board-page.component.html',
    styleUrls: ['./broadcasting-board-page.component.scss']
})
export class BroadcastingBoardPageComponent implements OnInit {

    constructor(private COMMON_SERVICE: CommonService) {
        this.GetBroadCastingBoard();
    }
    public structures: any;
    ngOnInit() {
    }

    GetBroadCastingBoard() {
        this.COMMON_SERVICE.GetBroadCastingBoard().subscribe(
            RESPONSE => {
                this.structures = RESPONSE.body.data;
            },
            ERROR => {}
        );
    }
}
