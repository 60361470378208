//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskModule } from "ngx-mask";
import { AgmCoreModule } from "@agm/core";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { PrivacyComponent } from "./components/pages/privacy/privacy.component";

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from "@angular/common/http";

//COMPONENTS  ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import {
    AppRoutingModule,
    RoutingComponents,
} from "./app-routing/app-routing.module";
import { MainSliderComponent } from "./components/pages/main-page/main-slider/main-slider.component";
import { TodaySliderComponent } from "./components/pages/main-page/today-slider/today-slider.component";
import { NewsPartialComponent } from "./components/pages/main-page/news-partial/news-partial.component";
import { MobilReporterBannerComponent } from "./components/pages/main-page/mobil-reporter-banner/mobil-reporter-banner.component";
import { MoviesAndTvseriesSliderComponent } from "./components/pages/main-page/movies-and-tvseries-slider/movies-and-tvseries-slider.component";
import { FooterPartialComponent } from "./components/partials/footer-partial/footer-partial.component";
import { HeaderPartialComponent } from "./components/partials/header-partial/header-partial.component";
import { BannerTypeOneComponent } from "./components/partials/banner-type-one/banner-type-one.component";
import { NotFoundPageComponent } from "./components/helpers/not-found-page/not-found-page.component";
import { CompanyProjectsPartialComponent } from "./components/pages/main-page/company-projects-partial/company-projects-partial.component";
import { TvshowsLastPartialComponent } from "./components/pages/main-page/tvshows-last-partial/tvshows-last-partial.component";
import { RadioshowsLastPartialComponent } from "./components/pages/main-page/radioshows-last-partial/radioshows-last-partial.component";
import { SearchModalComponent } from "./components/partials/header-partial/search-modal/search-modal.component";
import { HoverMenuPartialComponent } from "./components/partials/header-partial/hover-menu-partial/hover-menu-partial.component";
import { DetailCoverPartialComponent } from "./components/partials/detail-cover-partial/detail-cover-partial.component";

//DATEPICKER  ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
// import { defineLocale } from 'ngx-bootstrap/chronos';
// import { ruLocale } from 'ngx-bootstrap/locale';
// defineLocale('ru', ruLocale);
// import { idLocale } from 'ngx-bootstrap/locale';
import { MapModalComponent } from "./components/pages/mobil-reporter-page/map-modal/map-modal.component";
import { TermsModalComponent } from "./components/pages/mobil-reporter-page/terms-modal/terms-modal.component";
// defineLocale('az', idLocale);
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SafeUrlPipe } from "./services/safe-url-pipe/safe-url.pipe";
import { CustomSpinnerComponent } from "./components/helpers/custom-spinner/custom-spinner.component";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { SafeHtmlPipe } from "./services/safe-html/safe-html.pipe";
import { ChapterPartialComponent } from "./components/partials/chapter-partial/chapter-partial.component";
import { EmbedPlayerComponent } from "./components/partials/embed-player/embed-player.component";
import { VideoPlayerComponent } from "./components/partials/video-player/video-player.component";
import { TvserieComponent } from "./components/pages/tvseries-page/tvserie/tvserie.component";
import { MovieComponent } from "./components/pages/movies-page/movie/movie.component";
import { ContentLogoComponent } from "./components/partials/content-logo/content-logo.component";
import { ImageDirective } from "./services/image-default/image.directive";
import { ScheduleItemComponent } from "./components/pages/schedule-detail-page/schedule-item/schedule-item.component";
import { LivePlayerComponent } from "./components/partials/live-player/live-player.component";
import { ChapterComponent } from "./components/pages/main-page/tvshows-last-partial/chapter/chapter.component";
import { ModalContainerComponent } from "./components/partials/modal-container/modal-container.component";
import { CustomSelectComponent } from "./components/common/custom-select/custom-select.component";
import { ArticleChapterComponent } from "./components/common/news-chapter/article-chapter.component";
import { TvshowComponent } from "./components/pages/tvshows-page/tvshow/tvshow.component";
import { SearchItemComponent } from "./components/partials/header-partial/search-modal/search-item/search-item.component";
import { SubscribeModalComponent } from "./components/partials/subscribe-modal/subscribe-modal.component";
import { LanguageInterceptor } from "./services/interceptors/language.interceptors";
import { NewsSliderComponent } from "./components/common/news-slider/news-slider.component";
import { AboutCharterComponent } from "./components/pages/about-charter/about-charter.component";
import { AboutStructureComponent } from "./components/pages/about-structure/about-structure.component";
import { StructureItemComponent } from "./components/pages/about-structure/structure-item/structure-item.component";
import { ShareComponent } from "./components/common/share/share.component";
import { VoicekidsComponent } from "./components/pages/voice-kids/voice-kids.component";
import { VoicekidsNewsDetailComponent } from "./components/pages/voice-kids/voicekids-news-detail/voicekids-news-detail.component";
import { AppealPageComponent } from "./components/pages/appeal-page/appeal-page.component";
import { DragDropDirective } from "./components/pages/appeal-page/drag-drop.directive";
import { UploadFileComponent } from "./components/partials/upload-file/upload-file.component";
import { VacancyComponent } from "./components/pages/vacancy/vacancy.component";
import { ToAppealBannerComponent } from "./components/pages/main-page/to-appeal-banner/to-appeal-banner.component";
import { AdamiAwardComponent } from "./components/pages/adami-award/adami-award.component";
import { BroadcastingBoardPageComponent } from './components/pages/broadcasting-board-page/broadcasting-board-page.component';
import {CarierPageComponent} from './components/pages/carier-page/carier-page.component';
import {LiveVacancyPageComponent} from './components/pages/live-vacancy-page/live-vacancy-page.component';
import {PresidentBannerComponent} from './components/pages/main-page/president-banner/president-banner.component';
import {MskPageComponent} from './components/pages/msk-page/msk-page.component';

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@NgModule({
    declarations: [
        // Fallback for Broken Images
        ImageDirective,
        //SANITARIZE EMBED URLS
        SafeUrlPipe,

        SafeHtmlPipe,
        PrivacyComponent,
        AppComponent,

        //ALL ROUTING COMPONENTS ||||||||||||
        RoutingComponents,

        //HELPERS |||||||||||||||||||||||||||
        NotFoundPageComponent,
        CustomSpinnerComponent,

        //PARTIALS ||||||||||||||||||||||||||
        BannerTypeOneComponent,
        //FOOTER
        FooterPartialComponent,
        //HEADER
        HeaderPartialComponent,
        SearchModalComponent,
        HoverMenuPartialComponent,
        DetailCoverPartialComponent,
        ChapterPartialComponent,
        EmbedPlayerComponent,
        VideoPlayerComponent,
        LivePlayerComponent,

        //MAIN PAGE |||||||||||||||||||||||||
        MainSliderComponent,
        TodaySliderComponent,
        NewsPartialComponent,
        MobilReporterBannerComponent,
        PresidentBannerComponent,
        TvshowsLastPartialComponent,
        MoviesAndTvseriesSliderComponent,
        CompanyProjectsPartialComponent,
        RadioshowsLastPartialComponent,
        // COMMON ---------------------------
        CustomSelectComponent,
        ArticleChapterComponent,
        // ----------------------------------
        //TVSHOWS ||||||||||||||||||||||||||||

        //MOBIL REPORTER |||||||||||||||||||||
        MapModalComponent,
        TermsModalComponent,

        //TVSERIE ||||||||||||||||||||||||||||
        TvserieComponent,

        //MOVIE ||||||||||||||||||||||||||||
        MovieComponent,
        ContentLogoComponent,

        //MY FORM COMPONENTS |||||||||||||||||

        //MY FORM COMPONENTS |||||||||||||||||
        ScheduleItemComponent,
        TvshowComponent,
        ChapterComponent,
        ModalContainerComponent,

        SearchItemComponent,
        SubscribeModalComponent,
        NewsSliderComponent,
        AboutCharterComponent,
        AboutStructureComponent,
        StructureItemComponent,
        ShareComponent,
        VoicekidsComponent,
        VoicekidsNewsDetailComponent,
        AppealPageComponent,
        UploadFileComponent,
        DragDropDirective,
        VacancyComponent,
        ToAppealBannerComponent,
        AdamiAwardComponent,
        BroadcastingBoardPageComponent,
        CarierPageComponent,
        LiveVacancyPageComponent,
        MskPageComponent
    ],
    imports: [
        //INPUT MASKING
        NgxMaskModule.forRoot(),

        //BROWSER MODULE  |||||||||||||||||||
        BrowserModule,

        //APP ROUTING MODULE  |||||||||||||||
        AppRoutingModule,
        //APP FORMS MODULE  |||||||||||||||||
        FormsModule,
        ReactiveFormsModule,
        //TRANSLATE  ||||||||||||||||||||||||
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        //NG GOOGLE MAPS
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyDmRSFI5aFuISb_snM267SzNCn03GambEY",
        }),
        //NGX BOOTSTRAP DATEPICKER
        BsDatepickerModule.forRoot(),

        SlickCarouselModule,

        NgxUiLoaderModule,
    ],
    providers: [
        //DATEPICKER LANGS
        BsLocaleService,
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class AppModule {}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
