import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class ModalContainerComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HS:HelperService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  @Input('modal-name') modal:string;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  CloseSearchModal(){
    this.HS.HideModal('search');
    this.HS.HideModal('subscribe');
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


