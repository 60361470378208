import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {

  constructor(
    private HTTP: HttpClient
  ) { }


  GetUserLocation () {
    return this.HTTP.get<Location>('http://api.ipapi.com/api/check?access_key=a74e167426c3147b09ae5ee75e1a9080');
  }

  Search (key) {
    return this.HTTP.get<any>('https://maps.googleapis.com/maps/api/geocode/json?address=' + key + '&key=AIzaSyDmRSFI5aFuISb_snM267SzNCn03GambEY');
  }

}

export interface Location {
  latitude: string;
  longitude: string;
}
