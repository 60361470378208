import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { CommonService } from 'src/app/services/common/common.service';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: ['./subscribe-modal.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||




export class SubscribeModalComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HS: HelperService,
    private COMMON_SERVICE: CommonService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public subscribe_email_input:string;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SubscribeWithEmail_Api(email:string){
    this.COMMON_SERVICE.SubscribeWithEmail(email).subscribe(
      RESPONSE =>{
        document.querySelector("modal-container .footer-input-group").classList.add("active");
        document.querySelector("modal-container .footer-input-group input").setAttribute("disabled","true");
      },
      ERROR =>{}
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit(): void {
    // this.HS.ShowModal("subscribe");
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SendEmailForSubscribe(){
    this.SubscribeWithEmail_Api(this.subscribe_email_input);
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
