import { Component, OnInit } from '@angular/core';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ 
  selector: 'app-mobil-reporter-banner', 
  templateUrl: './mobil-reporter-banner.component.html', 
  styleUrls: ['./mobil-reporter-banner.component.scss'] 
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


export class MobilReporterBannerComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor() { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() { }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



