import { Component, OnInit, Input, SimpleChanges} from '@angular/core';
@Component({
  selector: 'app-detail-cover-partial',
  templateUrl: './detail-cover-partial.component.html',
  styleUrls: ['./detail-cover-partial.component.scss']
})
export class DetailCoverPartialComponent implements OnInit {

  constructor() { }

  @Input('cover_img') cover_img:string;
  @Input('title')     title:string;
  @Input('schedule')  schedule:string;
  @Input('after')     after:boolean;
  @Input('type')      type:string;
  @Input('sport_title')      sport_title:string;
  @Input('sport_subtitle')      sport_subtitle:string;


  
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.title){
      let text = "";
      for (let i = 0; i < this.title.length; i++) {
        this.title[i].replace("i","İ");
        if(this.title[i] == "i"){
          text+="İ";
        }
        else{
          text += this.title[i].toUpperCase();
        }
      }
      this.title = text;
    }
  }

}
