import { Component, OnInit, Input } from '@angular/core';
declare var AOS:any;

@Component({
  selector: 'app-tvserie',
  templateUrl: './tvserie.component.html',
  styleUrls: ['./tvserie.component.scss']
})
export class TvserieComponent implements OnInit {

  constructor() { }
  public done:boolean = true;

  @Input('data') data;
  ngOnInit() {
    AOS.init();
    setTimeout(() => {
      this.done = false;
    }, 600);
  }

}
