import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';

@Component({
  selector: 'search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss']
})
export class SearchItemComponent implements OnInit {

  constructor(
    private HS:HelperService
  ) { }

  ngOnInit() {
  }

  @Input("item") item;
  CloseSearchModal(){
    this.HS.HideModal("search");
  }
}
