import { Component, OnInit } from '@angular/core';
import { EurovisionService } from 'src/app/services/apis/eurovision/eurovision.service';
import { IEurovision } from 'src/app/models/eurovision/IEurovision';
import { ISelectModel } from '../../common/custom-select/custom-select.component';
import { IEurovisionChapter } from 'src/app/models/eurovision/IEurovisionChapter';
import { ILinks } from 'src/app/models/ILinks';
import { ActivatedRoute, Router } from '@angular/router';
import { IEurovisionNewsChapter } from 'src/app/models/eurovision/IEurovisionNewsChapter';
import { HelperService } from 'src/app/services/helper/helper.service';
import { PlatformLocation } from '@angular/common';
import { Title } from '@angular/platform-browser';
declare var $: any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'eurovision-page',
  templateUrl: './eurovision-page.component.html',
  styleUrls: ['./eurovision-page.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class EurovisionPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private EUROVISON_SERVICE:EurovisionService,
    private ACTIVATED_ROUTE: ActivatedRoute,
    private HS: HelperService,
    private ROUTER: Router,
    private LOCATION: PlatformLocation,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Eurovision");
    LOCATION.onPopState(() => {
      setTimeout(() => {
        this.ngOnInit();
      }, 10);
    });
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public eurovisions:IEurovision[];
  public eurovision_active:IEurovision = {} as any;
  public year_filter_select_items:ISelectModel[] = [];

  public eurovision_chapters:IEurovisionChapter[];
  public eurovision_played_chapter:IEurovisionChapter;
  public eurovision_chapters_links:ILinks;
  
  public eurovision_news_chapters:IEurovisionNewsChapter[];
  public eurovision_news_chapters_links:ILinks;

  public active_season_id:number;
  public active_chapter_id:number;
  public active_chapter_content:string = "chapters";
  public chaptes_search_key:string;
  public year_select_detaul_value:string ='Bütün buraxılışlar'
  public rules:any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.active_season_id = this.ACTIVATED_ROUTE.snapshot.queryParams.season;
    this.active_chapter_id = this.ACTIVATED_ROUTE.snapshot.queryParams.chapter;
    // this.ACTIVATED_ROUTE.queryParams
    this.active_chapter_id ? "" : '';
    
    this.GetEurovisionCollection_Api();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit(): void {
    
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  ChaptersSearchInputChange(){
    
    if(this.chaptes_search_key.length >= 1){
      if(this.active_season_id){
        this.GetEurovisionChaptersByIdAndKey_Api(`eurovision_id=${this.active_season_id}&title=${this.chaptes_search_key}`);
      }
      else{
        this.GetEurovisionChaptersByIdAndKey_Api(`title=${this.chaptes_search_key}`);
      }
    }
    
    else if (this.chaptes_search_key.length == 0){
      if(this.active_season_id){
        this.GetEurovisionChaptersById_Api(this.active_season_id);
      }
      else{
        this.GetAllEurovisionChapters_Api();
      }
    }
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAllEurovisionChapters_Api(){
    this.EUROVISON_SERVICE.GetAllEurovisionChapters().subscribe(
      RESPONSE => { 
        this.eurovision_chapters = RESPONSE.body.data;
        this.eurovision_chapters_links = RESPONSE.body.links;
      },
      ERROR => {}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionRules_Api(){
    this.EUROVISON_SERVICE.GetEurovisionRules().subscribe(
      RESPONSE => { 
        this.rules = RESPONSE.body;
        setTimeout(() => {
          this.SetToggleToContent();
        }, 300);
      },
      ERROR => {}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionChaptersById_Api(id:number){
    this.EUROVISON_SERVICE.GetEurovisionChaptersById(id).subscribe(
      RESPONSE => { 
        this.eurovision_chapters = RESPONSE.body.data
        this.eurovision_chapters_links = RESPONSE.body.links;
        if(this.active_chapter_id){
          this.PlayTargetChapter(this.active_chapter_id);
        }
      },
      ERROR => {}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionCollection_Api(){
    this.EUROVISON_SERVICE.GetEurovisionCollection().subscribe(
      RESPONSE => { 
        this.eurovisions = RESPONSE.body.data; 
        this.eurovision_active = this.eurovisions[0];
        this.GetSeasonByUrlParams();
        this.CreateSeasonSelectItems(this.eurovisions);
      },
      ERROR => {}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ChangeChapterNav(route){
    if(this.active_chapter_content != route){
      this.active_chapter_content = route;
      if(route == "news" && !this.eurovision_news_chapters){
        this.GetEurovisionNewsChapters_Api();
      }
      if(route == "chapters" && !this.eurovision_chapters){
        this.GetSeasonByUrlParams();
      }
      if(route == "rules" && !this.rules){
        this.GetEurovisionRules_Api();
      }
      else{
        setTimeout(() => {
          this.SetToggleToContent();
        }, 300);
      }
    }
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionNewsChapters_Api(){
    this.EUROVISON_SERVICE.GetEurovisionNewsChapters().subscribe(
      RESPONSE => { 
        this.eurovision_news_chapters = RESPONSE.body.data; 
        this.eurovision_news_chapters_links = RESPONSE.body.links;
        
      },
      ERROR => {}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetSeasonByUrlParams(){
    if(this.active_season_id){
      this.GetEurovisionChaptersById_Api(this.active_season_id); 
      this.eurovisions.forEach(e => {
        if(e.id == this.active_season_id){
          this.eurovision_active = e;
          setTimeout(() => {
            document.querySelector(".select_head h4").innerHTML = this.eurovision_active.title;
          }, 100);
          this.year_select_detaul_value = this.eurovision_active.title;
        }
      })
    }
    else{
      this.eurovision_active = this.eurovisions[0];
      this.active_season_id = this.eurovision_active.id;
      this.year_select_detaul_value = this.eurovision_active.title;
      this.ROUTER.navigate([], {
        queryParams: {
          season: this.active_season_id
        },
        queryParamsHandling: 'merge',
      });
      this.GetEurovisionChaptersById_Api(this.active_season_id); 
      // this.GetAllEurovisionChapters_Api();
    }
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreChapters(){
    this.GetEurovisionChaptersNextId_Api();
    // this.Paginate_Api(this.eurovision_chapters_links.next);
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionChaptersByIdAndKey_Api(key){
    this.EUROVISON_SERVICE.GetEurovisionChaptersByIdAndKey(key).subscribe(
      RESPONSE => { 
        this.eurovision_chapters = RESPONSE.body.data;
        this.eurovision_chapters_links = RESPONSE.body.links;
      },
      ERROR => {}
    );
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  YearSelectChange(value:any){
    this.active_season_id = parseInt(value);
    this.eurovisions.forEach(e => {
      if(e.id == this.active_season_id){
        this.eurovision_active = e;
      }
    })
    this.GetEurovisionChaptersById_Api(this.active_season_id);
    this.ROUTER.navigate([], {
      queryParams: {
        season: this.active_season_id
      },
      queryParamsHandling: 'merge',
    });
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  CreateSeasonSelectItems(eurovisions:IEurovision[]){
    eurovisions.forEach((e)=>{
      var item:ISelectModel = {} as any;
      item.name = e.title;
      item.value = e.id.toString();
      this.year_filter_select_items.push(item);
    });
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  PlayTargetChapter(id:any){
    this.eurovision_chapters.forEach((chapter) =>{
      if(chapter.id == id){
        this.eurovision_played_chapter = chapter;
        this.EUROVISON_SERVICE.GetEurovisionChapter(id).subscribe(
          RESPONSE =>{}
        )
      }
    })
    this.HS.ScrollToElementId("app-embed-player");
  }

    
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionChaptersNextId_Api(){
    var next_id:number = parseInt(this.eurovision_chapters_links.next.split("?page=")[1]);
    this.EUROVISON_SERVICE.GetEurovisionChaptersNextId(next_id).subscribe(
      RESPONSE =>{
        RESPONSE.body.data.forEach(chapter => {
          this.eurovision_chapters.push(chapter);
        });
        this.eurovision_chapters_links = RESPONSE.body.links;
      },
      ERROR => {}
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SetToggleToContent(){
    $(".accordion .head").click((e)=>{
      $(e.target).closest(".accordion").find(".body").slideToggle("fast",function(){});
      $(e.target).closest(".accordion").find(".head").toggleClass("active");
    })
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


