import { Component, OnInit, Input } from '@angular/core';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'article-chapter',
  templateUrl: './article-chapter.component.html',
  styleUrls: ['./article-chapter.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class ArticleChapterComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor() { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  @Input("id") id:number;
  @Input("image") image:string;
  @Input("date") date:string;
  @Input("title") title:string;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||




