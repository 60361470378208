import { Component, OnInit } from '@angular/core';
import { ITvshowChapters, ITvshowChapter } from '../tvshows-page/models/tvshows';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TvshowsService } from '../tvshows-page/service/tvshows.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ 
  selector: 'app-tvshows-detail-page', 
  templateUrl: './tvshows-detail-page.component.html', 
  styleUrls: ['./tvshows-detail-page.component.scss'] 
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class TvshowsDetailPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private ActivatedRoute:ActivatedRoute,
    private TvshowsService:TvshowsService,
    private SS:NgxUiLoaderService,
    private HS:HelperService,
    private ROUTER: Router,
    private TS:Title
  ) {
    this.TS.setTitle("İTV - Verilişlər");
    this.DedectRootChange();
   }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public search_key:string;
  public chapters:ITvshowChapters;
  public chapter:ITvshowChapter;
  public tvshow_id:number;
  public current_chapter_id:number;
  public active = {
    player:false,
    next_page_btn:false,
    next_page_id:null
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.tvshow_id = this.ActivatedRoute.snapshot.params.id;
    //GET CHARPET ID FROM URL
    this.current_chapter_id = this.ActivatedRoute.snapshot.queryParams.episode;
    this.GetChaptersByTvshowId(this.tvshow_id);
    
  }


  DedectRootChange(){
    this.ROUTER.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        const id  = this.ActivatedRoute.snapshot.params.id;
        if(id != this.tvshow_id){
          this.ngOnInit();
        }
      }
    });
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit(){
    this.HS.ScrollTo();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetChaptersByTvshowId(tvshow_id:number){
    this.SS.start();
    this.TvshowsService.GetChaptersByTvshowId(tvshow_id).subscribe(
      RESPONSE => {
        this.chapters = RESPONSE.body;
        var announce:ITvshowChapter = {
          "date":null,
          "duration":null,
          "guests":null,
          "id":null,
          "link":this.chapters.program.announce_source,
          "program":{
            id: null,
            presenter: null,
            title: null,
            schedule: null,
            image: null,
            cover: null,
            detail: null
          },
          "view_count":null,
          "title":this.chapters.program.announce_description
        }
        this.chapter = announce;
        this.GetNextPageId();
        setTimeout(() => {this.SS.stop();}, 1000);
        this.current_chapter_id == null ? this.current_chapter_id = this.chapters.data[0].id : this.GetCharpet(this.tvshow_id,this.current_chapter_id) ; 
      },
      ERROR =>{ setTimeout(() => {this.SS.stop();}, 1000); }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreChapters(){
    this.SS.start();
    this.TvshowsService.GetMoreChapters(this.tvshow_id,this.active.next_page_id).subscribe(
      RESPONSE => {
        RESPONSE.body.data.forEach(element => {this.chapters.data.push(element)});
        this.chapters.links = RESPONSE.body.links;
        this.GetNextPageId();
        setTimeout(() => {this.SS.stop();}, 1000);
      },
      ERROR => {setTimeout(() => {this.SS.stop();}, 1000);}
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetNextPageId(){
    this.chapters.links.next != null ? this.active.next_page_id = this.chapters.links.next.split('page=')[1] : this.active.next_page_id = null;
    this.active.next_page_id != null ? this.active.next_page_btn = true : this.active.next_page_btn = false;
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  PlayTargetChapter(chapter_id:number){
    if(this.current_chapter_id != chapter_id){
      this.current_chapter_id = chapter_id;
      this.GetCharpet(this.tvshow_id,this.current_chapter_id);
    }
    this.HS.ScrollToElementId("app-embed-player");
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetCharpet(tvshow_id:number,charpet_id:number){
    this.SS.start();
    this.TvshowsService.GetCharpet(tvshow_id,charpet_id).subscribe(
      RESPONSE => {
        this.chapter = RESPONSE.body;
        setTimeout(() => {this.SS.stop();}, 1000);
      },
      ERROR =>{ setTimeout(() => {this.SS.stop();}, 1000); }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SearchInputChange(){
    this.SS.start();
    if(this.search_key.length >= 1){
      this.TvshowsService.SearchTvshows(this.tvshow_id,this.search_key).subscribe(
        RESPONSE => {
          this.chapters = RESPONSE.body;
          this.chapters.links = RESPONSE.body.links;
          this.GetNextPageId();
          setTimeout(() => {this.SS.stop();}, 1000);
        },
        ERROR => {}
      );
    }
    if(this.search_key.length == 0){
      this.GetChaptersByTvshowId(this.tvshow_id);
    }
  }
  


}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
