import { Component, OnInit, ViewChild } from '@angular/core';
import { NewsService } from './service/news.service';
import { INewsReleases, INews } from './models/news';
import { HelperService } from 'src/app/services/helper/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import {Title} from "@angular/platform-browser";
declare var $:any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-news-page', templateUrl: './news-page.component.html', styleUrls: ['./news-page.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class NewsPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private NewsService: NewsService,
    private HS:HelperService,
    private SS: NgxUiLoaderService,
    private ACTIVATED_ROUTE: ActivatedRoute,
    private TS:Title
  ) {
    this.TS.setTitle("İTV - Xəbərlər");
    this.firsth_news_id = this.ACTIVATED_ROUTE.snapshot.queryParams.chapter;
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  @ViewChild('galleryOne') galleryOne;
  public search_key:string;
  public news:INewsReleases = {} as any;
  public news_get_next:boolean;
  public played_news:INews = {} as any;
  public firsth_news_id:number;
  public active = {
    active_video:0,
    active_news:0,
    active_content:false,
    news:false
  }

  public slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "infinite": false,
    "centerMode": false,
    "margin":20,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ],
    "nextArrow":
    `<button type='button' class='slick-next pull-right custom_1'>
      <img  class="slider-right" src="assets/icons/right-arrow-icon-grey.svg" alt="">
    </button>` ,
    "prevArrow":
    `<button type='button' class='slick-prev pull-left custom_1'>
      <img class="slider-left" src="assets/icons/left-arrow-icon-grey.svg" alt="">
    </button>`
  };
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ActivateContent(time = 200){
    setTimeout(() => {
      this.active.active_content = true
    }, time);
  }

  DeActivateContent(){
    this.active.active_content = false
  }

  ngOnInit() {
    //GET INITIALS NEWS ||||||||||||||||||||||||||||||||||||||||||||||||||||
    this.GetInitialNews();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetInitialNews(){
    this.SS.start();
    this.NewsService.GetInitialNews().subscribe(
      RESPONSE => {
        this.news = RESPONSE.body;
        this.firsth_news_id ? "" :this.firsth_news_id = this.news.data[0].id;
        this.news_get_next = RESPONSE.body.links.next ? this.news_get_next = true : this.news_get_next = false;
        this.GetPlayedNews();
        this.ActivateContent(2300);
        setTimeout(() => {this.SS.stop();}, 2500);
      },
      ERROR => {setTimeout(() => {this.SS.stop();}, 2500);}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  UpNewsViewCount(news_id:number){
    this.NewsService.UpNewsViewCount(news_id).subscribe(
      RESPONSE => {},
      ERROR => {}
    );
  }


//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetPlayedNews(){
    this.SS.start();
    //GET PLAYED NEWS ||||||||||||||||||||||||||||||||||||||||||||||||||||
    this.NewsService.GetPlayedNews(this.firsth_news_id).subscribe(
      RESPONSE => {
        this.active.active_news = this.firsth_news_id;
        this.played_news = RESPONSE.body;
        this.played_news.data.length ? this.active.active_video = this.played_news.data[0].id : "";
        this.active.active_video ? this.PlayVideo(this.active.active_video) : "";
        setTimeout(() => {this.SS.stop();}, 0);
        setTimeout(() => {this.active.news = true;}, 300);
      },
      ERROR    => { setTimeout(() => {this.SS.stop();}, 0); }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  PlayVideo(id){
    this.SS.start();
    setTimeout(() => {
      this.HS.ScrollToElementId("app-embed-player");
    }, 600);
    this.active.active_video = id;
    this.UpNewsViewCount(id);
    setTimeout(() => {this.SS.stop();}, 0);
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit() {
    this.HS.ScrollTo();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  CheckActive(id){
    if(id == this.active.active_video){ return true}
    else{return false}
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreNews(){
    this.SS.start();
    let page_id = this.news.links.next.split('page=')[1];
    this.NewsService.GetMoreNews(page_id).subscribe(
      RESPONSE => {
        RESPONSE.body.data.forEach(element => {this.news.data.push(element)});
        this.news_get_next = RESPONSE.body.links.next ? this.news_get_next = true : this.news_get_next = false;
        this.news.links = RESPONSE.body.links;
        setTimeout(() => {this.SS.stop();}, 0);
      },
      ERROR    => {setTimeout(() => {this.SS.stop();}, 100); }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  OpenTargetNews(id:number){
    this.DeActivateContent();
    this.HS.ScrollTo(120);
    if(!(this.active.active_news == id)){
       this.SS.start();
      this.active.active_news = id;
      //GET PLAYED NEWS ||||||||||||||||||||||||||||||||||||||||||||||||||||
      this.NewsService.GetPlayedNews(id).subscribe(
        RESPONSE => {
          this.played_news = RESPONSE.body;
          // this.active.active_video = this.played_news.data[0].id;
       //   this.galleryOne.unslick();

          this.ActivateContent(500);
          this.played_news.data[0].id ? this.PlayVideo(this.played_news.data[0].id) : "";
          setTimeout(() => {
            this.galleryOne.initSlick(this.slideConfig);
          }, 10);
          setTimeout(() => {this.SS.stop(); }, 0);
        },
        ERROR => { setTimeout(() => {this.SS.stop();}, 0); }
      )
    }
    else{
      this.ActivateContent(120);
    }

  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SearchInputChange(){
    if(this.search_key.length >= 1){
      this.SS.start();
      this.NewsService.SearchNews(this.search_key).subscribe(
        RESPONSE => {
          this.news = RESPONSE.body;
          this.news_get_next = RESPONSE.body.links.next ? this.news_get_next = true : this.news_get_next = false;
          this.news.links = RESPONSE.body.links;
          this.SS.stop();
        },
        ERROR => {
          this.SS.stop();
        }
      );
    }
    if(this.search_key.length == 0){
      this.SS.start();
      this.NewsService.GetInitialNews().subscribe(
        RESPONSE => {
          this.news = RESPONSE.body;
          this.news_get_next = RESPONSE.body.links.next ? this.news_get_next = true : this.news_get_next = false;
          this.news.links = RESPONSE.body.links;

          this.SS.stop();
        },
        ERROR => {this.SS.stop();}
      );
    }
  }

}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
