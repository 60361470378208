import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import * as Plyr from "./plyr.plugin/plyr.polyfilled.js";
import { Meta } from "@angular/platform-browser";
declare var $:any;
@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"]
})
export class VideoPlayerComponent implements OnInit {
  constructor(private META: Meta) { 
    this.META.addTag({ name: 'title', content: 'How to use Angular 4 meta service' });
    this.META.addTag({ name: 'description', content: 'talkingdotnet' });
    this.META.addTag({ name: 'keywords', content: 'Angular, Meta Service' });
  }
  // <!-- <meta property="og:url"           content="https://itv.saffman.uk" />
  // <meta property="og:type"          content="website" />
  // <meta property="og:title"         content="Your Website Titleasa" />
  // <meta property="og:description"   content="Your descriptiosn" />
  // <meta property="og:image"         content="https://itv-api.saffman.uk/storage/news/btr8iJOc44oH0Gluyuzgyo8B8M9hJnVQLJYB0vct.jpeg" />
  // <meta property="og:image" content="https://itv-api.saffman.uk/storage/news/btr8iJOc44oH0Gluyuzgyo8B8M9hJnVQLJYB0vct.jpeg" />
  // <meta property="og:image:secure_url" content="https://itv-api.saffman.uk/storage/news/btr8iJOc44oH0Gluyuzgyo8B8M9hJnVQLJYB0vct.jpeg" /> 
  // <meta property="og:image:type" content="image/jpeg" /> 
  // <meta property="og:image:width" content="400" /> 
  // <meta property="og:image:height" content="300" /> -->

  @Input("title") title: string;
  @Input("date") date: string;
  @Input("director") director: string;
  @Input("year") year: string;
  @Input("imdb") imdb: string;
  @Input("duration") duration: string;
  @Input("age_restriction") age_restriction: string;
  @Input("countries") countries;
  @Input("categories") categories;
  @Input("view_count") view_count: string;
  @Input("source") source: IVideoSource;
  @Input("cast") cast;
  @Input("type") type: string;
  // public player:any;


  ngOnChanges(changes: SimpleChanges): void {
    if(changes.source){

      const supported = Plyr.supported('video', 'html5', true);
      var player = new Plyr("#player", {
        title: "Example Title",
        settings: ["speed", "quality"]
      });

      var source  = {
        type: "video",
        sources: []
      }
      


      if(this.source.videos.w360){
        source.sources.push(
          {
            src: this.source.videos.w360,
            type: "video/mp4",
            size: 360
          }
        )
      }
      if(this.source.videos.w480){
        source.sources.push(
          {
            src: this.source.videos.w480,
            type: "video/mp4",
            size: 480
          }
        )
      }
      if(this.source.videos.w720){
        source.sources.push(
          {
            src: this.source.videos.w720,
            type: "video/mp4",
            size: 720
          }
        )
      }
      if(this.source.videos.w1080){
        source.sources.push(
          {
            src: this.source.videos.w1080,
            type: "video/mp4",
            size: 1080
          }
        )
      }
      if(this.source.videos.w2160){
        source.sources.push(
          {
            src: this.source.videos.w2160,
            type: "video/mp4",
            size: 2160
          }
        )
      }

      player.source = source;
      
    }
  }
  ngOnInit() {
    
  }
  ClickShare(){
  }
}


export interface IVideoSource{
  embed: string;
  videos:{
    w360: string;
    w480: string;
    w720: string;
    w1080: string;
    w2160: string;
  }
}

