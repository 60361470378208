
import { ITvshows, ITvshowChapters, ITvshowChapter, DateFormats } from '../models/tvshows';
import { UrlService } from 'src/app/services/url/url.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class TvshowsService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HttpClient: HttpClient,
    private UrlService: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetInitialTvshows() : Observable<any>{
    return this.HttpClient.get<ITvshows>(
      this.UrlService.GetUrl().GetInitialTvshows,
      { observe: 'response', headers: this.header }
    )
  }

  GetDate() : Observable<any>{
    return this.HttpClient.get<DateFormats>(
      this.UrlService.GetUrl().GetDate,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetChaptersByTvshowId(tvshow_id:number) : Observable<any>{
    return this.HttpClient.get<ITvshowChapters>(
      this.UrlService.GetUrl(tvshow_id).GetChaptersByTvshowId,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetCharpet(tvshow_id:number,charpet_id:number) : Observable<any>{
      return this.HttpClient.get<ITvshowChapter>(
        this.UrlService.GetUrl(tvshow_id,charpet_id).GetChapter,
        { observe: 'response', headers: this.header }
      )
    }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SearchTvshows(tvshow_id:number,key:string) : Observable<any>{
    return this.HttpClient.get<ITvshowChapters>(
      this.UrlService.GetUrl(tvshow_id,key).SearchTvshows,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreChapters(tvshow_id:number,page_id:number) : Observable<any>{
    return this.HttpClient.get<ITvshowChapters>(
      this.UrlService.GetUrl(tvshow_id,page_id).GetMoreChapters,
      { observe: 'response', headers: this.header }
    )
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
