import { Component, OnInit } from '@angular/core';
import { ILiveTvProgram } from './models/live-tv';
import { TestService } from 'src/app/services/test-data/test.service';
import { Title } from '@angular/platform-browser';
import {CommonService} from '../../../services/common/common.service';

@Component({ selector: 'app-live-tv', templateUrl: './live-tv.component.html', styleUrls: ['./live-tv.component.scss'] })

export class LiveTvComponent implements OnInit {

  constructor(
    private TS:TestService,
    private COMMON_SERVICE: CommonService,
    private TITLE:Title
  ) {
      this.TITLE.setTitle("İTV - Canlı yayım");
  }

  public program:ILiveTvProgram[] = [];
  public active ={
    slide:null
  }
  public slideConfig = {
    "slidesToShow": 6,
    "slidesToScroll": 1,
    "infinite": false,
    "nextArrow":
    `<button type='button' class='slick-next pull-right custom_2'>
      <img  class="slider-right" src="assets/icons/right-arrow-icon-grey.svg" alt="">
    </button>` ,
    "prevArrow":
    `<button type='button' class='slick-prev pull-left custom_2'>
      <img class="slider-left" src="assets/icons/left-arrow-icon-grey.svg" alt="">
    </button>`
  };

  GetLiveStream() {
    this.COMMON_SERVICE.GetLiveStream().subscribe(
        (RESPONSE) => {
          if (window.location.href !== RESPONSE.body.live_video_link) {
            window.location.href = RESPONSE.body.live_video_link;
          }
        },
        (ERROR) => {}
    );
  }


  ngOnInit() {
    this.GetLiveStream();
    // this.TS.GetLiveTvProgram().subscribe(
    //   RESPONSE => {
    //     this.program = RESPONSE;
    //     this.GetInitialActiveSlideItemFromProgram();
    //   },
    //   ERROR =>{}
    // )
  }

  GetInitialActiveSlideItemFromProgram(){
    for (let i = 0; i < this.program.length; i++) {
      if(this.program[i].is_live == true)
        this.active.slide = this.program[i].id;
    }
  }



  SetActiveClassToSlideItem(slide_id,active_id){
    if(slide_id == active_id){return true}
    else{return false}
  }

  ChangeProgramFlow(id,is_live,is_published){

    if(is_published == true && this.active.slide != id){
      this.active.slide = id;
    }
  }

}
