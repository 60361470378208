import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UrlService } from 'src/app/services/url/url.service';
import { ISportCategories, ISport } from '../models/sport';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export class SportService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HTTP: HttpClient,
    private URLS: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetSportCategories() : Observable<any>{
    return this.HTTP.get<ISportCategories>(
      this.URLS.GetUrl().GetSportCategories,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetSport(sport_id) : Observable<any>{
    return this.HTTP.get<ISport>(
      this.URLS.GetUrl(sport_id).GetSport,
      { observe: 'response', headers: this.header }
    )
  }

  GetSportChaptersById(id) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(id).GetSportChaptersById,
      { observe: 'response', headers: this.header }
    )
  }



}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


