//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language/language.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/services/common/common.service';
import { IGeneralInformation } from 'src/app/models/IGeneralInformation';
import { HelperService } from 'src/app/services/helper/helper.service';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-footer-partial', templateUrl: './footer-partial.component.html', styleUrls: ['./footer-partial.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class FooterPartialComponent implements OnInit {


  // CONSTRUCTOR  ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  constructor(
    private LanguageService:LanguageService,
    private SS : NgxUiLoaderService,
    private COMMON_SERVICE: CommonService,
    private HS: HelperService
  ) { this.active.lang = localStorage.getItem('language')}


    public live_stream_url: string;
  public active = {lang:'az'}
  public subscribe_email_input:string;
  public general_information:IGeneralInformation;
  public subscribe:boolean = false;
  public subscribe_already:boolean = false;
  public document_width:number;
  // USER SWITCH LANG  |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ChangeLang(lang) {
    this.SS.start();
    this.LanguageService.switchLanguage(lang);
    setTimeout(() => {this.SS.stop();}, 500);
 }

    GetLiveStream() {
        this.COMMON_SERVICE.GetLiveStream().subscribe(
            (RESPONSE) => {
                this.live_stream_url = RESPONSE.body.live_video_link;
            },
            (ERROR) => {}
        );
    }


  // PAGE ON INIT  |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ngOnInit() {
      this.GetLiveStream();
  }

  ngAfterViewInit(): void {
    this.GetGeneralInformation();
    setTimeout(() => {
      this.document_width  = this.HS.GetDocumentWidth();
    }, 10);
  }

  GetGeneralInformation(){
    setTimeout(() => {
      this.general_information = this.COMMON_SERVICE.general_information;
  }, 3000);
  }

  SubscribeWithEmail_Api(email:string){
    this.COMMON_SERVICE.SubscribeWithEmail(email).subscribe(
      RESPONSE =>{
        this.subscribe =true;
      },
      ERROR =>{
        this.subscribe_already = true;
      }
    )
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  FooterMainInputChange(){

  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SendEmailForSubscribe(){
    if(this.subscribe_email_input.includes("@") && this.subscribe_email_input.includes(".")){
      this.SubscribeWithEmail_Api(this.subscribe_email_input);
    }
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
