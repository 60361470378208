import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UrlService } from 'src/app/services/url/url.service';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class EurovisionService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HTTP: HttpClient,
    private URLS: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  header = new HttpHeaders({'Content-Type':  'application/json'});

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionCollection() : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl().GetEurovisionCollection,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAllEurovisionChapters() : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl().GetAllEurovisionChapters,
      { observe: 'response', headers: this.header }
    )
  }


//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionChapter(id) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(id).GetEurovisionChapter,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionChaptersById(id:number) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(id).GetEurovisionChaptersById,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionChaptersByIdAndKey(key:string) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(key).GetEurovisionChaptersByIdAndKey,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionNewsChapters() : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl().GetEurovisionNewsChapters,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionNewsDetail(id:number) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(id).GetEurovisionNewsDetail,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionChaptersNextId(id:number) : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl(id).GetEurovisionChaptersNextId,
      { observe: 'response', headers: this.header }
    )
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetEurovisionRules() : Observable<any>{
    return this.HTTP.get<any>(
      this.URLS.GetUrl().GetEurovisionRules,
      { observe: 'response', headers: this.header }
    )
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


