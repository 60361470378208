import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { IAboutus } from 'src/app/models/IAboutus';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { e } from '@angular/core/src/render3';
import { HelperService } from 'src/app/services/helper/helper.service';
import { Title } from '@angular/platform-browser';
declare var $: any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'app-carier-page',
  templateUrl: './carier-page.component.html',
  styleUrls: ['./carier-page.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||




export class CarierPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private COMMON_SERVICE:CommonService,
    private SPINNER: NgxUiLoaderService,
    private HS:HelperService,
    private TS:Title
  ) {
    this.TS.setTitle("İTV - Karyera");
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public about:IAboutus;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    //this.GetAbout_Api();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SetToggleToContent(){
    $(".accordion .head").click((e)=>{
      $(e.target).closest(".accordion").find(".body").slideToggle("fast",function(){

      });
      $(e.target).closest(".accordion").find(".head").toggleClass("active");
    })
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

