import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UrlService } from 'src/app/services/url/url.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class SingleService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HttpClient: HttpClient,
    private UrlService: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetAllPodcasts() : Observable<any>{
    return this.HttpClient.get<any>(
      this.UrlService.GetUrl().GetAllPodcasts,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
