//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
import { HttpEvent,HttpHandler,HttpInterceptor,HttpRequest} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { addUnitPriority } from "ngx-bootstrap/chronos/units/priorities";
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Injectable()
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class LanguageInterceptor implements HttpInterceptor {
  constructor() {}

  //GET CURRENT LANG FROM LOCAL STORAGE ||||||||||||||||||||||||||||||||||||||||||||||||||
  setAcceptLanguage(lang: string) {
    lang = localStorage.getItem("language") || lang;
    return lang;
  }

  
  intercept( request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: { "Accept-Language": "" + this.setAcceptLanguage("az") + "" }
    });
    return next.handle(request);
  }
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ADD { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true} TO
// app.modute.ts to providers: []
