import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleMapService } from 'src/app/services/maps/google-map.service';
import { MouseEvent } from '@agm/core';
declare var $:any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

@Component({ selector: 'app-map-modal', templateUrl: './map-modal.component.html', styleUrls: ['./map-modal.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class MapModalComponent implements OnInit {
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(private GOOGLE_MAPS: GoogleMapService) { 
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  lat: number ;
  lng: number;
  location: Object;
  marker: marker;
  userLocation: any;
  siteLocation: any;
  canCheckIn: boolean;
  zoom: number = 15;

  @Output() Location: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.setCurrentLocation();
  }

    private setCurrentLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.zoom = 10;
          const marker:marker = {
            lat:  position.coords.latitude,
            lng: position.coords.longitude,
            draggable: true
          };
          this.marker = marker;
        });
      }
    }


  mapClicked($event: MouseEvent) {
    const marker:marker = {
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    };
    this.marker = marker;
  }

  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  CloseModal(){
    $('#map_modal').slideUp('fast',function(){});
    $('body').css('overflow','scroll');
  }

  Approve(){
    this.CloseModal();
    this.Location.next({lat:this.lat,lng:this.lng});
  }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}
