import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'live-radio-page',
  templateUrl: './live-radio-page.component.html',
  styleUrls: ['./live-radio-page.component.scss']
})
export class LiveRadioPageComponent implements OnInit {

  constructor(
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Canlı radio");
  }

  ngOnInit() {
  }

}
