import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
declare var $: any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'news-slider',
  templateUrl: './news-slider.component.html',
  styleUrls: ['./news-slider.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class NewsSliderComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HS: HelperService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  @Input("data") slider_items:string[];

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
  }

  ngOnChanges(changes): void {
      if(changes.slider_items){
      setTimeout(() => {
        this.InitializeSliders();
      }, 10);
    }
  }
  
  InitializeSliders() {
    $(".news_slider").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      loop: false,
      responsive: [
        {
          breakpoint: 970,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
      ],
    // arrows: true,
      prevArrow: "<div class='prev' ARROW-LEFT-GREY-ICON></div>",
      nextArrow: "<div class='next' ARROW-RIGHT-GREY-ICON></div>"
    });

    if (this.HS.GetDocumentWidth() < 500) {
      $(".news_slider .slick-arrow.next").css({
        position: "absolute",
        top: "0%",
        right: "-2px",
        width: "8px",
        height: "100%"
      });
      $(".news_slider .slick-arrow.prev").css({
        position: "absolute",
        top: "0%",
        left: "-2px",
        width: "8px",
        height: "100%"
      });
    } else {
      $(".news_slider .slick-arrow.next").css({
        position: "absolute",
        top: "0%",
        right: "-22px",
        width: "8px",
        height: "100%"
      });
      $(".news_slider .slick-arrow.prev").css({
        position: "absolute",
        top: "0%",
        left: "-22px",
        width: "8px",
        height: "100%"
      });
    }
  }
}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
