import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({ providedIn: 'root' })

export class HelperService {

  constructor(
  ) { }

  ScrollTo(to = 0) {
    $("html, body").animate({ scrollTop: to + "px" }, 1000);
  }
  ScrollToElementId(selector){
    const top = $(selector).offset().top - 30;
    $("html, body").animate({ scrollTop: top + "px" }, 1000);
  }

  GetDocumentWidth(){
    return document.body.clientWidth;
  }

  ConvertAngularRoot(type){
    if(type == "tvseries"){
      return "tvseries";
    }
    else if(type == "films"){
      return "movies";
    }
    else if(type == "news"){
      return "news";
    }
    else if(type == "programs"){
      return "tvshows";
    }
    else{
      return "not-found";
    }
  }

  ScrollVideoFunction(){
    $(window).scroll(function (event) {
      let top =  $(window).scrollTop(); 
      let begin = false;

      if(top > 0 && top < 230){
        begin = false;
      }
      else{
        begin  = true;
      }


      if(top > 230 ){


        let $video = $('.player-container .content')
        let height = $video.height();
        let width = $video.width();


        if(height > 250 && width > 450){
          $video.height(height - (top-230)/2);
          $video.width(height*1.7 - (top-230)/2);
          $('.player-container .content .info').css('display','none');
          $('.player-container .content .video').css('width','100%');
          $('.player-container .content').css({
            "position" : "fixed",
            "z-index":"120",
            "top" : "10px",
            "right" : "10px"
          })
        }

      }

      if(!begin){
        $('.player-container .content').height(515);
        $('.player-container .content').css({
          "position" : "relative",
          "width" : "100%"
        })
        $('.player-container .content .info').css('display','flex');
        $('.player-container .content .video').css('width','calc(100% - 340px)');

        // $('.player-container .content').height(515*1.7);
      }

    });
  }

  ShowModal(name){
    var modal = document.querySelector(`[modal-name=${name}]`);
    if(!modal){
      return;
    }
    modal.classList.add('d-block');
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      modal.classList.add('active');
    }, 10);
  }

  HideModal(name){
    var modal = document.querySelector(`[modal-name=${name}]`);
    if(!modal){
      return;
    }
    modal.classList.remove('active');
    setTimeout(() => {
      modal.classList.remove('d-block');
      document.body.style.overflow = "scroll";
    }, 300);
  }


}
