import { Component, OnInit, Input } from '@angular/core';
import { ITvShowItem } from 'src/app/models/tvshows/ITvShowItem';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'tvshow',
  templateUrl: './tvshow.component.html',
  styleUrls: ['./tvshow.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||




export class TvshowComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor() { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  @Input("data") data:ITvShowItem;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {}

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
