import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from 'src/app/services/url/url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IMovie, IMovieGenre } from '../models/movies';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class MoviesService {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private HttpClient: HttpClient,
    private UrlService: UrlService
  ) { }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetInitialMovies() : Observable<any>{
    return this.HttpClient.get<IMovie>(
      this.UrlService.GetUrl().GetInitialMovies,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreMovies(next_page) : Observable<any>{
    return this.HttpClient.get<IMovie>(
      this.UrlService.GetUrl(next_page).GetMoreMovies,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMovieGenres() : Observable<any>{
    return this.HttpClient.get<IMovieGenre>(
      this.UrlService.GetUrl().GetMovieGenres,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMovieCountries() : Observable<any>{
    return this.HttpClient.get<IMovieGenre>(
      this.UrlService.GetUrl().GetMovieCountries,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  FilterMovies(year,imdb,genre_id,country_id) : Observable<any>{
    return this.HttpClient.get<IMovie>(
      this.UrlService.GetUrl(year,imdb,genre_id,country_id).FilterMovies,
      { observe: 'response', headers: this.header }
    )
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetSingleMovieById(movie_id) : Observable<any>{
    return this.HttpClient.get<IMovie>(
      this.UrlService.GetUrl(movie_id).GetSingleMovieById,
      { observe: 'response', headers: this.header }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
