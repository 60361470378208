import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
declare var $: any;

@Component({
  selector: "custom-select",
  templateUrl: "./custom-select.component.html",
  styleUrls: ["./custom-select.component.scss"],
})
export class CustomSelectComponent implements OnInit {
  constructor() {}

  ngOnChanges(changes): void {}

  @Input("items") items: ISelectModel;
  @Input("placeholder") placeholder: string;
  @Output() SelectChange: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    $(".select_out").height($(".select_m").outerHeight());
    $(document).click(function (e) {
      var $tgt = $(e.target);
      if (!$tgt.closest(".select_m").length) {
        $(".select_m").removeClass("active");
        $(".select_head svg").removeClass("rotate");
        $(".select_body").slideUp("fast", () => {});
      }
    });
  }

  ToggleSelect(e) {
    let select = $(e.target).closest(".select_out");
    $(select).find(".select_head svg").toggleClass("rotate");
    $(select).find(".select_m").toggleClass("active");
    $(select)
      .find(".select_body")
      .slideToggle("fast", () => {});
  }

  SelectItem(e) {
    let select = $(e.target).closest(".select_out");
    let item_id = $(e.target).attr("data-id");
    let item_name = $(e.target).html();
    $(select).find(".select_head input").val(item_id);
    $(select).find(".select_head h4").html(item_name);
    this.ToggleSelect(e);
    this.SelectChange.emit(item_id);
  }
  CloseAllSelects() {
    $(".select_m").removeClass("active");
    $(".select_body").slideUp("fast", () => {});
  }
}
export interface ISelectModel {
  name: string;
  value: string;
}
