import { Component, OnInit } from '@angular/core';
import { SportService } from '../service/sport.service';
import { ISport, ISportChapter } from '../models/sport';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ILinks } from 'src/app/models/ILinks';
import { Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/services/helper/helper.service';

@Component({
  selector: 'app-sport-detail-page',
  templateUrl: './sport-detail-page.component.html',
  styleUrls: ['./sport-detail-page.component.scss']
})
export class SportDetailPageComponent implements OnInit {

  constructor(
    private SPORT_SERVICE:SportService,
    private ACTIVATED_ROUTE:ActivatedRoute,
    private ROUTER: Router,
    private TS:Title,
    private HS:HelperService,

  ) { 
    this.TS.setTitle("İTV - İdman");
    this.DedectRootChange();
  }

  public sport:ISport;
  public sport_chapters:ISportChapter[];
  public active_sport_chapter:ISportChapter;
  public sport_id:string;
  public chapter_id:string;
  public sport_chapters_links:ILinks;
  
  DedectRootChange(){
    this.ROUTER.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        const id  = this.ACTIVATED_ROUTE.snapshot.params.id;
        if(id != this.sport_id){
          this.ngOnInit();
        }
      }
    });
  }

  ngOnInit() {
    this.sport_id = this.ACTIVATED_ROUTE.snapshot.params.id;
    this.chapter_id = this.ACTIVATED_ROUTE.snapshot.queryParams.chapter;
    this.GetSport(this.sport_id);
  }


  GetSport(sport_id){
    this.HS.ScrollTo();
    this.SPORT_SERVICE.GetSport(sport_id).subscribe(
      RESPONSE => {
        this.sport = RESPONSE.body;
        this.GetSportChaptersById(this.sport_id);
      },
      ERROR => {}
    )
  }

  PlayTargetChapter(id){
    this.sport_chapters.forEach((e)=>{
      if(e.id == id){
        this.active_sport_chapter = e;
        setTimeout(() => {
          this.active_sport_chapter.src.embed ?  this.HS.ScrollToElementId("app-embed-player") : this.HS.ScrollToElementId("app-video-player") ;
        }, 400);

      }
    })
  }

  GetSportChaptersById(id){
    this.SPORT_SERVICE.GetSportChaptersById(id).subscribe(
      RESPONSE => {
        this.sport_chapters = RESPONSE.body.data;
        this.sport_chapters_links = RESPONSE.body.links
        if(this.chapter_id){
          setTimeout(() => {
            this.PlayTargetEpisode(this.chapter_id);
          }, 100);
        }
      },
      ERROR => {}
    )
  }

  PlayTargetEpisode(played_id){
    this.sport_chapters.forEach(element => {
        if(element.id == played_id){
          this.active_sport_chapter = element;

        }
    });
  }

}
