import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IMobilReporter } from '../models/IMobilReporter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from 'src/app/services/url/url.service';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Injectable({ providedIn: 'root' })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class MobilReporterService {
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  header = new HttpHeaders({'Content-Type':  'application/json'});
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  constructor(
    private HttpClient: HttpClient,
    private UrlService: UrlService
  ) { }
  //||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  PostMobilReporterForm(DATA:IMobilReporter) : Observable<any>{
    return this.HttpClient.post<IMobilReporter>(
      this.UrlService.GetUrl().mobil_reporter,
      DATA,
      { observe: 'response', headers: this.header }
    )
  }
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

