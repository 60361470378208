import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IVoicekidsDetail } from 'src/app/models/voicekids/IVoicekidsNewsDetails';
import { Title } from '@angular/platform-browser';
import {MaskasService} from "../../../../services/apis/maskas/maskas.service";

@Component({
  selector: 'app-maska-news-detail',
  templateUrl: './maska-news-detail.component.html',
  styleUrls: ['./maska-news-detail.component.scss']
})
export class MaskaNewsDetailComponent implements OnInit {

  constructor(
    private ACTIVATED_ROUTE:ActivatedRoute,
    private MASKA_SERVICE:MaskasService,
    private TS:Title
  ) {
    this.TS.setTitle("İTV - Maska Xəbərlər");
  }

  public news_id:number;
  public slider_images:string[];
  public news:IVoicekidsDetail;
  ngOnInit() {
    this.news_id = this.ACTIVATED_ROUTE.snapshot.params.id;
    this.GetMaskaNewsDetail_Api(this.news_id );
  }

  GetMaskaNewsDetail_Api(id:number){
    this.MASKA_SERVICE.GetMaskaNewsDetail(id).subscribe(
      RESPONSE => {
        this.news = RESPONSE.body;
        this.slider_images = RESPONSE.body.slider_images;
      },
      ERROR => {}
    );
  }

}
