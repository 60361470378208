import { Component, OnInit } from '@angular/core';
import { VoicekidsService } from 'src/app/services/apis/voicekids/voicekids.service';
import { ActivatedRoute } from '@angular/router';
import { IVoicekidsDetail } from 'src/app/models/voicekids/IVoicekidsNewsDetails';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-voicekids-news-detail',
  templateUrl: './voicekids-news-detail.component.html',
  styleUrls: ['./voicekids-news-detail.component.scss']
})
export class VoicekidsNewsDetailComponent implements OnInit {

  constructor(
    private ACTIVATED_ROUTE:ActivatedRoute,
    private VOICEKIDS_SERVICE:VoicekidsService,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Voicekids Xəbərlər");
  }

  public news_id:number;
  public slider_images:string[];
  public news:IVoicekidsDetail;
  ngOnInit() {
    this.news_id = this.ACTIVATED_ROUTE.snapshot.params.id;
    this.GetVoicekidsNewsDetail_Api(this.news_id );
  }

  GetVoicekidsNewsDetail_Api(id:number){
    this.VOICEKIDS_SERVICE.GetVoicekidsNewsDetail(id).subscribe(
      RESPONSE => {
        this.news = RESPONSE.body;
        this.slider_images = RESPONSE.body.slider_images;
      },
      ERROR => {}
    );
  }

}
