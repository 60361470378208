import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/services/helper/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NewsService } from '../news-page/service/news.service';
import { IDetailNews } from '../news-page/models/news';
import { Title } from '@angular/platform-browser';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-news-detail-page', templateUrl: './news-detail-page.component.html', styleUrls: ['./news-detail-page.component.scss'],encapsulation: ViewEncapsulation.None, })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class NewsDetailPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  constructor(
    private ActivatedRoute:ActivatedRoute,
    private HS:HelperService,
    private SS: NgxUiLoaderService,
    private NewsService: NewsService,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Xəbərlər");
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  public news:IDetailNews;
  public title:string;
  public slider_images:string[];
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ngOnInit() {
    this.SS.start();
    let news_id = this.ActivatedRoute.snapshot.params.news_id;
    let detail_id = this.ActivatedRoute.snapshot.params.detail_id;
    //GET NEWS ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
    this.NewsService.GetDetailNews(detail_id,news_id).subscribe(
      RESPONSE => { 
        this.news = RESPONSE.body;
        this.title = RESPONSE.body.news.title;
        this.slider_images = RESPONSE.body.slider_images;
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => {setTimeout(() => {this.SS.stop();}, 500);}
    );
  }

  ngAfterViewInit() {
    this.HS.ScrollTo();
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

