import { Component, OnInit } from '@angular/core';
import { TvSeriesService } from './service/tvseries.service';
import { ITvSeries, ILocationCategory } from './models/tvseries';
import { HelperService } from 'src/app/services/helper/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';
declare var $:any;
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({ selector: 'app-tvseries-page', templateUrl: './tvseries-page.component.html', styleUrls: ['./tvseries-page.component.scss'] })
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class TvseriesPageComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  constructor(
    private TvSeriesService:TvSeriesService,
    private HS:HelperService,
    private SS: NgxUiLoaderService,
    private TS:Title
  ) { 
    this.TS.setTitle("İTV - Seriallar");
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public tvseries:ITvSeries;
  public tvseries_location_caregory:ILocationCategory;
  public active ={
    content:false,
    next_page:null,
    next_page_btn:false
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetTvseriesLocationCategory() {
    this.TvSeriesService.GetTvseriesLocationCategory().subscribe(
      RESPONSE => {
        this.tvseries_location_caregory = RESPONSE.body;
      },
      ERROR => {  }
    );
  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.SS.start();
    this.GetInitialTvSeries();
    this.GetTvseriesLocationCategory();

  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetInitialTvSeries(){
    this.TvSeriesService.GetInitialTvSeries().subscribe(
      RESPONSE => {
        this.tvseries = RESPONSE.body;
        this.active.content = true;
        this.GetNextPageId();
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => { setTimeout(() => {this.SS.stop();}, 500);}
    );
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetNextPageId(){
    this.tvseries.links.next != null ? this.active.next_page = this.tvseries.links.next.split('page=')[1] : this.active.next_page = null;
    this.active.next_page != null ? this.active.next_page_btn = true : this.active.next_page_btn = false;
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  GetMoreTvseries(){
    this.SS.start();
    this.TvSeriesService.GetMoreTvSeries(this.active.next_page).subscribe(
      RESPONSE => { 
        RESPONSE.body.data.forEach(element => {this.tvseries.data.push(element)});
        this.tvseries.links = RESPONSE.body.links;
        this.GetNextPageId();
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => { setTimeout(() => {this.SS.stop();}, 500); }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngAfterViewInit() {
    this.HS.ScrollTo();
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  FilterTvseriesBtn(){
    let location_id = $('input[name="location_select"]').val();
    this.FilterTvseries(location_id);
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  FilterTvseries(location_id){
    this.SS.start();
    this.ToggleFilterBox();
    this.TvSeriesService.FilterTvseries(location_id).subscribe(
      RESPONSE => { 
        this.tvseries  = RESPONSE.body;
        this.tvseries.links = RESPONSE.body.links;
        this.GetNextPageId();
        setTimeout(() => {this.SS.stop();}, 500);
      },
      ERROR => { setTimeout(() => {this.SS.stop();}, 500); }
    )
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  ToggleFilterBox(){
    $('.filter_body').slideToggle('fast',() => {});

    $('.filter_body .select_body').slideUp('fast',() => {});
    $('.filter_body .select_m').removeClass('active');
    $('.filter_body .select_head svg').removeClass('rotate');

  }
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  
  ToggleSelect(e){
    let select = $(e.target).closest('.select_out');
    $(select).find('.select_head svg').toggleClass('rotate');
    $(select).find('.select_m').toggleClass('active');
    $(select).find('.select_body').slideToggle('fast',() => {});
  }

  SelectItem(e){
    let select = $(e.target).closest('.select_out');
    let item_id = $(e.target).attr('data-id');
    let item_name = $(e.target).html();
    $(select).find('.select_head input').val(item_id);
    $(select).find('.select_head h4').html(item_name);
    this.ToggleSelect(e);
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
}
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
